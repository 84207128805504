import http from "src/utilis/http";
import API from "src/constants/apiConstants";

export const addCropPlan = (data: any) => http.post(API.ADD_CROP_PLAN, data);
export const updateCropPlan = (data: any) => http.put(API.ADD_CROP_PLAN, data);
export const getPreSignedURL = (data: any) =>
  http.post(API.GET_PRESIGNED_URL, data);
export const fetchCropPlanData = (queryString: any) =>
  http.post(API.FETCH_CROP_PLAN, queryString);
export const fetchMetaData = (queryString: any) =>
  http.post(API.FETCH_CROP_PLAN_METADATA, queryString);
export const getPreSignedURLForDownload = (data: any) =>
  http.post(API.GET_PRESIGNED_URL_DOWNLOAD, data);
