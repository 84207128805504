import { of, catchError, from } from "rxjs";
import { concatMap, mergeMap } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";
import * as modalActions from "../actions/modalActions";
import * as varietyActions from "../actions/varietyActions";
import { MESSAGE, LABEL } from "src/constants/commonConstants";
import { ROUTECONSTANTS } from "src/constants/routeConstants";

const VARIETY = "variety";

export const fetchVarietyEpic = (
  action$: any,
  state$: any,
  { varietyService }: any
) => {
  return action$.pipe(
    ofType(varietyActions.FETCH_VARIETY_LIST),

    mergeMap(({ payload }) => {
      return from(varietyService.fetchVarietyList()).pipe(
        concatMap((response: any) => {
          console.log("Fetch crop variety response", response);
          let actions = [];
          actions.push(
            varietyActions.storeVarietyList(response.data.cropVarieties)
          );
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const fetchVarietyMetaData = (
  action$: any,
  state$: any,
  { varietyService }: any
) => {
  return action$.pipe(
    ofType(varietyActions.FETCH_VARIETY_METADATA),

    mergeMap(({ payload }) => {
      return from(varietyService.fetchVarietyMetaData(payload)).pipe(
        concatMap((response: any) => {
          console.log("Fetch fetchVarietyMetaData response", response);
          let actions = [];
          actions.push(varietyActions.storeVarietyMetaData(response.data));
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const deleteVarietyEpic = (
  action$: any,
  state$: any,
  { varietyService }: any
) => {
  return action$.pipe(
    ofType(varietyActions.DELETE_VARIETY),
    mergeMap(({ payload }) => {
      return from(
        varietyService.deleteVariety({ cropVarietyId: payload })
      ).pipe(
        concatMap((response: any) => {
          console.log("delete crop variety response", response);
          let actions = [];
          actions.push(varietyActions.fetchVarieties());
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: MESSAGE.ACTIVE_DELETE(VARIETY),
              subMessage: MESSAGE.ACTIVE_DELETE_SUB_MESSAGE,
              okButtonText: LABEL.CLOSE,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const updateVarietyEpic = (
  action$: any,
  state$: any,
  { varietyService }: any
) => {
  return action$.pipe(
    ofType(varietyActions.UPDATE_VARIETY),
    mergeMap(({ payload }) => {
      return from(varietyService.updateVariety(payload)).pipe(
        concatMap((response: any) => {
          console.log("update variety response", response);
          let actions = [];
          actions.push(varietyActions.fetchVarieties());
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: err?.response?.data?.message || "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const addVarietyEpic = (
  action$: any,
  state$: any,
  { varietyService }: any
) => {
  return action$.pipe(
    ofType(varietyActions.ADD_VARIETY),
    mergeMap(({ payload }) => {
      return from(varietyService.addVariety({ ...payload })).pipe(
        concatMap((response: any) => {
          let actions = [];
          actions.push(
            modalActions.open({
              message: MESSAGE.ADDED_SUCCESSFULLY(VARIETY),
              detailMessage: {
                type: "Variety Name",
                name: payload.cropVarietyName,
              },
              subMessage: MESSAGE.ADDED_SUCCESSFULLY_SUB_MESSAGE(VARIETY),
              okButtonText: LABEL.CONTINUE,
              handleConfirmationOk: () =>
                (window.location.href = ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY),
            })
          );
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];

          if (
            err?.response?.data?.message &&
            err?.response?.status == 400 &&
            err.response.data.message.includes("already Exists")
          ) {
            actions.push(
              modalActions.open({
                message: MESSAGE.ALREADY_EXIST(VARIETY),
                subMessage: MESSAGE.ALREADY_EXIST_SUB_MESSAGE(VARIETY),
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          } else {
            actions.push(
              modalActions.open({
                message: "Something went wrong",
                subMessage: err?.response?.data?.message || "",
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          }
          return of(...actions);
        })
      );
    })
  );
};

export const putVarietyEpic = (
  action$: any,
  state$: any,
  { varietyService }: any
) => {
  return action$.pipe(
    ofType(varietyActions.PUT_VARIETY),
    mergeMap(({ payload }) => {
      console.log("payload", payload);
      return from(varietyService.editVariety({ ...payload })).pipe(
        concatMap((response: any) => {
          let actions = [];
          actions.push(
            modalActions.open({
              message: MESSAGE.UPDATED_SUCCESSFULLY(VARIETY),
              detailMessage: {
                type: "Variety Name",
                name: payload.cropVarietyName,
              },
              subMessage: MESSAGE.UPDATED_SUCCESSFULLY_SUB_MESSAGE(VARIETY),
              okButtonText: LABEL.CONTINUE,
              handleConfirmationOk: () =>
                (window.location.href = ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY),
            })
          );
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];

          if (
            err?.response?.data?.message &&
            err?.response?.status == 400 &&
            err.response.data.message.includes("already Exists")
          ) {
            actions.push(
              modalActions.open({
                message: MESSAGE.ALREADY_EXIST(VARIETY),
                subMessage: MESSAGE.ALREADY_EXIST_SUB_MESSAGE(VARIETY),
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          } else {
            actions.push(
              modalActions.open({
                message: "Something went wrong",
                subMessage: err?.response?.data?.message || "",
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          }
          return of(...actions);
        })
      );
    })
  );
};

export default combineEpics(
  addVarietyEpic,
  putVarietyEpic,
  fetchVarietyEpic,
  deleteVarietyEpic,
  updateVarietyEpic,
  fetchVarietyMetaData
);
