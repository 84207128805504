export const OPEN = "OPEN";
export const CLOSE = "CLOSE";

export const open = (object: any) => ({
  type: OPEN,
  payload: object,
});

export const close = () => ({
  type: CLOSE,
  payload: false,
});
