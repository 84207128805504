export const ADD_CROP_PLAN = "ADD_CROP_PLAN";
export const FETCH_CROP_PLAN = "FETCH_CROP_PLAN";
export const STORE_CROP_PLAN = "STORE_CROP_PLAN";
export const UPDATE_CROP_PLAN = "UPDATE_CROP_PLAN";
export const GET_PRESIGNED_URL = "GET_PRESIGNED_URL";
export const STORE_CROP_METADATA = "STORE_CROP_METADATA";
export const FETCH_CROP_METADATA = "FETCH_CROP_METADATA";
export const REQUEST_PRESIGNED_URL = "REQUEST_PRESIGNED_URL";
export const STORE_VARIETY_METADATA = "STORE_VARIETY_METADATA";
export const STORE_SIGNED_MEDIA_URL = "STORE_SIGNED_MEDIA_URL";
export const FETCH_CROP_PLAN_DETAILS = "FETCH_CROP_PLAN_DETAILS";
export const STORE_CROP_PLAN_DETAILS = "STORE_CROP_PLAN_DETAILS";
export const GET_PRESIGNED_URL_DOWNLOAD = "GET_PRESIGNED_URL_DOWNLOAD";
export const FETCH_PLAN_VARIETY_METADATA = "FETCH_PLAN_VARIETY_METADATA";

export const fetchCropPlanData = () => ({
  type: FETCH_CROP_PLAN,
});

export const storeCropPlanData = (data: any) => ({
  type: STORE_CROP_PLAN,
  payload: data,
});

export const fetchCropMetaData = () => ({
  type: FETCH_CROP_METADATA,
});

export const fetchPlanVarietyMetaData = (data: any) => ({
  type: FETCH_PLAN_VARIETY_METADATA,
  payload: data,
});

export const storeVarietyMetaData = (data: any) => ({
  type: STORE_VARIETY_METADATA,
  payload: data,
});

export const storeCropMetaData = (data: any) => ({
  type: STORE_CROP_METADATA,
  payload: data,
});

export const fetchCropPlanDetails = (data: any) => ({
  type: FETCH_CROP_PLAN_DETAILS,
  payload: data,
});

export const storeCropPlanDetails = (data: any) => ({
  type: STORE_CROP_PLAN_DETAILS,
  payload: data,
});

export const addCropPlan = (data: any) => ({
  type: ADD_CROP_PLAN,
  payload: data,
});

export const updateCropPlan = (data: any) => {
  return {
    type: UPDATE_CROP_PLAN,
    payload: data,
  };
};

export const getPreSignedURL = (data: any) => ({
  type: GET_PRESIGNED_URL,
  payload: data,
});

export const getPreSignedURLDownload = (data: any) => ({
  type: GET_PRESIGNED_URL_DOWNLOAD,
  payload: data,
});

export const requestPreSignedURL = (data: any) => ({
  type: REQUEST_PRESIGNED_URL,
  payload: data,
});

export const storeSignedMediaUrl = (data: any) => ({
  type: STORE_SIGNED_MEDIA_URL,
  payload: data,
});
