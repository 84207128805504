import React from "react";
import { Provider } from "react-redux";
import Routes from "../routes";
import store from "../store";
import "../../public/assets/css/main.scss";
import ConfirmationModal from "./common/confirmationModal";
import MoengageProvider from "./MoengageProvider";

export class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <MoengageProvider>
          <>
            <Routes />
            <ConfirmationModal />
          </>
        </MoengageProvider>
      </Provider>
    );
  }
}

export default App;
