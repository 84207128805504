import React, { FC } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LABEL } from "src/constants/commonConstants";
import { hasPermission } from "src/utilis/PermissionList";
import CropManagementIcon from "src/images/crop-management.png";
import RespondToFarmersIcon from "src/images/respond-to-farmers.png";
import CropPlanManagementIcon from "src/images/crop-plan-management.png";
import { ROUTECONSTANTS, ROUTENAME } from "../../constants/routeConstants";

interface menuRrops {
  role?: any;
  route: string;
  icon: string;
  label: string;
  menuItems: {
    routeName: string;
    label: string;
    url: string;
  }[];
}

interface SidebarProps {
  role?: any;
  parentKey: string;
  childKey?: string;
}

export const getMenu = ({
  role,
  route,
  icon,
  label,
  menuItems,
  parentKey,
  setParentKey,
  childKey,
}: any) => {
  const userHasPermission = hasPermission({
    role,
    path: route,
  });

  return !userHasPermission ? null : (
    <li
      className={`parent-title ${
        parentKey === route ? "parentActive" : "inactive"
      }`}
    >
      <a
        href="#"
        className="parent-menu"
        onClick={() => setParentKey(parentKey === route ? "" : route)}
      >
        <div>
          <img className="side-icon-management" src={icon} />
        </div>
        <div>{label}</div>
      </a>
      <ul className="child-menu">
        {menuItems.map((item: any, index: any) => {
          return (
            <li
              key={index}
              className={` ${childKey === item.routeName ? "active" : ""}`}
            >
              <Link to={item.url}>{item.label}</Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export const Sidebar = (props: any) => {
  let { childKey } = props;
  const [parentKey, setParentKey] = React.useState(props.parentKey);

  const menuItems = {
    queries: [
      {
        label: LABEL.ACTIVE_QUERIES,
        routeName: ROUTENAME.QUERY_MANAGEMENT_ACTIVE_QUERIES,
        url: ROUTECONSTANTS.QUERY_MANAGEMENT_ACTIVE_QUERIES,
      },
      {
        label: LABEL.NEW_QUERIES,
        routeName: ROUTENAME.QUERY_MANAGEMENT_NEW_QUERIES,
        url: ROUTECONSTANTS.QUERY_MANAGEMENT_NEW_QUERIES,
      },
    ],
    cropManagement: [
      {
        label: LABEL.ADD_NEW_CROP,
        routeName: ROUTENAME.CROP_MANAGEMENT_CROP,
        url: ROUTECONSTANTS.CROP_MANAGEMENT_CROP,
      },
      {
        label: LABEL.ADD_NEW_VARIETY,
        routeName: ROUTENAME.CROP_MANAGEMENT_VARIETY,
        url: ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY,
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="side-navigation">
        <ul>
          {getMenu({
            role: props.role,
            route: ROUTENAME.QUERY_MANAGEMENT,
            icon: RespondToFarmersIcon,
            label: LABEL.RESPOND_TO_FARMERS,
            menuItems: menuItems.queries,
            parentKey,
            setParentKey,
            childKey,
          })}

          {hasPermission({
            role: props.role,
            path: ROUTENAME.CROP_PLAN_MANAGEMENT,
          }) && (
            <li
              className={`parent-title ${
                parentKey === ROUTENAME.CROP_PLAN_MANAGEMENT
                  ? "parentActive"
                  : "inactive"
              }`}
            >
              <Link
                className="single-parent"
                to={ROUTECONSTANTS.CROP_PLAN_MANAGEMENT}
              >
                <div>
                  <img
                    className="side-icon-plan"
                    src={CropPlanManagementIcon}
                  />{" "}
                </div>
                <div>{LABEL.CROP_PLAN_MANAGEMENT}</div>
              </Link>
            </li>
          )}

          {getMenu({
            role: props.role,
            route: ROUTENAME.CROP_MANAGEMENT,
            icon: CropManagementIcon,
            label: LABEL.CROP_MANAGEMENT,
            menuItems: menuItems.cropManagement,
            parentKey,
            setParentKey,
            childKey,
          })}
        </ul>
      </div>
    </React.Fragment>
  );
};

export const mapStateToProps = (state: any) => ({
  role: state.auth.role,
});

const connector = connect(mapStateToProps, {});

export default connector(Sidebar);
