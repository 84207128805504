import React, { FC, useState } from "react";
import PropTypes from "prop-types";
import { Outlet } from "react-router-dom";
import Appbar from "../common/layout/Appbar";
import Sidebar from "../../components/sideNav/index";

interface SidebarProps {
  parentKey: string;
  childKey?: string;
  children: object;
  noPadding?: boolean;
}

const AppContainer: FC<SidebarProps> = (props: SidebarProps) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="grid-container">
      <Appbar toggleMenu={toggleMenu} />
      <div className="main-body">
        {isMenuOpen && <Sidebar {...props} />}
        <div
          className={`content ${isMenuOpen ? "" : "main-content-no-sidebar"} ${
            !props.noPadding && "p-5"
          }`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

AppContainer.prototype = {
  children: PropTypes.any,
  noPadding: false,
};

export default AppContainer;
