import { KatBox, KatCheckbox, KatDropdown } from "@amzn/katal-react";
import React, { useState } from "react";

type option = {
  name: string;
  value: string;
};

interface Props {
  label: string;
  placeholder: string;
  options?: option[];
  value: string[];
  disabled?: boolean;
  onChange?: (e: any) => void;
}

export const getMultiSelectProps = (setMouseEntered: any) => {
  return {
    tabIndex: 1,
    onMouseEnter: () => setMouseEntered(true),
    onMouseLeave: () => setMouseEntered(false),
    className: "options-container-for-testing",
  };
};

export const generateOptions = (
  options: option[],
  value: string[],
  onChange?: Function
) => {
  return options.map((item: option, index) => (
    <KatCheckbox
      key={index}
      label={item.name}
      className="multiselect-container-options-item"
      value={item.value}
      checked={value.includes(item.value)}
      onChange={(e: any) => {
        var processedVal: string[] = [];
        if (e.detail.checked) {
          processedVal = [...value, item.value];
        } else {
          processedVal = value.filter((i) => i !== item.value);
        }
        onChange?.(processedVal);
      }}
    />
  ));
};

export const MultiSelect = ({
  label,
  placeholder,
  options,
  disabled = false,
  value,
  onChange,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [mouseEntered, setMouseEntered] = useState(false);

  return (
    <div className="multiselect" onBlur={() => !mouseEntered && setOpen(false)}>
      <article className="multiselect-container">
        <KatDropdown
          {...{ disabled }}
          className={`form-select`}
          placeholder={placeholder}
          label={label}
          onClick={() => !disabled && setOpen(!open)}
          options={[
            {
              name: value.join(", "),
              value: value.join(", "),
            },
          ]}
          value={value.join(", ")}
        />
        {open && (
          <div {...getMultiSelectProps(setMouseEntered)}>
            <KatBox variant="ltgrey" className="multiselect-container-options">
              {options && generateOptions(options, value, onChange)}
            </KatBox>
          </div>
        )}
      </article>
    </div>
  );
};

export default MultiSelect;
