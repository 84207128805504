import { find } from "lodash";
import { connect } from "react-redux";
import CropPlanDrawer from "./CropPlanDrawer";
import { KatButton } from "@amzn/katal-react";
import React, { Fragment, useState } from "react";
import * as queryActions from "src/actions/queryActions";
import * as modalActions from "src/actions/modalActions";
import { LABEL, LANGUAGES } from "src/constants/commonConstants";
import { endChatModal } from "src/helper/modal";

export const Header = ({
  showModal,
  queryDetails,
  endQueryChat,
  queryCropPlan,
  growthActivities,
  fetchQueryCropPlan,
  storeQueryCropPlan,
  storeFetchGrowthActivities,
}: any) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <header className="active-queries-chats-header">
        <article className="px-5 py-3 d-flex align-items-center justify-content-between active-queries-chats-header-profile">
          <div className="d-flex align-items-center">
            <aside>
              <img
                src={require("../../../../../images/farmer_avatar.png").default}
                alt="image desc"
              />
            </aside>
            <div className="ps-3">
              <h2 className="mb-1">{queryDetails.id}</h2>
              <p className="m-0 text-secondary text-size-md">
                {LABEL.LANGUAGE}:{" "}
                {find(LANGUAGES, { value: queryDetails?.language })?.name}
              </p>
            </div>
          </div>

          <KatButton
            label={LABEL.END_CHAT}
            variant="danger"
            className="active-queries-chats-header-profile-end-call"
            onClick={() =>
              endChatModal({ showModal, endQueryChat, queryDetails })
            }
          />
        </article>

        <section className="active-queries-chats-header-details px-5 py-3">
          <div>
            <span className="text-secondary">{LABEL.CROP_NAME}:</span>{" "}
            {queryDetails.cropName}
          </div>
          <div>
            <span className="text-secondary">{LABEL.VARIETY}:</span>{" "}
            {queryDetails.cropVarietyName}
          </div>
          <div>
            <span className="text-secondary">{LABEL.SOWING_DATE}:</span>{" "}
            {queryDetails.sowingDate}
          </div>
          <div>
            <KatButton
              variant="link"
              className="blue-link-button"
              onClick={() => {
                fetchQueryCropPlan({
                  cropPlanId: queryDetails.farmerCropPlanId,
                  sowingDate: queryDetails.sowingDate,
                });

                setOpen(true);
              }}
            >
              {LABEL.CHECK_CROP_PLAN}
              <span className="html-symbol">&nbsp;&rsaquo;</span>
            </KatButton>
          </div>
        </section>
      </header>
      <CropPlanDrawer
        {...{
          open,
          setOpen,
          queryDetails,
          queryCropPlan,
          growthActivities,
          storeQueryCropPlan,
          storeFetchGrowthActivities,
        }}
      />
    </Fragment>
  );
};

export const mapStateToProps = (state: any) => ({
  queryCropPlan: state.query.queryCropPlan,
  growthActivities: state.query.growthActivities,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchQueryCropPlan: (data: any) => {
    dispatch(queryActions.fetchQueryCropPlan(data));
  },
  storeQueryCropPlan: (data: any) => {
    dispatch(queryActions.storeQueryCropPlan(data));
  },
  storeFetchGrowthActivities: (data: any) => {
    dispatch(queryActions.storeFetchGrowthActivities(data));
  },
  endQueryChat: (data: any) => {
    dispatch(queryActions.endQueryChat(data));
  },
  showModal: (data: any) => {
    dispatch(modalActions.open(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Header);
