import { AnyAction } from "redux";
import * as authActions from "../actions/authActions";

interface AuthState {
  userId: string;
  fullName: string;
  role: string[];
}

const initialState: AuthState = {
  userId: "",
  fullName: "",
  role: ["Upaj-Admins"],
};

const authReducer = (state = initialState, action: AnyAction) => {
  const type = action.type;
  const payload = action.payload;

  switch (type) {
    case authActions.STORE_USER_DETAILS:
      return { ...payload };
    default:
      return state;
  }
};

export default authReducer;
