import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { LABEL } from "src/constants/commonConstants";
import { KatBadge, KatDivider } from "@amzn/katal-react";

const SideNav = ({
  activeQueryList,
  setSelectedQuery,
  selectedQuery,
  readQueryChat,
  setClickedFromSideNav,
}: any) => {
  const [selected, setSelected] = React.useState(0);

  useEffect(() => {
    if (selectedQuery?.id) {
      setSelected(selectedQuery.id);
    }
  }, [selectedQuery]);

  return (
    <aside className="active-queries-profiles">
      <h2 className="pt-4 ps-3 pb-3">{LABEL.ACTIVE_QUERIES}</h2>
      {activeQueryList &&
        activeQueryList.map((item: any, index: any) => {
          return (
            <Fragment key={index}>
              <button
                className={`active-queries-profiles-profile ${
                  selected === item.id &&
                  "active-queries-profiles-profile-selected"
                }`}
                onClick={() => {
                  if (item?.unSeenByAgronomist != 0) {
                    readQueryChat({
                      farmerId: item.farmerId,
                      farmerCropId: item.farmerCropId,
                    });
                  }
                  setSelected(item.id);
                  setSelectedQuery(item);
                  setClickedFromSideNav(true);
                }}
              >
                <article>
                  <aside>
                    <img
                      src={
                        require("../../../../images/farmer_avatar.png").default
                      }
                      alt="image desc"
                    />
                  </aside>
                  <main>
                    <header className="d-flex align-items-center justify-content-between mb-2">
                      <h5 className="mb-0">{item.id?.split("-")[0]} </h5>
                      {item?.unSeenByAgronomist ? (
                        <span className="badge">
                          {item?.unSeenByAgronomist}
                        </span>
                      ) : null}
                      <span className="text-size-sm text-secondary">
                        {moment(item?.updatedTime).fromNow()}
                      </span>
                    </header>
                    <p className="mb-2 ellipsis">{item?.recentMessage}</p>
                    <KatBadge
                      className="transparent-badges me-2"
                      label={item.cropName}
                    ></KatBadge>

                    <KatBadge
                      className="transparent-badges me-2"
                      label={item.cropVarietyName}
                    ></KatBadge>
                  </main>
                </article>
              </button>
              <KatDivider className="divider" />
            </Fragment>
          );
        })}
    </aside>
  );
};

export default SideNav;
