import { KatButton } from "@amzn/katal-react";
import { map } from "lodash";
import React, { Children } from "react";
import ReactPlayer from "react-player";

const CropPlanStepper = ({ data = [], header, asideWidth }: any) => {
  const [play, setPlay] = React.useState(false);
  return (
    <div className="plan-stepper">
      <section className="plan-stepper-main mt-3">
        <aside className="plan-stepper-main-col1" style={{ width: asideWidth }}>
          <span className="text-secondary">
            {header?.aside ? `${header?.aside}` : ""}
          </span>
        </aside>
        <article className="plan-stepper-main-col2">
          <div>
            {header?.completed ? (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="11"
                  fill="#B9E8F7"
                  stroke="#00A8E1"
                  strokeWidth="2"
                />
                <path
                  d="M9.99556 14.6001L6.70732 11.3099C6.31692 10.9192 5.68375 10.919 5.29311 11.3094C4.90247 11.6998 4.90227 12.333 5.29268 12.7237L9.2882 16.7216C9.67876 17.1124 10.3122 17.1124 10.7028 16.7216L17.714 9.70693C18.1044 9.3163 18.1042 8.68314 17.7136 8.29271C17.323 7.90229 16.6898 7.90245 16.2994 8.29307L9.99556 14.6001Z"
                  fill="black"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12"
                  cy="12"
                  r="11"
                  fill="#B9E8F7"
                  stroke="#077398"
                  stroke-width="2"
                />
              </svg>
            )}
          </div>
          <div className="plan-stepper-main-col2-path"></div>
        </article>
        <main className="plan-stepper-main-col3">
          <h3>{header.title}</h3>
        </main>
      </section>

      {Children.toArray(
        data.map((item: any) => (
          <section className="plan-stepper-main">
            <aside
              className="plan-stepper-main-col1"
              style={{ width: asideWidth }}
            >
              <span className="text-secondary">
                {item?.aside ? `${item?.aside}` : ""}
              </span>
            </aside>
            <article className="plan-stepper-main-col2">
              <div>
                {item?.completed ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11"
                      fill="#B9E8F7"
                      stroke="#00A8E1"
                      strokeWidth="2"
                    />
                    <path
                      d="M9.99556 14.6001L6.70732 11.3099C6.31692 10.9192 5.68375 10.919 5.29311 11.3094C4.90247 11.6998 4.90227 12.333 5.29268 12.7237L9.2882 16.7216C9.67876 17.1124 10.3122 17.1124 10.7028 16.7216L17.714 9.70693C18.1044 9.3163 18.1042 8.68314 17.7136 8.29271C17.323 7.90229 16.6898 7.90245 16.2994 8.29307L9.99556 14.6001Z"
                      fill="black"
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="12"
                      r="11"
                      fill="white"
                      stroke="#077398"
                      strokeWidth="2"
                    />
                  </svg>
                )}
              </div>
              <div className="plan-stepper-main-col2-path"></div>
            </article>
            <main className="plan-stepper-main-col3">
              <h3>{item?.title}</h3>
              <p className="m-0 p-0">{item?.description}</p>
              <section className="plan-stepper-main-col3-images-grid">
                {Children.toArray(
                  map(item?.videoList, (item) => {
                    return (
                      <div>
                        <ReactPlayer
                          key={item.id}
                          url={item.videoUrl}
                          light={item.thumbnail}
                          className="react-player"
                          controls
                          playing
                          width="200"
                          height="200"
                        />
                        {item?.videoDescription && (
                          <div className="videoDescription">
                            {item?.videoDescription}
                          </div>
                        )}
                      </div>
                    );
                  })
                )}
              </section>
              <section className="plan-stepper-main-col3-images-grid">
                {Children.toArray(
                  map(item?.mediaList, (item) => {
                    return (
                      <img
                        src={item}
                        alt={item}
                        className="landscape-image m-0"
                      />
                    );
                  })
                )}
              </section>
              <div className="mb-3"></div>
            </main>
          </section>
        ))
      )}
    </div>
  );
};

export default CropPlanStepper;
