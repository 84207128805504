import moment from "moment";
import { find, isEmpty, lowerCase, map, startCase } from "lodash";
import { connect } from "react-redux";
import * as queryActions from "src/actions/queryActions";
import { LABEL, LANGUAGES, REGEX } from "src/constants/commonConstants";
import React, { Children, useEffect, useState } from "react";
import { KatButton, KatModal, KatBox, KatIcon } from "@amzn/katal-react";
import { getSignedMediaURL } from "src/helper";

export const Preview = ({
  querId: queryId,
  setModal,
  showModal,
  chats,
  fetchQuery,
  queryList,
  handleRespond,
  chats3MediaList,
}: any) => {
  const [info, setInfo] = useState({
    cropName: "",
    cropVarietyName: "",
    currentStage: "",
    language: "",
    duration: "",
  });

  const messagesEndRef = React.createRef();

  useEffect(() => {
    if (showModal) {
      console.log(queryId);
      fetchQuery(queryId);
      setInfo(find(queryList, { id: queryId }));
    }
  }, [queryId, showModal]);

  useEffect(() => {
    scrollToBottom(messagesEndRef);
  }, [chats]);

  const scrollToBottom = (messagesEndRef: any) => {
    messagesEndRef?.current?.scrollIntoView();
  };

  return (
    <KatModal
      visible={showModal}
      className="custom-css previewModal"
      onClose={() => setModal(false)}
      no-close-icon
    >
      <svg
        width={34}
        height={34}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="preview-query-close-icon"
        onClick={() => setModal(false)}
      >
        <path
          d="M17 31.167c7.824 0 14.167-6.343 14.167-14.167S24.824 2.833 17 2.833 2.834 9.176 2.834 17 9.175 31.167 17 31.167zM21.25 12.75l-8.5 8.5M12.75 12.75l8.5 8.5"
          stroke="#fff"
          strokeWidth={2.83333}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      {/* <KatIcon name="close" size="small" className="preview-query-close-icon"></KatIcon> */}
      <div className="queryIdentifier">
        <div>
          <img
            className="profileIcon"
            src={require("../../../../images/farmer_avatar.png").default}
          />
        </div>
        <div>
          <div className="queryId">{queryId}</div>
          <div className="queryLanguage">{`Language :  ${
            find(LANGUAGES, { value: info?.language })?.name
          }`}</div>
        </div>
      </div>
      <div className="queryStatusDetails">
        <div className="queryStatus">
          <div className="statusDetails">
            <span className="statusLabel">Crop</span>
            <span className="statusValue">{info?.cropName}</span>
          </div>
          <div className="statusDetails">
            <span className="statusLabel">Variety</span>
            <span className="statusValue">{info?.cropVarietyName}</span>
          </div>
          {/* <div className="statusDetails">
            <span className="statusLabel">Stage</span>
            <span className="statusValue">{info?.currentStage}</span>
          </div> */}
          <div className="statusDetails duration">
            <span className="statusLabel">Duration</span>
            <span className="statusValue">{`${info?.duration || 0} Days`}</span>
          </div>
        </div>
      </div>

      <KatBox variant="white-junglemist">
        {map(
          chats,
          ({
            createdTime,
            message,
            farmerId,
            hasMedia,
            mediaUrl,
            readStatus,
            senderId,
            id,
          }) => {
            return (
              <div
                className={`queryBlock w-75 ${
                  farmerId === senderId ? "query" : "reply"
                }`}
              >
                <div className="userIdentifier">
                  <span>
                    {farmerId === senderId
                      ? LABEL.AMAZON_FARMER
                      : LABEL.AGRONOMIST}{" "}
                  </span>
                  <span className="queryTime">
                    {moment(createdTime).fromNow()}
                  </span>
                  <div className="mt-2">
                    {hasMedia.toString() === "true" &&
                      getSignedMediaURL(
                        id,
                        {
                          [REGEX.MEDIA_LINK_MP4.test(mediaUrl).toString()]:
                            "video",
                          [REGEX.MEDIA_LINK_MP3.test(mediaUrl).toString()]:
                            "audio",
                          [REGEX.MEDIA_LINK.test(mediaUrl).toString()]: "image",
                        }["true"],
                        chats3MediaList
                      )}
                  </div>
                </div>
                <div className="messageContent">{message}</div>
              </div>
            );
          }
        )}
      </KatBox>
      <div slot="footer" className="kat-group-horizontal">
        <KatButton
          className="previewButton"
          label="Respond"
          onClick={handleRespond}
        >
          <article className="respondBtn">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.2634 15.2673C6.72226 15.2673 7.10045 15.6677 7.15213 16.1835L7.15815 16.3044V18.2946L10.1782 15.4946C10.5344 15.1643 11.0416 15.2007 11.3603 15.56L11.4358 15.6565C11.7208 16.0694 11.6894 16.6572 11.3794 17.0267L11.2961 17.1142L6.82235 21.2626C6.26313 21.7812 5.44696 21.3642 5.37392 20.5689L5.36865 20.4528V16.3044C5.36865 15.7316 5.76925 15.2673 6.2634 15.2673Z"
                fill="white"
              />
              <path
                d="M19.4876 0.747986C20.4797 0.747986 21.3877 1.39016 21.4682 2.44112L21.474 2.59402V15.4955C21.474 16.5843 20.6054 17.2765 19.6279 17.3372L19.4876 17.3415H10.737C10.2428 17.3415 9.84224 16.8772 9.84224 16.3044C9.84224 15.7726 10.1877 15.3342 10.6326 15.2743L10.737 15.2673H19.4876C19.551 15.2673 19.6097 15.2589 19.6593 15.2453L19.6845 15.2352V2.85122L19.6311 2.83652L19.5629 2.82599L19.4876 2.82218H1.98634C1.92295 2.82218 1.86433 2.83064 1.81472 2.84416L1.7895 2.85225V15.2362L1.81472 15.2453C1.83952 15.2521 1.86658 15.2576 1.89536 15.2614L1.98634 15.2673H6.26325C6.7574 15.2673 7.158 15.7317 7.158 16.3044C7.158 16.8363 6.81259 17.2746 6.36759 17.3345L6.26325 17.3415H1.98634C1.00022 17.3415 0.0868251 16.6988 0.00582662 15.6483L0 15.4955V2.59402C0 1.50523 0.868571 0.813012 1.84605 0.752333L1.98634 0.747986H19.4876Z"
                fill="white"
              />
              <ellipse
                cx="5.3686"
                cy="9.04474"
                rx="1.7895"
                ry="2.07419"
                fill="white"
              />
              <ellipse
                cx="10.7373"
                cy="9.04474"
                rx="1.7895"
                ry="2.07419"
                fill="white"
              />
              <ellipse
                cx="16.1054"
                cy="9.04474"
                rx="1.7895"
                ry="2.07419"
                fill="white"
              />
            </svg>
            <div>Respond</div>
          </article>
        </KatButton>
      </div>
    </KatModal>
  );
};
export const mapStateToProps = (state: any) => ({
  queryList: state.query.queryList,
  chats: state.query.chats,
  chats3MediaList: state.query.chats3MediaList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchQuery: (queryId: any) => {
    dispatch(queryActions.getQueryById(queryId));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Preview);
