export const SOWING_TYPE = {
  mr: [
    { name: "प्रत्यारोपण", value: "प्रत्यारोपण" },
    { name: "थेट पेरणी", value: "थेट पेरणी" },
  ],
  hi: [
    { name: "सीधी बुवाई", value: "सीधी बुवाई" },
    { name: "प्रत्यारोपण", value: "प्रत्यारोपण" },
  ],
};

export const SOWING_TYPE_ENUM = {
  प्रत्यारोपण: "TRANSPLANTING",
  "सीधी बुवाई": "DIRECT_SOWING",
  "थेट पेरणी": "DIRECT_SOWING",
};

export const VARIETY_TYPE_OPTIONS = [
  { name: "Long Duration", value: "LONG_DURATION" },
  { name: "Short Duration", value: "SHORT_DURATION" },
  { name: "Medium Duration", value: "MEDIUM_DURATION" },
];

export const DEFAULT_TRANSLATION = "Hindi : टमाटर";

export const PAGE_SIZE = 10;

export const PRESIGNED_CROP_PLAN_TYPE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
};

export const CROP_PLAN_CSV_SAMPLE =
  "s3://upaj-crop-plans-beta/परीक्षा/टेस्टिंग यूनिट/जेनेरिक/Hindi_परीक्षा_टेस्टिंग यूनिट_जेनेरिक_प्रत्यारोपण.csv";

export const REQUIRE_CSV_HEADER = [
  "Growth Stage Name",
  "Growth Stage Active Icon",
  "Growth Stage In-Active Icon",
  "Growth Stage Completed Icon",
  "Growth Stage Start Day",
  "Activity Name",
  "Activity Start Date",
  "Description",
];
export const CSV_IMAGES_HEADER = [
  "Image 1",
  "Image 2",
  "Image 3",
  "Video 1",
  "Video 2",
  "Video 3",
  "Video 1 Desc",
  "Video 2 Desc",
  "Video 3 Desc",
  "Thumbnail 1",
  "Thumbnail 2",
  "Thumbnail 3",
];
export const CSV_HEADER = REQUIRE_CSV_HEADER.concat(CSV_IMAGES_HEADER);
export const CSV_FILE_NAME = "cropPlanSampleTemplate.csv";

export const CROP_PLAN_FILE_NAME =
  "Hindi_Tomato_Abhilash_Syngenta_Transplanting.csv";

export const TEXT_LENGTH = {
  CROP_NAME: 50,
  VARIETY_NAME: 80,
  COMPANY_NAME: 50,
  VARIETY_DESCRITPION: 120,
};

export const LABEL = {
  REPLACE_FILE: "Replace File",
  KEEP_EXIST_FILE: "Keep Existing File",
  RESPOND_TO_FARMERS: "Respond to Farmers",
  ACTIVE_QUERIES: "Active Queries",
  NEW_QUERIES: "New Queries",
  CROP_PLAN_MANAGEMENT: "Crop Plan Management",
  CROP_MANAGEMENT: "Crop Management",
  ADD_NEW_CROP: "Add New Crop",
  CROP: "Crop",
  EDIT_CROP: "Update Crop",
  VARIETY: "Variety",
  ADD_NEW_VARIETY: "Add New Variety",
  EDIT_VARIETY: "Update Variety",
  STATUS: "Status",
  CROP_NAME: "Crop Name",
  VARIETY_NAME: "Variety Name",
  LANGUAGE: "Language",
  RESPOND: "RESPOND",
  PREVIEW_QUERY: "Preview Query",
  COMPANY_NAME: "Company Name",
  SOWING_TYPE: "Sowing Type",
  SOWING_DATE: "Sowing Date",
  FILTER: "Filter",
  ACTION: "Action",
  BACK: "Back",
  CLOSE: "Close",
  CROP_PLAN_NAME: "Crop Plan Name",
  FILE_NAME: "File Name",
  UPLOAD_CROP_PLAN: "Upload Crop Plan",
  DOWNLOAD: "Download",
  DOWNLOAD_HERE: "Download here",
  UPLOAD_FILE: "Upload File",
  SAMPLE_CROP_PLAN_TEMPLATE: "Sample crop plan template.",
  SELECT_LANGUAGE: "Select Language",
  SELECT_STATUS: "Select Status",
  SELECT_SOWING_TYPE: "Select Sowing Type",
  SELECT_COMPANY: "Select Company",
  SELECT_VARIETY: "Select Variety",
  SELECT_CROP: "Select Crop",
  ENTER_VARIETY: "Enter Variety",
  ENTER_COMPANY: "Enter Company",
  MEDIA_LINK: "Media Link",
  ENTER_MEDIA_LINK: "Enter Media Link",
  SEED_RATE: "Seed Rate",
  TYPE_HERE: "Type here",
  SEASON: "Season",
  SELECT_SEASON: "Select Season",
  VARIETY_TYPE: "Variety Type",
  SELECT_VARIETY_TYPE: "Select Variety Type",
  SELECT_MONTH: "Select Month",
  MONTH: "Month",
  AGROCLIMATIC_ZONES: "Agroclimatic Zones",
  SELECT_AGROCLIMATIC_ZONES: "Agroclimatic Zones",
  SUB_AGROCLIMATIC_ZONES: "Sub-Agroclimatic Zones",
  SELECT: "Select",
  SOIL_TYPE: "Soil type",
  SELECT_SOIL_TYPE: "Select Soil type",
  DESCRIPTION: "Description",
  ENTER_DESCRIPTION: "Enter description",
  ADD_VARIETY: "Add Variety",
  DELETE: "Delete",
  EDIT: "Edit",
  CANCEL: "Cancel",
  CONTINUE: "Continue",
  SAVE: "Save",
  TRY_AGAIN: "Try Again",
  DELETE_POPUP_TITLE: "Are you sure you want to delete?",
  DELETE_POPUP_SUBTITLE:
    "Once you delete this you won’t able to retrive the same",
  JUST_NOW: "Just Now",
  // CURRENT_STAGE: "Current Stage",
  STAGE: "Stage",
  CHECK_CROP_PLAN: "Check Crop Plan",
  END_CHAT: "End Chat",
  DURATION: "Duration",
  END_CHAT_POPUP_TITLE: "Are you sure you want to end chat?",
  END_CHAT_POPUP_SUBTITLE:
    "Once you end this chat, you will not be able to reply to this particular query",
  CROP_PLAN_HISTORY: "Crop Plan History",
  DATE_UPLOADED: "Date Uploaded",
  UPLOADED_BY: "Uploaded By",
  CROP_PLAN: "Crop Plan",
  CROP_PLAN_PREVIEW: "Crop Plan Preview",
  SEND_MEDIA: "Send Media",
  SEND: "Send",
  DAYS: "Days",
  AMAZON_FARMER: "Amazon Farmer",
  AGRONOMIST: "Agronomist",
  UPLOAD: "Upload",
};

export const QUERY_TYPE = {
  new: "NEW",
  active: "ACTIVE",
  closed: "CLOSED",
};

export const PAGETITLE = {
  CROP: "Add New Crop",
  VARIETY: "Add New Variety",
  NEW_QUERIES: "New Queries",
};

export const LANGUAGES = [
  { name: "Hindi", value: "hi" },
  { name: "Marathi", value: "mr" },
];

export const LANGUAGES_CODE = {
  hi: "Hindi",
  mr: "Marathi",
};

export const STATUS = [
  { name: "Active", value: "ACTIVE" },
  { name: "Draft", value: "DRAFT" },
  { name: "Inactive", value: "INACTIVE" },
];

export const SOURCE_LANGUAGE_CODE = "en";

export const MESSAGE = {
  SUB_MESSAGE_INVALID_LINK: "Invalid image link.",
  DELETE: "Are you sure you want to delete?",
  TEXT_LENGTH: (type: string, length: number) =>
    `${type} (should not exceed more than ${length} characters)`,
  SUB_AGROCLIMATIC_NOT_AVAILABLE:
    "Sub-Agroclimatic Zones is not available for selected Agroclimatic Zones.",
  SUB_DELETE_MESSAGE:
    "Once you delete this you won’t able to retrieve the same.",
  ACTIVE_DELETE: (type: string) =>
    `You cannot delete  a crop which belongs to active ${type} cycle.`,
  ACTIVE_DELETE_SUB_MESSAGE: `To delete, first change  the status to inactive or draft.`,
  CHANGE_STATUS: (type: string) =>
    `Are you sure you want to change ${type} status?`,
  SUB_CHANGE_STATUS:
    "Once the status is active it will be visible on the portal.",
  REQUIRE_MESSAGE: (type: string) =>
    `Please enter appropriate details of the ${type}.`,
  ALREADY_EXIST: (type: string) => `This ${type} already exists.`,
  ALREADY_EXIST_SUB_MESSAGE: (type: string) =>
    `Please enter a different ${type} name.`,
  ADDED_SUCCESSFULLY: (type: string) => `New ${type} Added.`,
  ADDED_SUCCESSFULLY_SUB_MESSAGE: (type: string) =>
    `${type} added successfully.`,
  UPDATED_SUCCESSFULLY: (type: string) => `${type} Updated.`,
  UPDATED_SUCCESSFULLY_SUB_MESSAGE: (type: string) =>
    `${type} updated successfully.`,
  SEED_RATE_LIMIT_SUB: `Seed Rate should be between 1 to 5000`,
  INVALID_SEED_RATE: `Invalid Seed Rate`,
  REQUIRE_CSV_HEADER: `Uploaded crop plan.csv file does not follow pre-defined format`,
  UPLOAD_FILE: "Upload File",
  FILE_UPLOADED_SUB_MESSAGE: `File uploaded successfully.`,
  UPLOADED_FILE_EXIST_MESSAGE: `Uploaded file already exist.`,
  REQUIRE_FILE_FILEDS: `You must select relevant filters before uploading the crop plan`,
  REQUIRE_FILE_FILEDS_SUB_MESSAGE: `Select the relevent options from the given dropdown`,
  REQUIRE_CSV_FILE: `Select a .csv file to upload`,
  REQUIRE_CSV_FILE_SUB_MESSAGE: `You can select a csv file saved on your system`,
  REQUIRE_CSV_FILE_DATA: `Selected csv file has no data available`,
  REQUIRE_CSV_FILE_DATA_SUB_MESSAGE: `Please select the appropriate csv file`,
  FILE_SIZE_0KB: `File size of crop plan.csv file is 0KB`,
  FAILED_TO_DOWNLOAD: `Failed to Download`,
  FILE_DOES_NOT_EXISTS_IN_S3: "File does not Exist.",
};

export const DATE_FORMATS = {
  v1: "DD MMMM YYYY",
  v2: "DD/MM/YY, hh:mm a",
  v3: "DD/MM/YY",
  v4: "hh:mm a",
  v5: "DD MMMM, YYYY",
};

export const REGEX = {
  SEED_RATE: /^(?:[1-9]\d{0,2}|[1-4]\d{3}|5000)$/,    // seed limit regex for allow values from 1 to 5000
  MEDIA_LINK: /^(https:|s3:)\/\/.*\.(?:png|jpg|jpeg|bmp|svg|webp)$/i,
  MEDIA_LINK_S3: /^(s3:)\/\/.*\.(?:png|jpg|jpeg|bmp|svg|webp)$/i,
  MEDIA_LINK_MP4: /^(s3:)\/\/.*\.(?:\mp4)$/i,
  MEDIA_LINK_MP3: /^(s3:)\/\/.*\.(?:\mp3)$/i,
  VALID_STRING: /.*\S.*/,
};
