import {
  KatBox,
  KatButton,
  KatColumn,
  KatIcon,
  KatRow,
  KatTextarea,
} from "@amzn/katal-react";
import {
  filter,
  find,
  flatten,
  identity,
  includes,
  isEmpty,
  map,
  omit,
  pickBy,
  toPairs,
  uniq,
  uniqBy,
  values,
} from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _trim from "lodash/trim";
import * as cropActions from "src/actions/cropActions";
import * as modalActions from "src/actions/modalActions";
import * as varietyActions from "src/actions/varietyActions";
import AppContainer from "src/components/AppContainer";
import FormInput from "src/components/FormComponents/FormInput";
import FormSelect from "src/components/FormComponents/FormSelect";
import MultiSelect from "src/components/FormComponents/MultiSelect";
import {
  LABEL,
  LANGUAGES,
  LANGUAGES_CODE,
  MESSAGE,
  REGEX,
  TEXT_LENGTH,
  VARIETY_TYPE_OPTIONS,
} from "src/constants/commonConstants";
import { ROUTECONSTANTS, ROUTENAME } from "src/constants/routeConstants";

const variety = "variety";

interface AddNewVarietyProps {
  cropList: any;
  varietyMetaData: any;
  fetchCrops: () => void;
  showModal: (data: any) => void;
  editVariety: {
    id: string;
    version: string;
    status: string;
    cropId: string;
    preferredGrowingMonths: string[];
    preferredGrowingSeasons: string[];
    seedRate: string;
    cropName: string;
    preferredSoilType: string;
    varietyType: string;
    cropVarietyName: string;
    description: string;
    companyName: string;
    languageCode: string;
    agroClimaticZones: string[];
    subAgroClimaticZones: string[];
  };
  addVariety: (data: any) => void;
  putVariety: (data: any) => void;
  fetchVarietyMetaData: (data: any) => void;
}

export const AddNewVariety = ({
  cropList,
  showModal,
  addVariety,
  fetchCrops,
  putVariety,
  editVariety,
  varietyMetaData,
  fetchVarietyMetaData,
}: AddNewVarietyProps) => {
  const [metaData, setMetaData] = React.useState({
    months: [],
    seasons: [],
    cropNameList: [],
    agroclimaticZones: [],
    subAgroclimaticZones: [],
  }) as any;

  const [translating, setTranslating] = useState(false);

  const [addNewVariety, setAddNewVariety] = useState({
    id: "",
    status: "",
    version: "",
    cropId: "",
    months: Array(),
    seasons: Array(),
    language: "",
    seedRate: "",
    cropName: "",
    soilType: "",
    varietyType: "",
    varietyName: "",
    companyName: "",
    description: "",
    languageCode: "",
    agroClimaticZones: Array(),
    subAgroClimaticZones: Array(),
    translatedVarietyName: "",
    translatedDescription: "",
    translatedCompanyName: "",
  });

  useEffect(() => {
    fetchCrops();
  }, []);

  useEffect(() => {
    if (!isEmpty(editVariety)) {
      fetchVarietyMetaData({ languageCode: editVariety?.languageCode });

      setAddNewVariety({
        id: editVariety?.id || "",
        version: editVariety?.version,
        status: editVariety?.status,
        cropId: editVariety?.cropId,
        months: editVariety?.preferredGrowingMonths,
        seasons: editVariety?.preferredGrowingSeasons,
        seedRate: editVariety?.seedRate,
        cropName: editVariety?.cropName,
        soilType: editVariety?.preferredSoilType,
        varietyType: editVariety?.varietyType,
        varietyName: editVariety?.cropVarietyName,
        companyName: editVariety?.companyName,
        description: editVariety?.description,
        languageCode: editVariety?.languageCode,
        agroClimaticZones: editVariety?.agroClimaticZones,
        subAgroClimaticZones: editVariety?.subAgroClimaticZones,
        translatedVarietyName: editVariety?.cropVarietyName,
        translatedDescription: editVariety?.description,
        translatedCompanyName: editVariety?.companyName,
        language:
          LANGUAGES_CODE[
            editVariety?.languageCode as keyof typeof LANGUAGES_CODE
          ],
      });

      updateCropNameList(editVariety.languageCode);
    }
  }, [editVariety]);

  const onAgroclimaticZonesChange = (zoneList: string[]) => {
    let subAgroclimaticZonesList: any = [];
    varietyMetaData.climaticZones
      ? varietyMetaData.climaticZones.map((item: any) => {
          if (includes(zoneList, item.climaticZoneName)) {
            subAgroclimaticZonesList = subAgroclimaticZonesList.concat(
              item.subClimaticZones
            );
          }
        })
      : [];

    const subAgroClimaticZones = subAgroclimaticZonesList.map(
      (item: string) => {
        return { name: item, value: item };
      }
    );

    setMetaData((prevState: any) => ({
      ...prevState,
      subAgroClimaticZones,
    }));

    setAddNewVariety((prevState: any) => ({
      ...prevState,
      agroClimaticZones: !isEmpty(subAgroClimaticZones) ? zoneList : [],
      subAgroClimaticZones: [],
    }));

    if (
      isEmpty(editVariety) &&
      !isEmpty(zoneList) &&
      isEmpty(subAgroClimaticZones)
    ) {
      showModal({
        isOpen: true,
        message: MESSAGE.SUB_AGROCLIMATIC_NOT_AVAILABLE,
        okButtonText: LABEL.TRY_AGAIN,
      });
    }
  };

  const updateCropNameList = (languageCode: string) => {
    let cropNameList: any[] = [];
    cropList.map((item: any) => {
      if (item.languageCode == languageCode) {
        cropNameList.push({ name: item.cropName, value: item.id });
      }
    });

    setMetaData((prevState: any) => ({
      ...prevState,
      cropNameList: uniqBy(cropNameList, "name"),
    }));
  };

  const getTranslated = async ({
    varietyName,
    languageCode,
    companyName,
    description,
    fieldName,
  }: {
    varietyName: string;
    description: string;
    companyName: string;
    languageCode: string;
    fieldName: string;
  }) => {
    let translatedVarietyName = _trim(addNewVariety.translatedVarietyName);
    if (fieldName == "varietyName") {
      translatedVarietyName = _trim(varietyName)
        ? _trim(addNewVariety.varietyName)
        : "";
    }

    if (
      !isEmpty(varietyName) &&
      languageCode &&
      ["varietyName", "OnLanguageChange"].includes(fieldName)
    ) {
      setTranslating(true);
      translatedVarietyName = varietyName;  // No Translation
      setTranslating(false);
    }

    let translatedCompanyName = _trim(addNewVariety.translatedCompanyName);
    if (fieldName == "companyName") {
      translatedCompanyName = _trim(companyName)
        ? _trim(addNewVariety.companyName)
        : "";
    }

    if (
      !isEmpty(companyName) &&
      languageCode &&
      ["companyName", "OnLanguageChange"].includes(fieldName)
    ) {
      setTranslating(true);
      translatedCompanyName = companyName;    // No Translation
      setTranslating(false);
    }

    let translatedDescription = _trim(addNewVariety.translatedDescription);
    if (fieldName == "description") {
      translatedDescription = _trim(description)
        ? _trim(addNewVariety.description)
        : "";
    }

    if (
      !isEmpty(description) &&
      languageCode &&
      ["description", "OnLanguageChange"].includes(fieldName)
    ) {
      setTranslating(true);
      translatedDescription = description;    // No Translation
      setTranslating(false);
    }

    setAddNewVariety((prevState) => ({
      ...prevState,
      languageCode,
      varietyName,
      companyName,
      description,
      language: languageCode
        ? LANGUAGES_CODE[languageCode as keyof typeof LANGUAGES_CODE]
        : "",
      translatedVarietyName: _trim(translatedVarietyName),
      translatedCompanyName: _trim(translatedCompanyName),
      translatedDescription: _trim(translatedDescription),
    }));
  };

  const onLanguageChange = async (languageCode: string) => {
    fetchVarietyMetaData({ languageCode });
    updateCropNameList(languageCode);
    setAddNewVariety({
      ...addNewVariety,
      language: LANGUAGES_CODE[languageCode as keyof typeof LANGUAGES_CODE],
      languageCode,
      agroClimaticZones: Array(),
      subAgroClimaticZones: Array(),
      months: Array(),
      seasons: Array(),
      cropId: "",
      cropName: "",
    });
    getTranslated({
      ...addNewVariety,
      languageCode,
      fieldName: "OnLanguageChange",
    });
  };

  const storeChangeData = (data: any) => {
    setAddNewVariety((prevState: any) => ({
      ...prevState,
      ...data,
    }));
  };
  const requestForAddNewVariety = () => {
    const {
      id,
      status,
      version,
      cropId,
      seasons,
      months,
      seedRate,
      cropName,
      soilType,
      varietyType,
      languageCode,
      agroClimaticZones,
      subAgroClimaticZones,
      translatedVarietyName,
      translatedCompanyName,
      translatedDescription,
      description,
      companyName,
      varietyName,
    } = addNewVariety;

    const cropNameDetails = find(
      map(
        filter(
          cropList,
          (item) => item.languageCode === addNewVariety.languageCode
        ),
        ({ cropName, id }) => ({
          name: cropName,
          value: id,
        })
      ),
      { value: cropId }
    );

    const addVarietyData = {
      languageCode,
      cropId,
      cropName: cropNameDetails ? cropNameDetails.name : "",
      cropVarietyImgUrl: "https://abc.com",
      seedRate,
      preferredGrowingSeasons: seasons,
      preferredGrowingMonths: months,
      varietyType,
      agroClimaticZones: agroClimaticZones,
      subAgroClimaticZones: subAgroClimaticZones,
      preferredSoilType: "soilType",
      cropVarietyName: isEmpty(varietyName) ? "" : translatedVarietyName,
      companyName: isEmpty(companyName) ? "" : translatedCompanyName,
      description: isEmpty(description) ? "" : translatedDescription,
    };

    const inputDetails: any = {
      languageCode: LABEL.LANGUAGE,
      cropName: LABEL.CROP_NAME,
      cropVarietyName: LABEL.VARIETY_NAME,
      companyName: LABEL.COMPANY_NAME,
      cropVarietyImgUrl: LABEL.MEDIA_LINK,
      seedRate: LABEL.SEED_RATE,
      preferredGrowingSeasons: LABEL.SEASON,
      preferredGrowingMonths: LABEL.MONTH,
      varietyType: LABEL.VARIETY_TYPE,
      agroClimaticZones: LABEL.AGROCLIMATIC_ZONES,
      subAgroClimaticZones: LABEL.SUB_AGROCLIMATIC_ZONES,
      preferredSoilType: LABEL.SOIL_TYPE,
      description: LABEL.DESCRIPTION,
    };

    var subMessage: string = "";
    var requiredFields: any = [];
    map(toPairs(omit(addVarietyData, ["cropId"])), ([key, value]: any) => {
      if (isEmpty(value)) requiredFields.push(inputDetails[key]);
    });

    if (!isEmpty(requiredFields)) {
      subMessage += "Required Fields: " + requiredFields.join(", ");
    }

    var invalidFields: any = [];

    if (!REGEX.SEED_RATE.test(addVarietyData.seedRate)) {
      invalidFields.push(MESSAGE.SEED_RATE_LIMIT_SUB);
    }

    if (
      companyName &&
      translatedCompanyName.length > TEXT_LENGTH.COMPANY_NAME
    ) {
      invalidFields.push(
        MESSAGE.TEXT_LENGTH(LABEL.COMPANY_NAME, TEXT_LENGTH.COMPANY_NAME)
      );
    }

    if (
      varietyName &&
      translatedVarietyName.length > TEXT_LENGTH.VARIETY_NAME
    ) {
      invalidFields.push(
        MESSAGE.TEXT_LENGTH(LABEL.VARIETY_NAME, TEXT_LENGTH.VARIETY_NAME)
      );
    }

    if (
      description &&
      translatedDescription.length > TEXT_LENGTH.VARIETY_DESCRITPION
    ) {
      invalidFields.push(
        MESSAGE.TEXT_LENGTH(LABEL.DESCRIPTION, TEXT_LENGTH.VARIETY_DESCRITPION)
      );
    }

    if (!isEmpty(invalidFields)) {
      subMessage +=
        (!subMessage.trim() ? "" : "\n") +
        "Invalid Fields: " +
        invalidFields.join(", \n");
    }

    if (!isEmpty(subMessage)) {
      showModal({
        isOpen: true,
        message: MESSAGE.REQUIRE_MESSAGE(variety),
        subMessage: subMessage,
        okButtonText: LABEL.TRY_AGAIN,
      });

      return;
    }
    id
      ? putVariety({ ...addVarietyData, id, status, version })
      : addVariety({ ...addVarietyData });
  };

  return (
    <AppContainer
      parentKey={ROUTENAME.CROP_MANAGEMENT}
      childKey={ROUTENAME.CROP_MANAGEMENT_VARIETY_ADD}
    >
      <span>
        <Link
          color="red"
          to={ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY}
          className="mb-3 icon-link"
        >
          <KatIcon name="arrow_back" size="small" className="me-1" />
          Back
        </Link>
      </span>

      <KatBox variant="white-shadow">
        <KatRow>
          <KatColumn md={12} className="mb-4">
            <h2>
              {editVariety?.id ? LABEL.EDIT_VARIETY : LABEL.ADD_NEW_VARIETY}
            </h2>
          </KatColumn>

          <KatColumn md={3} className="mb-3">
            <FormSelect
              label={LABEL.LANGUAGE}
              value={addNewVariety.languageCode}
              placeholder={LABEL.SELECT_LANGUAGE}
              options={LANGUAGES}
              onChange={(val) => onLanguageChange(val)}
            />
          </KatColumn>

          <KatColumn md={12} />
          <KatColumn md={3} className="mb-2">
            <FormSelect
              label={LABEL.CROP_NAME}
              placeholder={LABEL.SELECT_CROP}
              options={map(
                filter(
                  cropList,
                  (item) => item.languageCode === addNewVariety.languageCode
                ),
                ({ cropName, id }) => ({
                  name: cropName,
                  value: id,
                })
              )}
              value={addNewVariety.cropId}
              disabled={isEmpty(addNewVariety.languageCode)}
              onChange={(val) => storeChangeData({ cropId: val })}
            />
          </KatColumn>

          <KatColumn md={3} className="mb-2">
            <FormInput
              type="text"
              onInput={() => setTranslating(true)}
              onChange={(event: any) => {
                getTranslated({
                  ...addNewVariety,
                  varietyName: _trim(event.target.value),
                  fieldName: "varietyName",
                });
              }}
              label={LABEL.VARIETY_NAME}
              value={addNewVariety.varietyName}
              placeholder={LABEL.ENTER_VARIETY}
              disabled={isEmpty(addNewVariety.languageCode)}
              state={
                addNewVariety.translatedVarietyName.length >
                  TEXT_LENGTH.VARIETY_NAME && "error"
              }
              stateLabel={
                addNewVariety.translatedVarietyName.length >
                  TEXT_LENGTH.VARIETY_NAME &&
                MESSAGE.TEXT_LENGTH(
                  LABEL.VARIETY_NAME,
                  TEXT_LENGTH.VARIETY_NAME
                )
              }
            />
          </KatColumn>

          <KatColumn md={3} className="mb-2">
            <FormInput
              type="text"
              value={addNewVariety.companyName}
              onInput={() => setTranslating(true)}
              onChange={(event: any) => {
                getTranslated({
                  ...addNewVariety,
                  companyName: _trim(event.target.value),
                  fieldName: "companyName",
                });
              }}
              label={LABEL.COMPANY_NAME}
              placeholder={LABEL.ENTER_COMPANY}
              disabled={isEmpty(addNewVariety.languageCode)}
              state={
                addNewVariety.translatedCompanyName.length >
                  TEXT_LENGTH.COMPANY_NAME && "error"
              }
              stateLabel={
                addNewVariety.translatedCompanyName.length >
                  TEXT_LENGTH.COMPANY_NAME &&
                MESSAGE.TEXT_LENGTH(
                  LABEL.COMPANY_NAME,
                  TEXT_LENGTH.COMPANY_NAME
                )
              }
            />
          </KatColumn>

          <KatColumn md={3}>
            <FormInput
              label={LABEL.MEDIA_LINK}
              value=""
              placeholder={LABEL.ENTER_MEDIA_LINK}
              disabled
            ></FormInput>
          </KatColumn>

          <KatColumn md={3} className="mb-4">
            <FormInput
              type="number"
              label={LABEL.SEED_RATE}
              value={addNewVariety.seedRate}
              placeholder={LABEL.TYPE_HERE}
              disabled={isEmpty(addNewVariety.languageCode)}
              onInput={(e: any) =>
                storeChangeData({ seedRate: e.target.value })
              }
              state={
                addNewVariety.seedRate &&
                !REGEX.SEED_RATE.test(addNewVariety.seedRate) &&
                "error"
              }
              stateLabel={
                addNewVariety.seedRate &&
                !REGEX.SEED_RATE.test(addNewVariety.seedRate) &&
                MESSAGE.SEED_RATE_LIMIT_SUB
              }
            ></FormInput>
          </KatColumn>

          <KatColumn md={3} className="mb-4">
            <MultiSelect
              label={LABEL.SEASON}
              value={addNewVariety?.seasons || []}
              placeholder={LABEL.SELECT_SEASON}
              disabled={isEmpty(addNewVariety.languageCode)}
              onChange={(val) => storeChangeData({ seasons: val })}
              options={
                varietyMetaData.seasons
                  ? varietyMetaData.seasons.map((item: string) => {
                      return { name: item, value: item };
                    })
                  : []
              }
            />
          </KatColumn>

          <KatColumn md={3} className="mb-4">
            <FormSelect
              label={LABEL.VARIETY_TYPE}
              value={addNewVariety.varietyType}
              placeholder={LABEL.SELECT_VARIETY_TYPE}
              disabled={isEmpty(addNewVariety.languageCode)}
              options={VARIETY_TYPE_OPTIONS}
              onChange={(val) => storeChangeData({ varietyType: val })}
            />
          </KatColumn>

          <KatColumn md={3} className="mb-4">
            <MultiSelect
              label={LABEL.MONTH}
              value={addNewVariety?.months || []}
              placeholder={LABEL.SELECT_MONTH}
              disabled={isEmpty(addNewVariety.languageCode)}
              onChange={(val) => storeChangeData({ months: val })}
              options={
                varietyMetaData.months
                  ? varietyMetaData.months.map((item: string) => {
                      return { name: item, value: item };
                    })
                  : []
              }
            />
          </KatColumn>

          <KatColumn md={3} className="mb-4">
            <MultiSelect
              label={LABEL.AGROCLIMATIC_ZONES}
              value={addNewVariety?.agroClimaticZones || []}
              placeholder={LABEL.SELECT_AGROCLIMATIC_ZONES}
              disabled={isEmpty(addNewVariety.languageCode)}
              onChange={(val) => onAgroclimaticZonesChange(val)}
              options={values(
                pickBy(
                  varietyMetaData?.climaticZones?.map((item: any) => {
                    return isEmpty(item?.subClimaticZones)
                      ? null
                      : {
                          name: item.climaticZoneName,
                          value: item.climaticZoneName,
                        };
                  }),
                  identity
                )
              )}
            />
          </KatColumn>

          <KatColumn md={3} className="mb-4">
            <MultiSelect
              label={LABEL.SUB_AGROCLIMATIC_ZONES}
              value={addNewVariety?.subAgroClimaticZones || []}
              placeholder={LABEL.SELECT}
              options={map(
                values(
                  pickBy(
                    uniq(
                      flatten(
                        map(
                          addNewVariety.agroClimaticZones,
                          (item) =>
                            find(varietyMetaData?.climaticZones, {
                              climaticZoneName: item,
                            })?.subClimaticZones
                        )
                      )
                    ),
                    identity
                  )
                ),
                (i) => ({ name: i, value: i })
              )}
              disabled={isEmpty(addNewVariety.agroClimaticZones)}
              onChange={(val) => storeChangeData({ subAgroClimaticZones: val })}
            />
          </KatColumn>

          <KatColumn md={3} className="mb-4">
            <MultiSelect
              label={LABEL.SOIL_TYPE}
              value={[]}
              placeholder={LABEL.SELECT_SOIL_TYPE}
              options={LANGUAGES}
              disabled
              onChange={(val) => storeChangeData({ soilType: val })}
            />
          </KatColumn>

          <KatColumn md={12} className="mb-3">
            <KatTextarea
              onInput={() => setTranslating(true)}
              onChange={(event: any) => {
                getTranslated({
                  ...addNewVariety,
                  description: _trim((event.target as HTMLInputElement).value),
                  fieldName: "description",
                });
              }}
              className="form-input w-100"
              label={LABEL.DESCRIPTION}
              disabled={isEmpty(addNewVariety.languageCode)}
              value={addNewVariety.description}
              placeholder={LABEL.ENTER_DESCRIPTION}
              state={
                addNewVariety.translatedDescription.length >
                TEXT_LENGTH.VARIETY_DESCRITPION
                  ? "error"
                  : undefined
              }
              stateLabel={
                addNewVariety.translatedDescription.length >
                TEXT_LENGTH.VARIETY_DESCRITPION
                  ? MESSAGE.TEXT_LENGTH(
                      LABEL.DESCRIPTION,
                      TEXT_LENGTH.VARIETY_DESCRITPION
                    )
                  : undefined
              }
            />
          </KatColumn>
        </KatRow>
      </KatBox>
      <article className="text-right">
        <KatButton
          disabled={translating}
          onClick={() => requestForAddNewVariety()}
          label={editVariety?.id ? LABEL.EDIT_VARIETY : LABEL.ADD_NEW_VARIETY}
          variant="primary"
          className="mt-3 w-25"
        ></KatButton>
      </article>
    </AppContainer>
  );
};

interface stateType {
  crop: {
    cropList: [
      {
        id: string;
        version: string;
        cropName: string;
        CropStatus: string;
        languageCode: string;
      }
    ];
  };
  variety: {
    editVariety: any;
    varietyMetaData: any;
  };
}

export const mapStateToProps = (state: stateType) => ({
  cropList: state.crop.cropList,
  editVariety: state.variety.editVariety || {},
  varietyMetaData: state.variety.varietyMetaData,
});

export const mapDispatchToProps = (dispatch: any) => ({
  addVariety: (data: any) => {
    dispatch(varietyActions.addVariety(data));
  },
  fetchCrops: () => {
    dispatch(cropActions.fetchCrops());
  },
  fetchVarietyMetaData: (data: any) => {
    dispatch(varietyActions.fetchVarietyMetaData(data));
  },
  putVariety: (data: any) => {
    dispatch(varietyActions.putVariety(data));
  },
  showModal: (data: any) => {
    dispatch(modalActions.open(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AddNewVariety);
