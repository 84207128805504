import { AnyAction } from "redux";
import * as cropActions from "../actions/cropActions";

const initialState = {
  cropList: [],
  editCrop: {},
};

const cropReducer = (state = initialState, action: AnyAction) => {
  const type = action.type;
  const payload = action.payload;

  switch (type) {
    case cropActions.STORE_CROP_LIST:
      return {
        ...state,
        cropList: payload,
      };
    case cropActions.EDIT_CROP:
      return {
        ...state,
        editCrop: payload,
      };
    default:
      return state;
  }
};

export default cropReducer;
