import { LABEL } from "src/constants/commonConstants";

export const endChatModal = ({
  showModal,
  endQueryChat,
  queryDetails,
}: any) => {
  return showModal({
    isOpen: true,
    message: LABEL.END_CHAT_POPUP_TITLE,
    subMessage: LABEL.END_CHAT_POPUP_TITLE,
    okButtonText: LABEL.END_CHAT,
    cancelButtonText: LABEL.CANCEL,
    handleConfirmationOk: () =>
      endQueryChat({
        farmerId: queryDetails.farmerId,
        farmerCropId: queryDetails.farmerCropId,
      }),
  });
};
