import {
  KatRow,
  KatColumn,
  KatDivider,
  KatFileUpload,
} from "@amzn/katal-react";
import Papa from "papaparse";
import _uniq from "lodash/uniq";
import _reduce from "lodash/reduce";
import _uniqBy from "lodash/uniqBy";
import _values from "lodash/values";
import { CSVLink } from "react-csv";
import _isEmpty from "lodash/isEmpty";
import { fileUploadHandler, prepareCSVData } from "src/helper";
import { CropPlanUploadProps } from "src/types";
import { Popup } from "src/components/common/Popup";
import { ShadowBox } from "src/components/common/ShadowBox";
import React, { Fragment, useEffect, useState } from "react";
import FormSelect from "src/components/FormComponents/FormSelect";
import {
  LABEL,
  MESSAGE,
  LANGUAGES,
  CSV_HEADER,
  SOWING_TYPE,
  CSV_FILE_NAME,
  LANGUAGES_CODE,
  CROP_PLAN_FILE_NAME,
  REQUIRE_CSV_HEADER,
} from "src/constants/commonConstants";
import BlueButton from "src/components/common/BlueButton";
import { identity, join, map, mapValues, pickBy, values } from "lodash";

export const CropPlanUpload = ({
  isOpen,
  cropList,
  showModal,
  getPreSignedURL,
  varietyMetadata,
  fetchPlanVarietyMetaData,
}: CropPlanUploadProps) => {
  const [fileName, setFileName] = useState("");
  const [extractData, setExtractData] = useState("");
  const [uploadFilePopup, setUploadFilePopup] = useState(false);
  const [selectedMetaData, setSelectedMetaData] = useState({
    cropId: "",
    varietyId: "",
    cropName: "",
    language: "",
    sowingType: "",
    varietyName: "",
    companyName: "",
    languageCode: "",
  });

  useEffect(() => {
    isOpen ? setUploadFilePopup(false) : "";
  }, [isOpen]);

  const storeChangeData = (data: any) => {
    if (data.cropId) {
      const cropNameDetails = cropList
        ? cropList.find((item: any) => item.id == data.cropId)
        : {};
      data = cropNameDetails
        ? {
            ...data,
            cropName: cropNameDetails.cropName,
          }
        : data;
    }

    if (data.varietyId) {
      const varietyNameDetails = varietyMetadata.varietyNameList
        ? varietyMetadata.varietyNameList.find(
            (item: any) => item.value == data.varietyId
          )
        : {};

      data = varietyNameDetails
        ? {
            ...data,
            varietyName: varietyNameDetails.name,
          }
        : data;
    }
    if (data.languageCode) {
      setSelectedMetaData((prevState: any) => ({
        ...mapValues(prevState, () => ""),
        ...data,
      }));
      generateFileName({ ...selectedMetaData, ...data });
      return;
    }
    if (data.cropId) {
      setSelectedMetaData((prevState: any) => ({
        ...prevState,
        ...data,
        varietyId: "",
        varietyName: "",
        companyName: "",
      }));
    } else {
      setSelectedMetaData((prevState: any) => ({
        ...prevState,
        ...data,
      }));
    }

    generateFileName({ ...selectedMetaData, ...data });
  };

  const [metaData, setMetaData] = React.useState({
    cropNameList: [],
  }) as any;

  const generateFileName = (data: any) => {
    let name = `${join(
      values(
        pickBy(
          map(
            [
              "language",
              "cropName",
              "varietyName",
              "companyName",
              "sowingType",
            ],
            (item) => data[item]
          ),
          identity
        )
      ),
      "_"
    )}.csv`;

    setFileName(name);
  };

  const updateCropNameList = (languageCode: string) => {
    let cropNameList: any[] = [];
    cropList.map((item: any) => {
      if (item.languageCode == languageCode) {
        cropNameList.push({ name: item.cropName, value: item.id });
      }
    });

    setMetaData((prevState: any) => ({
      ...prevState,
      cropNameList: _uniqBy(cropNameList, "name"),
    }));
  };

  const handlerUploadButton = () => {
    const { cropName, varietyName, companyName, sowingType, language } =
      selectedMetaData;

    if (!cropName || !varietyName || !companyName || !sowingType || !language) {
      showModal({
        isOpen: true,
        message: MESSAGE.REQUIRE_FILE_FILEDS,
        subMessage: MESSAGE.REQUIRE_FILE_FILEDS_SUB_MESSAGE,
        okButtonText: LABEL.TRY_AGAIN,
      });
    } else {
      setUploadFilePopup(true);
    }
  };

  return (
    <Fragment>
      <Popup
        title={LABEL.UPLOAD_FILE}
        subtitle={
          <article className="file-name-blue-box w-fit m-auto">
            {fileName}
          </article>
        }
        content={
          uploadFilePopup && (
            <article className="mb-4">
              <KatFileUpload
                accept=".csv,text/csv"
                fileView="list"
                variant="large"
                onFilesReplaced={(event) =>
                  fileUploadHandler(
                    event.detail.newFiles[0],
                    showModal,
                    setUploadFilePopup,
                    setExtractData,
                    selectedMetaData,
                    fileName
                  )
                }
                onFilesAttached={(event) => {
                  fileUploadHandler(
                    event.detail.files[0],
                    showModal,
                    setUploadFilePopup,
                    setExtractData,
                    selectedMetaData,
                    fileName
                  );
                }}
              ></KatFileUpload>
            </article>
          )
        }
        blueLabel={LABEL.SAVE}
        outlineLabel={LABEL.CANCEL}
        trigger={uploadFilePopup}
        handler={setUploadFilePopup}
        blueHandler={() => {
          if (_isEmpty(extractData)) {
            showModal({
              isOpen: true,
              message: MESSAGE.REQUIRE_CSV_FILE,
              subMessage: MESSAGE.REQUIRE_CSV_FILE_SUB_MESSAGE,
              okButtonText: LABEL.TRY_AGAIN,
            });
          } else {
            getPreSignedURL(extractData);
          }
        }}
      />

      <ShadowBox>
        <KatRow className="align-items-center justify-content-between">
          <KatColumn xs={12}>
            <h3>{LABEL.FILE_NAME}</h3>
            <p>
              <span className="text-secondary">
                Select the appropriate dropdown to create the file name
              </span>
            </p>
          </KatColumn>

          <KatColumn md={2}>
            <FormSelect
              label={LABEL.LANGUAGE}
              value={selectedMetaData.languageCode}
              placeholder={LABEL.SELECT_LANGUAGE}
              options={LANGUAGES}
              onChange={(val) => {
                storeChangeData({ languageCode: val });
                storeChangeData({
                  language: LANGUAGES_CODE[val as keyof typeof LANGUAGES_CODE],
                });
                updateCropNameList(val);
              }}
            />
          </KatColumn>

          <KatColumn md={2}>
            <FormSelect
              label={LABEL.CROP_NAME}
              value={selectedMetaData.cropId}
              placeholder={LABEL.SELECT_CROP}
              options={metaData.cropNameList}
              disabled={_isEmpty(selectedMetaData.language)}
              onChange={(val) => {
                storeChangeData({ cropId: val });
                fetchPlanVarietyMetaData({
                  cropId: val,
                  languageCode: selectedMetaData.languageCode,
                });
              }}
            />
          </KatColumn>

          <KatColumn md={2}>
            <FormSelect
              value={selectedMetaData.varietyId}
              label={LABEL.VARIETY_NAME}
              placeholder={LABEL.SELECT_VARIETY}
              options={varietyMetadata.varietyNameList}
              disabled={
                _isEmpty(selectedMetaData.language) ||
                _isEmpty(selectedMetaData.cropId)
              }
              onChange={(val) => storeChangeData({ varietyId: val })}
            />
          </KatColumn>

          <KatColumn md={3}>
            <FormSelect
              label={LABEL.COMPANY_NAME}
              placeholder={LABEL.SELECT_COMPANY}
              value={selectedMetaData.companyName}
              options={varietyMetadata.companyNameList}
              disabled={
                _isEmpty(selectedMetaData.language) ||
                _isEmpty(selectedMetaData.cropId)
              }
              onChange={(val) => storeChangeData({ companyName: val })}
            />
          </KatColumn>

          <KatColumn md={3}>
            <FormSelect
              label={LABEL.SOWING_TYPE}
              value={selectedMetaData.sowingType}
              placeholder={LABEL.SELECT_SOWING_TYPE}
              disabled={_isEmpty(selectedMetaData.language)}
              options={
                SOWING_TYPE[
                  selectedMetaData.languageCode as keyof typeof SOWING_TYPE
                ]
              }
              onChange={(val) => storeChangeData({ sowingType: val })}
            />
          </KatColumn>

          <KatColumn xs={12} className="mt-1">
            <p className="text-secondary">
              {!fileName ? CROP_PLAN_FILE_NAME : fileName}
            </p>
            <KatDivider variant="athens" />
          </KatColumn>

          <KatColumn xs={6} className="mt-1">
            <h1>{LABEL.UPLOAD_CROP_PLAN}</h1>

            <p className="text-secondary">upload file in .csv</p>

            <p className="text-secondary mt-3">
              {LABEL.SAMPLE_CROP_PLAN_TEMPLATE}
              <CSVLink
                filename={CSV_FILE_NAME}
                data={[CSV_HEADER]}
                className="color-amazon"
              >
                {" "}
                {LABEL.DOWNLOAD_HERE}
              </CSVLink>
            </p>
          </KatColumn>

          <KatColumn xs={4} className="text-right">
            <BlueButton
              label={LABEL.UPLOAD_FILE}
              className="uploadFile mt-3 w-50"
              onClick={handlerUploadButton}
            />
          </KatColumn>
        </KatRow>
      </ShadowBox>
    </Fragment>
  );
};

export default CropPlanUpload;
