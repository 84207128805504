import { isEmpty, startsWith } from "lodash";
import React from "react";
import { Popup } from "src/components/common/Popup";
import FormInput from "src/components/FormComponents/FormInput";
import { LABEL, MESSAGE, REGEX } from "src/constants/commonConstants";

function AttachmentPopup({
  attachmentPopup,
  setAttachmentPopup,
  attachement,
  mediaError,
  sendQueryChat,
  queryDetails,
  setMediaError,
  setLoading,
  handlerMedia,
  setAttachement,
  s3Media,
  loading,
}: any) {
  return (
    <Popup
      title={LABEL.SEND_MEDIA}
      subtitle={""}
      blueLabel={LABEL.UPLOAD}
      outlineLabel={LABEL.CANCEL}
      trigger={attachmentPopup}
      handler={setAttachmentPopup}
      blueHandler={() => {
        if (REGEX.MEDIA_LINK.test(attachement)) {
          if (!mediaError) {
            sendQueryChat({
              hasMedia: "true",
              mediaUrl: attachement,
              queryId: queryDetails.id,
              farmerId: queryDetails.farmerId,
              farmerCropId: queryDetails.farmerCropId,
            });
            setAttachmentPopup(false);
          }
        } else {
          setMediaError(MESSAGE.SUB_MESSAGE_INVALID_LINK);
        }
      }}
      content={
        <main>
          <FormInput
            onInput={(e: any) => {
              if (startsWith(e.target.value, "s3://")) {
                setLoading(true);
                handlerMedia(e.target.value);
              } else if (startsWith(e.target.value, "https://")) {
                setAttachement(e.target.value);
              }
              REGEX.MEDIA_LINK.test(e.target.value) && setMediaError("");

              if (isEmpty(e.target.value)) {
                setAttachement("");
                setMediaError(MESSAGE.SUB_MESSAGE_INVALID_LINK);
              }
            }}
            label={"Media Link"}
            value={attachement}
            placeholder={"Enter Media Link"}
            className="mb-3 enterMediaLink"
            state={mediaError ? "error" : ""}
            stateLabel={mediaError}
          />

          {startsWith(attachement, "https://") ? (
            <img src={attachement} className="attachment-img" />
          ) : (
            attachement &&
            !isEmpty(s3Media) &&
            !s3Media.includes(MESSAGE.FILE_DOES_NOT_EXISTS_IN_S3) &&
            !loading && <img src={s3Media} className="attachment-img" />
          )}
        </main>
      }
    />
  );
}

export default AttachmentPopup;
