import React from "react";
import { LABEL } from "src/constants/commonConstants";
import BlueButton from "../BlueButton";
import OutlinedButton from "../OutlinedButton";

interface Props {
  title?: string;
  subtitle?: any;
  content?: any;
  trigger?: boolean;
  outlineLabel?: string;
  blueLabel?: string;
  disableOverlayClickClose?: boolean;
  handler?: (e: any) => void;
  blueHandler?: () => void;
  outlineHandler?: () => void;
  blueProps?: any;
}

export const Popup = ({
  title,
  subtitle,
  content,
  trigger,
  outlineLabel,
  blueLabel,
  disableOverlayClickClose,
  handler,
  blueHandler,
  outlineHandler,
  blueProps,
}: Props) => {
  return (
    <section className={`popup ${!trigger && "hide-popup"}`}>
      <aside
        className="popup-overlay"
        onClick={() => !disableOverlayClickClose && handler?.(false)}
      />
      <article className="popup-dialog">
        <h1 className="popup-dialog-title">{title}</h1>
        <p className="text-secondary text-center">{subtitle}</p>
        {content}
        <footer className="popup-dialog-footer">
          <OutlinedButton
            className="cancel-btn"
            label={outlineLabel || LABEL.CANCEL}
            onClick={() =>
              outlineHandler ? outlineHandler?.() : handler?.(false)
            }
          />
          <BlueButton
            className="save-btn"
            label={blueLabel || LABEL.DELETE}
            onClick={() => (blueHandler ? blueHandler?.() : handler?.(false))}
            {...blueProps}
          />
        </footer>
      </article>
    </section>
  );
};
