import React from "react";
import { mapValues, values } from "lodash";
import { KatRow, KatColumn, KatLabel } from "@amzn/katal-react";
import FormSelect from "src/components/FormComponents/FormSelect";
import OutlinedButton from "src/components/common/OutlinedButton";
import { LANGUAGES, STATUS, LABEL } from "src/constants/commonConstants";

const CropFilter = ({
  handlerFilter,
  setfilterCriteria,
  filterCriteria,
}: any) => {
  console.log(filterCriteria);
  const onChangeHandler = (data: any) => {
    setfilterCriteria(() => ({
      ...filterCriteria,
      ...data,
    }));
  };
  return (
    <KatRow className="justify-content-between mb-4">
      <KatColumn sm={6}>
        <KatRow noGutters>
          <KatColumn sm={6} className="pe-3">
            <FormSelect
              className={"languageSelection"}
              label={LABEL.LANGUAGE}
              value={filterCriteria?.languageCode}
              placeholder={LABEL.SELECT_LANGUAGE}
              options={LANGUAGES}
              onChange={(val) => onChangeHandler({ languageCode: val })}
            />
          </KatColumn>
          <KatColumn sm={6} className="ps-3">
            <FormSelect
              value={filterCriteria?.status}
              label={LABEL.STATUS}
              placeholder={LABEL.SELECT_STATUS}
              options={STATUS}
              className={"statusSelection"}
              onChange={(val) => onChangeHandler({ status: val })}
            />
          </KatColumn>
        </KatRow>
      </KatColumn>

      <KatColumn sm={2}>
        <KatLabel className="mb-3 text-transparent">label</KatLabel>
        <OutlinedButton
          label="Filter"
          className="mt-1 filterButton"
          onClick={() => handlerFilter()}
        />
      </KatColumn>
    </KatRow>
  );
};

export default CropFilter;
