import { connect } from "react-redux";
import React, { useEffect } from "react";
import _includes from "lodash/includes";
import AppContainer from "../AppContainer";
import CropPlanTable from "./CropPlanTable";
import { cropPlanFilter } from "src/helper";
import CropPlanUpload from "./CropPlanUpload";
import * as cropActions from "src/actions/cropActions";
import { ROUTENAME } from "src/constants/routeConstants";
import * as modalActions from "src/actions/modalActions";
import * as cropPlanActions from "src/actions/cropPlanActions";

interface CropPlanUploadProps {
  cropList: any;
  cropMetadata: any;
  varietyMetadata: any;
  fetchCrops: () => void;
  fetchCropPlanData: () => void;
  fetchCropMetaData: () => void;
  getPreSignedURL: (data: any) => void;
  fetchPlanVarietyMetaData: (data: any) => void;
}

interface CropPlanUploadProps {
  cropPlan: any;
  cropList: any;
  isOpen: boolean;
  cropMetadata: any;
  varietyMetadata: any;
  fetchCrops: () => void;
  fetchCropMetaData: () => void;
  showModal: (data: any) => void;
  getPreSignedURL: (data: any) => void;
  fetchPlanVarietyMetaData: (data: any) => void;
}

export const CropPlan = ({
  isOpen,
  cropPlan,
  cropList,
  showModal,
  fetchCrops,
  getPreSignedURL,
  cropMetadata,
  varietyMetadata,
  fetchCropMetaData,
  fetchCropPlanData,
  fetchPlanVarietyMetaData,
}: CropPlanUploadProps) => {
  const [filterData, setFilterData] = React.useState(cropPlan);
  const [selectedMetaData, setSelectedMetaData] = React.useState({
    cropId: "",
    cropName: "",
    language: "",
    sowingType: "",
    varietyName: "",
    companyName: "",
    languageCode: "",
  });

  useEffect(() => {
    console.log("cropPlan", cropPlan);
    setFilterData(cropPlan);
  }, [cropPlan]);

  useEffect(() => {
    fetchCrops();
    fetchCropPlanData();
  }, []);

  const handleFilter = () => {
    const filterCropPlanList = cropPlanFilter({ selectedMetaData, cropPlan });
    setFilterData(filterCropPlanList);
  };

  return (
    <AppContainer parentKey={ROUTENAME.CROP_PLAN_MANAGEMENT}>
      <CropPlanUpload
        isOpen={isOpen}
        cropList={cropList}
        showModal={showModal}
        getPreSignedURL={getPreSignedURL}
        fetchPlanVarietyMetaData={fetchPlanVarietyMetaData}
        cropMetadata={cropMetadata}
        varietyMetadata={varietyMetadata}
      />
      <br />
      <CropPlanTable
        cropList={cropList}
        cropPlan={filterData}
        handleFilter={handleFilter}
        cropMetadata={cropMetadata}
        varietyMetadata={varietyMetadata}
        selectedMetaData={selectedMetaData}
        setSelectedMetaData={setSelectedMetaData}
        fetchPlanVarietyMetaData={fetchPlanVarietyMetaData}
      />
    </AppContainer>
  );
};

export const mapStateToProps = (state: any) => ({
  cropList: state.crop.cropList,
  isOpen: state.modal.isOpen,
  cropPlan: state.cropPlan.cropPlan,
  cropMetadata: state.cropPlan.cropMetadata,
  varietyMetadata: state.cropPlan.varietyMetadata,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCropPlanData: () => {
    dispatch(cropPlanActions.fetchCropPlanData());
  },
  fetchCropMetaData: () => {
    dispatch(cropPlanActions.fetchCropMetaData());
  },
  fetchPlanVarietyMetaData: (data: any) => {
    dispatch(cropPlanActions.fetchPlanVarietyMetaData(data));
  },
  fetchCrops: () => {
    dispatch(cropActions.fetchCrops());
  },
  getPreSignedURL: (data: any) => {
    dispatch(cropPlanActions.getPreSignedURL(data));
  },
  showModal: (data: any) => {
    dispatch(modalActions.open(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CropPlan);
