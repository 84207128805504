import { KatButton } from "@amzn/katal-react";
import React from "react";

interface Props {
  label: string;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const BlueButton = ({ label, className, onClick, disabled }: Props) => {
  return (
    <KatButton
      {...{ label, onClick, disabled }}
      variant="primary"
      className={`blue-button w-100 ${className}`}
    />
  );
};

export default BlueButton;
