import { KatIcon } from "@amzn/katal-react";
import { find, isEmpty, map } from "lodash";
import { Children, useRef, useState } from "react";
import React from "react";

type option = {
  name: string;
  value: string;
};

interface TitleProps {
  label?: string;
  placeholder: string;
  options?: option[];
  value?: string;
  className?: string;
  setValue?: Function;
  disabled?: boolean;
  onChange?: (e: any) => void;
}

const FormSelect = ({
  label,
  options,
  placeholder,
  value,
  disabled,
  className,
  onChange,
}: TitleProps) => {
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLHeadingElement>(null);
  return (
    <div
      className={`select`}
      onMouseLeave={() => setOpen(false)}
      ref={selectRef}
    >
      <label htmlFor="cars" className="select-label">
        <span>{label}</span>
      </label>
      <div className="select-container">
        <article
          className={`select-input ${open && "open"} ${className} ${
            disabled && "disabled"
          }`}
          onClick={() => setOpen(!open)}
        >
          {!isEmpty(value) ? (
            find(options, { value })?.name
          ) : (
            <span className="text-placeholder text-italic">{placeholder}</span>
          )}
          <KatIcon
            name="keyboard_arrow_down"
            size="small"
            className="select-input-icon text-placeholder text-italic"
          ></KatIcon>
        </article>

        {open && (
          <section
            className={`select-options 
            ${
              Number(selectRef.current?.getBoundingClientRect()?.top) >
                window.innerHeight - 200 && "top"
            }
            `}
          >
            {Children.toArray(
              map(options, ({ name, value: optionValue }) => {
                return (
                  <main
                    className={`select-options-option ${
                      value === optionValue && "selected"
                    }`}
                    onClick={() => {
                      onChange?.(optionValue);
                      setOpen(false);
                    }}
                  >
                    {name}
                  </main>
                );
              })
            )}
          </section>
        )}
      </div>
    </div>
  );
};

export default FormSelect;
