import { connect } from "react-redux";
import React, { useEffect } from "react";
import Error from "src/components/common/error";
import * as authActions from "src/actions/authActions";
import { hasPermission } from "src/utilis/PermissionList";

interface ProtectedRouteProps {
  outlet: JSX.Element;
  path: string;
  userId: string;
  role: string[];
  ensureAuthenticated: () => void;
}

interface stateType {
  auth: {
    userId: string;
    role: string[];
  };
}

export const ProtectedRoute = ({
  outlet,
  path,
  userId,
  role,
  ensureAuthenticated,
}: ProtectedRouteProps) => {
  useEffect(() => {
    ensureAuthenticated();
  }, [path]);

  return userId ? hasPermission({ role, path }) ? outlet : <Error /> : null;
};

export const mapStateToProps = (state: stateType) => ({
  userId: state.auth.userId,
  role: state.auth.role,
});

export const mapDispatchToProps = (dispatch: any) => ({
  ensureAuthenticated: () => {
    dispatch(authActions.ensureAuthenticated());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ProtectedRoute);
