import { of } from "rxjs";
import { concatMap, mergeMap } from "rxjs/operators";
import * as authHandler from "../utilis/AuthHandler";
import { CognitoAuth } from "amazon-cognito-auth-js";
import * as authActions from "../actions/authActions";
import { ofType, combineEpics } from "redux-observable";

export const ensureAuthenticatedEpic = (
  action$: any,
  state$: any,
  { authorizationService }: any
) => {
  return action$.pipe(
    ofType(authActions.ENSURE_AUTHENTICATED),
    mergeMap(() => authorizationService.ensureAuthenticated()),
    concatMap((auth: CognitoAuth) => {
      const role = authHandler.extractRoles(auth);
      const userId = authHandler.extractAlias(auth);
      const fullName = authHandler.extractUserName(auth);

      let actions = [];
      actions.push(authActions.storeUserDetails({ role, userId, fullName }));

      return of(...actions);
    })
  );
};

export default combineEpics(ensureAuthenticatedEpic);
