export default {
  FETCH_CROP_LIST: `/v1/management/crops`,
  ADD_CROP: `/v1/management/crops`,
  EDIT_CROP: `/v1/management/crops`,
  DELETE_CROP: `/v1/management/crops`,

  FETCH_VARIETY_LIST: `/v1/management/crop-variety`,
  FETCH_VARIETY_METADATA: `/v1/management/variety-meta-data`,
  ADD_VARIETY: `/v1/management/crop-variety`,
  EDIT_VARIETY: `/v1/management/crop-variety`,
  DELETE_VARIETY: `/v1/management/crop-variety`,
  VARIETY_METADATA: `/v1/management/variety-meta-data`,

  FETCH_ACTIVE_QUERY: `/v1/management/query/active-queries`,
  FETCH_INACTIVE_QUERY: `/v1/management/query/new-queries`,
  ASSIGN_QUERY: `/v1/management/query/assign-query`,
  END_CHAT: `/v1/management/query/end-query`,
  READ_QUERY_CHAT: `/v1/management/query/chat`,
  FETCH_QUERY_CROP_PLAN_GROWTH: `/v1/management/crop-plans/base-plan`,
  FETCH_QUERY_CROP_PLAN_GROWTH_ACTIVITIES: `/v1/management/crop-plans/growth-stages`,
  SENT_QUERY_CHAT: `/v1/management/query/chat`,
  GET_QUERY_BY_ID: `/v1/management/queries/chat`,
  RETRIEVE_S3_MEDIA: `/v1/management/crop/get-media`,
  RETRIEVE_S3_MEDIA_LIST: `/v1/management/crop/get-chat-media`,

  FETCH_CROP_PLAN: `/v1/management/crop-plans/base`,
  ADD_CROP_PLAN: `/v1/management/crop-plans`,
  FETCH_CROP_PLAN_METADATA: `/v1/management/crop-plan/metadata`,
  GET_PRESIGNED_URL: `/v1/management/crop-plan/upload`,
  GET_PRESIGNED_URL_DOWNLOAD: `/v1/management/crop-plan/download`,
};
