import {
  KatBox,
  KatRow,
  KatIcon,
  KatColumn,
  KatButton,
} from "@amzn/katal-react";
import { connect } from "react-redux";
import {
  LABEL,
  REGEX,
  MESSAGE,
  LANGUAGES,
  TEXT_LENGTH,
  LANGUAGES_CODE,
} from "src/constants/commonConstants";
import _trim from "lodash/trim";
import _isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AppContainer from "src/components/AppContainer";
import * as cropActions from "src/actions/cropActions";
import * as modalActions from "src/actions/modalActions";
import FormInput from "src/components/FormComponents/FormInput";
import FormSelect from "src/components/FormComponents/FormSelect";
import { ROUTECONSTANTS, ROUTENAME } from "src/constants/routeConstants";
import { validateURL, generateErrorMessageForCrop } from "src/helper/index";

interface AddNewCropProps {
  putCrop: (data: any) => void;
  addCrop: (data: any) => void;
  showModal: (data: any) => void;
  editCrop: {
    id: string;
    version: string;
    cropName: string;
    cropImgUrl: string;
    cropStatus: string;
    languageCode: string;
  };
}

export const AddNewCrop = ({
  putCrop,
  addCrop,
  showModal,
  editCrop,
}: AddNewCropProps) => {
  const [cropData, setCropData] = React.useState({
    language: "",
    cropName: "",
    cropImgUrl: "",
    languageCode: "",
    translatedCropName: "",
  });

  const [translating, setTranslating] = useState(false);
  const requestForAddNewCrop = async () => {
    const { id, version, cropStatus } = editCrop;
    const { languageCode, cropImgUrl, translatedCropName, cropName } = cropData;

    const errorDetails = await generateErrorMessageForCrop({
      languageCode,
      cropImgUrl,
      translatedCropName,
      cropName,
    });
    if (errorDetails.isValid) {
      const { message, subMessage } = errorDetails;
      showModal({
        isOpen: true,
        message,
        subMessage,
        okButtonText: LABEL.TRY_AGAIN,
      });
    } else if (!validateURL(cropImgUrl)) {
      showModal({
        isOpen: true,
        message: MESSAGE.REQUIRE_MESSAGE("crop"),
        subMessage: MESSAGE.SUB_MESSAGE_INVALID_LINK,
        okButtonText: LABEL.TRY_AGAIN,
      });
    } else {
      id
        ? putCrop({
            id,
            cropStatus,
            version,
            cropImgUrl,
            languageCode,
            cropName: translatedCropName || cropName,
          })
        : addCrop({
            cropImgUrl,
            languageCode,
            cropName: translatedCropName || cropName,
          });
    }
  };

  useEffect(() => {
    setCropData((prevState: any) => ({
      ...prevState,
      cropName: editCrop?.cropName || "",
      cropImgUrl: editCrop?.cropImgUrl || "",
      languageCode: editCrop?.languageCode || "",
      translatedCropName: editCrop?.cropName || "",
      language:
        LANGUAGES_CODE[editCrop?.languageCode as keyof typeof LANGUAGES_CODE],
    }));
  }, []);

  const getTranslated = async ({
    cropName,
    languageCode,
  }: {
    cropName: string;
    languageCode: string;
  }) => {
    let translatedCropName = _trim(cropData.translatedCropName);
    if (!_isEmpty(cropName) && languageCode) {
      setTranslating(true);
      translatedCropName = cropName;    // No Translation
      setTranslating(false);
    }

    setCropData((prevState) => ({
      ...prevState,
      languageCode,
      cropName,
      translatedCropName: _trim(translatedCropName),
      language: LANGUAGES_CODE[languageCode as keyof typeof LANGUAGES_CODE],
    }));
  };

  const onLanguageChange = async (languageCode: string) => {
    getTranslated({ ...cropData, languageCode });
  };

  const onChangeHandler = (cropImgUrl: string) => {
    setCropData((prevState) => ({
      ...prevState,
      cropImgUrl,
    }));
  };

  return (
    <AppContainer
      parentKey={ROUTENAME.CROP_MANAGEMENT}
      childKey={ROUTENAME.CROP_MANAGEMENT_CROP_ADD}
    >
      <span>
        <Link
          color="red"
          to={ROUTECONSTANTS.CROP_MANAGEMENT_CROP}
          className="mb-3 icon-link"
        >
          <KatIcon name="arrow_back" size="small" className="me-1" />
          {LABEL.BACK}
        </Link>
      </span>

      <KatBox variant="white-shadow">
        <KatRow>
          <KatColumn md={12} className="mb-4">
            <h2>{editCrop?.id ? LABEL.EDIT_CROP : LABEL.ADD_NEW_CROP}</h2>
          </KatColumn>

          <KatColumn md={3} className="mb-4">
            <FormSelect
              label={LABEL.LANGUAGE}
              value={cropData.languageCode}
              placeholder={LABEL.SELECT_LANGUAGE}
              options={LANGUAGES}
              onChange={(val) => onLanguageChange(val)}
            />
          </KatColumn>

          <KatColumn md={12} />
          <KatColumn md={3}>
            <FormInput
              type="text"
              maxlength={TEXT_LENGTH.CROP_NAME}
              onInput={(e: any) =>
                setTranslating(!(e.target.value == cropData.cropName))
              }
              onChange={(e: any) =>
                getTranslated({
                  languageCode: cropData.languageCode,
                  cropName: _trim(e.target.value),
                })
              }
              label={LABEL.CROP_NAME}
              placeholder="Type crop name"
              value={cropData.cropName}
              state={
                cropData.translatedCropName.length > TEXT_LENGTH.CROP_NAME &&
                "error"
              }
              stateLabel={
                cropData.translatedCropName.length > TEXT_LENGTH.CROP_NAME &&
                MESSAGE.TEXT_LENGTH(LABEL.CROP_NAME, TEXT_LENGTH.CROP_NAME)
              }
              disabled={!cropData.languageCode}
            ></FormInput>
          </KatColumn>

          <KatColumn md={3}>
            <FormInput
              type="text"
              onInput={(e: any) => onChangeHandler(e.target.value)}
              label={LABEL.MEDIA_LINK}
              value={cropData.cropImgUrl}
              placeholder={LABEL.ENTER_MEDIA_LINK}
              state={
                cropData.cropImgUrl &&
                !REGEX.MEDIA_LINK.test(cropData.cropImgUrl)
                  ? "error"
                  : ""
              }
              stateLabel={
                cropData.cropImgUrl &&
                !REGEX.MEDIA_LINK.test(cropData.cropImgUrl) &&
                MESSAGE.SUB_MESSAGE_INVALID_LINK
              }
            ></FormInput>
          </KatColumn>
        </KatRow>
      </KatBox>
      <article className="text-right">
        <KatButton
          onClick={() => requestForAddNewCrop()}
          label={editCrop?.id ? LABEL.EDIT_CROP : LABEL.ADD_NEW_CROP}
          variant="primary"
          className="mt-3 w-25"
          disabled={translating}
        ></KatButton>
      </article>
    </AppContainer>
  );
};

interface stateType {
  crop: {
    editCrop: {
      id: string;
      version: string;
      cropName: string;
      cropImgUrl: string;
      cropStatus: string;
      languageCode: string;
    };
  };
}

export const mapStateToProps = (state: stateType) => ({
  editCrop: state.crop.editCrop,
});

export const mapDispatchToProps = (dispatch: any) => ({
  addCrop: (data: any) => {
    dispatch(cropActions.addCrop(data));
  },
  putCrop: (data: any) => {
    dispatch(cropActions.putCrop(data));
  },
  showModal: (data: any) => {
    dispatch(modalActions.open(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AddNewCrop);
