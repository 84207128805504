import React from "react";
import { CSVLink } from "react-csv";
import _isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import { KatIcon } from "@amzn/katal-react";
import * as modalActions from "src/actions/modalActions";
import CropManagementIcon from "src/images/successIcon.png";
import CropManagementFailureIcon from "src/images/failureIcon.png";
import {
  LABEL,
  CSV_HEADER,
  CSV_FILE_NAME,
} from "src/constants/commonConstants";
import BlueButton from "../BlueButton";
import OutlinedButton from "../OutlinedButton";

interface ConfirmationModalTypes {
  isOpen: boolean;
  message?: string;
  closeIcon?: boolean;
  subMessage?: string;
  detailMessage?: {
    name: string;
    type: string;
  };
  okButtonText?: string;
  closeModal: () => void;
  cancelButtonText?: string;
  handleConfirmationOk?: (data?: boolean) => void;
  handleConfirmationCancel?: (data?: any) => void;
}

export const ConfirmationModal = ({
  isOpen,
  message,
  closeIcon,
  subMessage,
  closeModal,
  detailMessage,
  cancelButtonText,
  okButtonText = "OK",
  handleConfirmationOk = (f) => f,
  handleConfirmationCancel = (f) => f,
}: ConfirmationModalTypes) =>
  !isOpen ? null : (
    <div className="confirmationModal">
      <div className="confirmationBox">
        {closeIcon && (
          <div className="close-icon">
            <KatIcon size="small" name="close" onClick={closeModal} />
          </div>
        )}
        {message && <div className="confirmationMessage">{message}</div>}
        {detailMessage && detailMessage.type == "cropPlan" && (
          <div className="cropTitle">
            {LABEL.SAMPLE_CROP_PLAN_TEMPLATE}
            <CSVLink
              filename={CSV_FILE_NAME}
              data={[CSV_HEADER]}
              className="color-amazon"
            >
              {" "}
              {LABEL.DOWNLOAD_HERE}
            </CSVLink>
          </div>
        )}

        {detailMessage &&
          detailMessage.type != "cropPlan" &&
          detailMessage?.type != "cropPlanError" && (
            <div className="cropTitle cropSection">
              {detailMessage.type}:{" "}
              <span className="cropName">{detailMessage.name}</span>
            </div>
          )}
        {detailMessage && detailMessage.type == "cropPlanError" && (
          <div className="cropTitle cropSection">
            <span className="cropName">{detailMessage.name}</span>
          </div>
        )}
        <div className="subMessageSection">
          {!_isEmpty(detailMessage) &&
            detailMessage?.type != "cropPlan" &&
            detailMessage?.type != "cropPlanError" && (
              <img className="successIcon" src={CropManagementIcon} />
            )}
          {!_isEmpty(detailMessage) &&
            detailMessage?.type == "cropPlanError" && (
              <img className="successIcon" src={CropManagementFailureIcon} />
            )}
          {subMessage && (
            <div
              className={`confirmationSubMessage ${
                detailMessage?.type == "cropPlanError" ? "failureIcon" : ""
              }`}
            >
              {subMessage}
            </div>
          )}
        </div>

        <div className="confirmationFooter">
          {cancelButtonText && (
            <OutlinedButton
              className="cancelButton"
              label={cancelButtonText}
              onClick={() => {
                handleConfirmationCancel(false);
                closeModal();
              }}
            />
          )}
          <BlueButton
            className={`ok-button ${!cancelButtonText ? "m-auto" : ""} w-50`}
            label={okButtonText}
            onClick={() => {
              handleConfirmationOk(false);
              closeModal();
            }}
          />
        </div>
      </div>
    </div>
  );

type modalType = {
  modal: {
    isOpen: boolean;
    closeIcon: boolean;
    message?: string;
    subMessage?: string;
    okButtonText: string;
    detailMessage?: {
      name: string;
      type: string;
    };
    cancelButtonText?: string;
    handleConfirmationOk?: (data?: boolean) => void;
    handleConfirmationCancel: (data?: any) => void;
  };
};

export const mapStateToProps = (state: modalType) => ({
  isOpen: state.modal.isOpen,
  message: state.modal.message,
  closeIcon: state.modal.closeIcon,
  subMessage: state.modal.subMessage,
  okButtonText: state.modal.okButtonText,
  detailMessage: state.modal.detailMessage,
  cancelButtonText: state.modal.cancelButtonText,
  handleConfirmationOk: state.modal.handleConfirmationOk,
  handleConfirmationCancel: state.modal.handleConfirmationCancel,
});

export const mapDispatchToProps = (dispatch: any) => ({
  closeModal: () => {
    dispatch(modalActions.close());
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ConfirmationModal);
