export const ADD_CROP = "ADD_CROP";
export const PUT_CROP = "PUT_CROP";
export const EDIT_CROP = "EDIT_CROP";
export const DELETE_CROP = "DELETE_CROP";
export const UPDATE_CROP = "UPDATE_CROP";
export const FETCH_CROP_LIST = "FETCH_CROP_LIST";
export const STORE_CROP_LIST = "STORE_CROP_LIST";

export const fetchCrops = () => ({
  type: FETCH_CROP_LIST,
});

export const storeCropList = (object: any) => ({
  type: STORE_CROP_LIST,
  payload: object,
});

export const addCrop = (data: any) => ({
  type: ADD_CROP,
  payload: {
    ...data,
  },
});

export const updateCrop = (data: any) => ({
  type: UPDATE_CROP,
  payload: data,
});

export const editCrop = (data: any) => ({
  type: EDIT_CROP,
  payload: data,
});

export const putCrop = (data: any) => ({
  type: PUT_CROP,
  payload: data,
});

export const deleteCrop = (id: string) => ({
  type: DELETE_CROP,
  payload: id,
});
