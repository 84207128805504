export const ROUTECONSTANTS = {
  ANY: "/*",
  ROOT: "/",
  AUTH: "/auth",
  CROP_MANAGEMENT_CROP: "/crop-management/crop",
  CROP_MANAGEMENT_CROP_ADD: "/crop-management/crop/add",
  CROP_PLAN_MANAGEMENT: "/crop-plan-management",
  CROP_PLAN_MANAGEMENT_PREVIEW: "preview/:planId",
  CROP_MANAGEMENT_VARIETY: "/crop-management/variety",
  CROP_MANAGEMENT_VARIETY_ADD: "/crop-management/variety/add",
  QUERY_MANAGEMENT_NEW_QUERIES: "/query/new-queries",
  QUERY_MANAGEMENT_ACTIVE_QUERIES: "/query/active-queries",
};

export const ROUTENAME = {
  CROP_MANAGEMENT: "crop-management",
  CROP_MANAGEMENT_CROP: "crop",
  CROP_MANAGEMENT_CROP_ADD: "crop/add",
  CROP_PLAN_MANAGEMENT: "crop-plan-management",
  CROP_PLAN_MANAGEMENT_PREVIEW: "preview/:planId",
  CROP_MANAGEMENT_VARIETY: "variety",
  CROP_MANAGEMENT_VARIETY_ADD: "variety/add",
  QUERY_MANAGEMENT: "query",
  QUERY_MANAGEMENT_NEW_QUERIES: "new-queries",
  QUERY_MANAGEMENT_ACTIVE_QUERIES: "active-queries",
};
