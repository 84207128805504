import { of, catchError, from, map } from "rxjs";
import { concatMap, mergeMap, delay } from "rxjs/operators";
import { LABEL } from "src/constants/commonConstants";
import * as queryActions from "../actions/queryActions";
import * as modalActions from "../actions/modalActions";
import { ofType, combineEpics } from "redux-observable";
import { ROUTECONSTANTS } from "src/constants/routeConstants";

export const fetchInactiveQueriesEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.FETCH_INACTIVE_QUERIES),
    mergeMap(() => queryService.fetchInActiveQueryList()),
    concatMap((response: any) => {
      let actions = [];
      actions.push(queryActions.storeInactiveQueries(response.data.queries));
      return of(...actions);
    }),
    catchError((err) => {
      console.log("Error details: " + err);
      let actions = [];
      actions.push(
        modalActions.open({
          message: "Something went wrong",
          okButtonText: LABEL.TRY_AGAIN,
        })
      );
      return of(...actions);
    })
  );
};
export const fetchActiveQueriesEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.FETCH_ACTIVE_QUERIES),
    mergeMap(({ payload }) =>
      queryService.fetchActiveQueryList({
        agronomistId: state$.value.auth.userId,
      })
    ),
    concatMap((response: any) => {
      let actions = [];
      actions.push(queryActions.storeActiveQueries(response.data.queries));
      return of(...actions);
    }),
    catchError((err) => {
      console.log("Error details: " + err);
      let actions = [];
      actions.push(
        modalActions.open({
          message: "Something went wrong",
          okButtonText: LABEL.TRY_AGAIN,
        })
      );
      return of(...actions);
    })
  );
};

export const getQueryByIdEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.GET_QUERY_BY_ID),
    mergeMap(({ payload }) => {
      return from(
        queryService.getQueryById({
          queryId: payload,
        })
      ).pipe(
        concatMap((response: any) => {
          console.log("Get query by id response", response, payload);
          let actions = [];
          actions.push(queryActions.storeChats(response.data.chats));
          if (payload) {
            actions.push(
              queryActions.retrieves3MediaList({ queryId: payload })
            );
          }
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const fetchQueryCropPlanEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.FETCH_QUERY_CROP_PLAN),
    mergeMap(({ payload }) => {
      return from(queryService.fetchQueryCropPlan(payload)).pipe(
        concatMap((response: any) => {
          console.log("Fetch fetchQueryCropPlanEpic list response", response);

          let actions = [];
          actions.push(
            queryActions.storeQueryCropPlan(response.data.growthStages)
          );
          let ids: string[] = [];
          response.data.growthStages.map((item: any) =>
            ids.push(item.growthStageId)
          );
          actions.push(
            queryActions.fetchGrowthActivities({
              ids,
              cropPlanId: payload.cropPlanId,
              sowingDate: payload.sowingDate,
            })
          );
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const fetchGrowthActivitiesEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.FETCH_GROWTH_ACTIVITIES),
    mergeMap(({ payload }) => {
      const promises = payload.ids.map((id: any) => {
        return queryService.fetchGrowthActivities({
          cropPlanId: payload.cropPlanId,
          sowingDate: payload.sowingDate,
          growthStageId: id,
        });
      });
      console.log("promises", promises);
      return from(Promise.all(promises)).pipe(
        concatMap((response: any) => {
          console.log("fetchGrowthActivities response", response);
          type dataType = {
            [key: string]: object;
          };
          let data: dataType = {};
          payload.ids.map((id: any, index: any) => {
            data[id] = response[index].data;
          });
          let actions = [];
          actions.push(queryActions.storeFetchGrowthActivities(data));
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const assignQueryEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.ASSIGN_QUERY),
    mergeMap(({ payload }) => {
      return from(
        queryService.assignQuery({
          ...payload,
          agronomistId: state$.value.auth.userId,
        })
      ).pipe(
        concatMap((response: any) => {
          console.log("Fetch assignQuery list response", response);
          window.location.href = ROUTECONSTANTS.QUERY_MANAGEMENT_ACTIVE_QUERIES;
          let actions = [];
          actions.push(queryActions.fetchActiveQueries());
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          if (
            err?.response?.data?.message &&
            err?.response?.status == 400 &&
            err.response.data.message.includes(
              "Query is already assigned to another Agronomist"
            )
          ) {
            actions.push(
              modalActions.open({
                message: "Something went wrong",
                subMessage: err?.response?.data?.message,
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          } else {
            actions.push(
              modalActions.open({
                message: "Something went wrong",
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          }
          return of(...actions);
        })
      );
    })
  );
};

export const retrieveS3MediaEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.RETRIEVE_S3_MEDIA),
    mergeMap(({ payload }) => {
      return from(
        queryService.retrieves3Media({
          ...payload,
        })
      ).pipe(
        concatMap((response: any) => {
          console.log("retrieves3Media response", response);

          let actions = [];
          actions.push(queryActions.store3Media(response.data.signedMediaUrl));
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          if (err?.response?.data?.message && err?.response?.status == 400) {
            actions.push(
              queryActions.store3Media(err?.response?.data?.message)
            );
          } else {
            actions.push(
              modalActions.open({
                message: "Something went wrong",
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          }
          return of(...actions);
        })
      );
    })
  );
};

export const retrieveS3MediaListEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.RETRIEVE_S3_MEDIA_LIST),
    mergeMap(({ payload }) => {
      return from(
        queryService.retrieves3MediaList({
          ...payload,
        })
      ).pipe(
        concatMap((response: any) => {
          console.log("RETRIEVE_S3_MEDIA_LIST response", response);

          let actions = [];
          actions.push(
            queryActions.store3MediaList(response.data.signedUrlandChatId)
          );
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const sendQueryChatEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.SENT_QUERY_CHAT),
    mergeMap(({ payload }) => {
      return from(
        queryService.sentQueryChat({
          ...payload,
          senderId: state$.value.auth.userId,
        })
      ).pipe(
        concatMap((response: any) => {
          console.log("Fetch sentQueryChat list response", response);
          let actions = [];
          actions.push(queryActions.getQueryById(payload.queryId));
          return of(...actions);
        }),
        catchError((err) => {
          console.log("Error details: " + err);
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const endQueryChatEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.END_QUERY_CHAT),
    mergeMap(({ payload }) => {
      console.log("payload", payload);
      return queryService.endQueryChat({ ...payload });
    }),
    concatMap((response: any) => {
      console.log("Fetch endQueryChat list response", response);
      let actions = [];
      actions.push(
        queryActions.fetchActiveQueries(),
        queryActions.getQueryById(response.querId)
      );
      return of(...actions);
    }),
    catchError((err) => {
      console.log("Error details: " + err);
      let actions = [];
      actions.push(
        modalActions.open({
          message: "Something went wrong",
          okButtonText: LABEL.TRY_AGAIN,
        })
      );
      return of(...actions);
    })
  );
};

export const readQueryChatEpic = (
  action$: any,
  state$: any,
  { queryService }: any
) => {
  return action$.pipe(
    ofType(queryActions.READ_QUERY_CHAT),
    mergeMap(({ payload }) => {
      console.log("payload", payload);
      return queryService.readQueryChat({
        ...payload,
        agronomistId: state$.value.auth.userId,
      });
    }),
    concatMap((response: any) => {
      console.log("Fetch readQueryChat response", response);
      let actions = [];
      actions.push(queryActions.fetchActiveQueries());
      return of(...actions);
    }),
    catchError((err) => {
      console.log("Error details: " + err);
      let actions = [];
      actions.push(
        modalActions.open({
          message: "Something went wrong",
          okButtonText: LABEL.TRY_AGAIN,
        })
      );
      return of(...actions);
    })
  );
};

export default combineEpics(
  assignQueryEpic,
  getQueryByIdEpic,
  endQueryChatEpic,
  sendQueryChatEpic,
  readQueryChatEpic,
  retrieveS3MediaEpic,
  fetchActiveQueriesEpic,
  fetchQueryCropPlanEpic,
  retrieveS3MediaListEpic,
  fetchInactiveQueriesEpic,
  fetchGrowthActivitiesEpic
);
