export const ADD_VARIETY = "ADD_VARIETY";
export const EDIT_VARIETY = "EDIT_VARIETY";
export const PUT_VARIETY = "PUT_VARIETY";
export const DELETE_VARIETY = "DELETE_VARIETY";
export const UPDATE_VARIETY = "UPDATE_VARIETY";
export const FETCH_VARIETY_LIST = "FETCH_VARIETY_LIST";
export const STORE_VARIETY_LIST = "STORE_VARIETY_LIST";
export const FETCH_VARIETY_METADATA = "FETCH_VARIETY_METADATA";
export const STORE_VARIETY_METADATA = "STORE_VARIETY_METADATA";

export const fetchVarieties = () => ({
  type: FETCH_VARIETY_LIST,
});

export const storeVarietyList = (object: any) => ({
  type: STORE_VARIETY_LIST,
  payload: object,
});

export const addVariety = (data: any) => ({
  type: ADD_VARIETY,
  payload: {
    ...data,
  },
});

export const updateVariety = (data: any) => ({
  type: UPDATE_VARIETY,
  payload: data,
});

export const editVariety = (data: any) => ({
  type: EDIT_VARIETY,
  payload: data,
});

export const putVariety = (data: any) => ({
  type: PUT_VARIETY,
  payload: data,
});

export const deleteVariety = (id: string) => ({
  type: DELETE_VARIETY,
  payload: id,
});

export const fetchVarietyMetaData = (data: any) => ({
  type: FETCH_VARIETY_METADATA,
  payload: data,
});

export const storeVarietyMetaData = (data: any) => ({
  type: STORE_VARIETY_METADATA,
  payload: data,
});
