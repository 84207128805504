import {
  KatRow,
  KatTable,
  KatColumn,
  KatTableRow,
  KatTableBody,
  KatTableCell,
  KatRadiobutton,
  KatTableHead,
  KatPagination,
  KatTableFooter,
} from "@amzn/katal-react";
import _map from "lodash/map";
import _slice from "lodash/slice";
import React, { Children, useEffect, useState } from "react";
import { getFomattedDate } from "src/helper";
import { find, lowerCase, map, slice, startCase } from "lodash";
import Pagination from "src/components/common/pagination";
import { LANGUAGES, PAGE_SIZE } from "src/constants/commonConstants";

interface NewQueriesTableProps {
  queryList: any;
  setQuery: (data: any) => void;
  queryId: any;
  page: any;
  setPage: any;
}

const NewQueriesTable = ({
  queryList,
  setQuery,
  queryId,
  page,
  setPage,
}: NewQueriesTableProps) => {
  return (
    <KatRow className="align-items-end overflow-auto">
      <KatColumn sm={12}>
        <KatTable>
          <KatTableHead>
            <KatTableRow>
              <KatTableCell></KatTableCell>
              <KatTableCell className="nowrap">Query ID</KatTableCell>
              <KatTableCell className="nowrap">Language </KatTableCell>
              <KatTableCell className="nowrap">Crop Name</KatTableCell>
              <KatTableCell className="nowrap">Crop Variety</KatTableCell>
              <KatTableCell className="nowrap">Query</KatTableCell>
            </KatTableRow>
          </KatTableHead>
          <KatTableBody>
            {Children.toArray(
              map(
                slice(
                  queryList,
                  page * PAGE_SIZE - PAGE_SIZE,
                  page * PAGE_SIZE
                ),
                (item: any, index) => {
                  return (
                    <KatTableRow>
                      <KatTableCell>
                        <KatRadiobutton
                          className={"queryRadioButton"}
                          name="queryId"
                          value={item?.id}
                          checked={item?.id === queryId}
                          onClick={() => setQuery(item)}
                        ></KatRadiobutton>
                      </KatTableCell>
                      <KatTableCell>
                        {item.id?.split("-")[0]}
                        <span className="queryDate nowrap">
                          {getFomattedDate(item.updatedTime)}
                        </span>
                      </KatTableCell>
                      <KatTableCell>
                        {find(LANGUAGES, { value: item?.language })?.name}
                      </KatTableCell>
                      <KatTableCell>{item.cropName}</KatTableCell>
                      <KatTableCell>{item.cropVarietyName}</KatTableCell>
                      <KatTableCell>
                        <div className="new-queries-ellipsis">
                          {item.recentMessage}
                        </div>
                      </KatTableCell>
                    </KatTableRow>
                  );
                }
              )
            )}
          </KatTableBody>

          <KatTableFooter className="p-2 h-100">
            <section className="d-flex justify-content-end align-items-center">
              <div className="pe-3">{queryList.length} Results</div>
              <KatPagination
                className="vsp-enrollments-pagination"
                itemsPerPage={PAGE_SIZE}
                onPageChanged={(e: any) => {
                  setPage(e.detail.page);
                }}
                page={page}
                totalItems={queryList?.length}
              />
            </section>
          </KatTableFooter>
        </KatTable>
      </KatColumn>
    </KatRow>
  );
};

export default NewQueriesTable;
