import AWS from "aws-sdk";
import { getAWSCredentials } from "src/utilis/AuthHandler";
import { getCognitoConfigDetails } from "src/config/CognitoConfig";

export const getTranslate = async (
  sourceLanguageCode: string,
  targetLanguageCode: string,
  text: string
): Promise<any> => {
  const params = {
    SourceLanguageCode: sourceLanguageCode,
    TargetLanguageCode: targetLanguageCode,
    Text: text,
  };

  return await new Promise(async (resolve, reject) => {
    const getAWSCredentialsResponse = await getAWSCredentials();
    AWS.config.region = getCognitoConfigDetails.region;
    AWS.config.update(getAWSCredentialsResponse.credentials);
    const translate = new AWS.Translate();
    translate.translateText(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
        reject(err);
      } else {
        resolve(data["TranslatedText"]);
      }
    });
  });
};
