import epic from "./epics/index";
import reducer from "src/reducers/index";
import { configureStore } from "@reduxjs/toolkit";
import { createEpicMiddleware } from "redux-observable";
import * as cropService from "src/services/cropService";
import * as queryService from "src/services/queryService";
import * as varietyService from "src/services/varietyService";
import authorizationService from "./api/AuthorizationService";
import * as cropPlanService from "src/services/cropPlanService";

const epicMiddleware = createEpicMiddleware({
  dependencies: {
    cropService,
    queryService,
    varietyService,
    cropPlanService,
    authorizationService,
  },
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(epicMiddleware),
});

epicMiddleware.run(epic);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
