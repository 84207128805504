import React, { Children } from "react";
import { KatIcon } from "@amzn/katal-react";
import { find, get, isEmpty, values } from "lodash";
import { DATE_FORMATS, LABEL } from "src/constants/commonConstants";
import CropPlanStepper from "src/components/common/CropPlanStepper";
import moment from "moment";

interface Props {
  open?: boolean;
  queryCropPlan?: any;
  queryDetails?: Object;
  growthActivities?: any;
  setOpen?: (e: any) => void;
  storeQueryCropPlan: (e: any) => void;
  storeFetchGrowthActivities: (e: any) => void;
}

const CropPlanDrawer = ({
  open,
  setOpen,
  queryDetails,
  queryCropPlan,
  growthActivities,
  storeQueryCropPlan,
  storeFetchGrowthActivities,
}: Props) => {
  if (!open) return null;
  return (
    <div className="cp-drawer">
      <section
        className="cp-drawer-backdrop"
        onClick={() => {
          setOpen?.(false);
          storeQueryCropPlan({});
          storeFetchGrowthActivities({});
        }}
      ></section>
      <header className="cp-drawer-main">
        <section className="cp-drawer-main-container">
          <div>
            <h2 className="cp-drawer-main-title">
              <KatIcon
                className="cp-drawer-main-title-icon"
                name="arrow-left"
                onClick={() => {
                  console.log("sadasd");
                  setOpen?.(false);
                  storeQueryCropPlan({});
                  storeFetchGrowthActivities({});
                }}
              ></KatIcon>{" "}
              Crop Plan
            </h2>

            <article className="cp-drawer-main-blue-strip">
              <div className="cp-drawer-main-blue-strip-item">
                <div>
                  <span className="text-secondary">{LABEL.CROP}:</span>
                  <div className="cp-drawer-main-blue-strip-black">
                    {get(queryDetails, "cropName")}
                  </div>
                </div>
              </div>
              <div className="cp-drawer-main-blue-strip-item">
                <div>
                  <span className="text-secondary">{LABEL.VARIETY}:</span>
                  <div className="cp-drawer-main-blue-strip-black">
                    {get(queryDetails, "cropVarietyName")}
                  </div>
                </div>
              </div>
              <div className="cp-drawer-main-blue-strip-item pl-3">
                <div>
                  <span className="text-secondary">{LABEL.STAGE}:</span>
                  <div className="cp-drawer-main-blue-strip-black">
                    {find(queryCropPlan, { status: "ACTIVE" })
                      ?.growthStageName || get(queryDetails, "currentStage")}
                  </div>
                </div>
              </div>
              <div className="cp-drawer-main-blue-strip-item">
                <div>
                  <span className="text-secondary">{LABEL.DURATION}:</span>
                  <div className="cp-drawer-main-blue-strip-black">
                    {get(queryDetails, "duration", 0)} Days
                  </div>
                </div>
              </div>
            </article>
          </div>

          <main className="px-3 cp-drawer-main-container-stepper">
            {!isEmpty(queryCropPlan) &&
              Children.toArray(
                queryCropPlan?.map((item: any, index: any) => {
                  return (
                    <div className="px-1">
                      <CropPlanStepper
                        header={{
                          aside: moment(item?.startDay, "DD-MM-YYYY").format(
                            DATE_FORMATS.v5
                          ),
                          title: item?.growthStageName,
                          completed: item?.status === "COMPLETED",
                        }}
                        data={values(growthActivities)[index]?.activities.map(
                          (i: any) => ({
                            aside: moment(i?.startDay, "DD-MM-YYYY").format(
                              DATE_FORMATS.v5
                            ),
                            title: i?.activityName,
                            description: i?.description,
                            completed: i?.status === "COMPLETED",
                          })
                        )}
                        asideWidth={"16ch"}
                      />
                    </div>
                  );
                })
              )}
          </main>
        </section>
      </header>
    </div>
  );
};

export default CropPlanDrawer;
