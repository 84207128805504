import { AnyAction } from "redux";
import * as modalActions from "../actions/modalActions";

interface ModalState {
  isOpen: boolean;
  message: string;
  subMessage: string;
}

const initialState: ModalState = {
  isOpen: false,
  message: "",
  subMessage: "",
};

const modalReducer = (state = initialState, action: AnyAction) => {
  const type = action.type;
  const payload = action.payload;

  switch (type) {
    case modalActions.OPEN:
      return { ...payload, isOpen: true };
    case modalActions.CLOSE:
      return { isOpen: false };
    default:
      return state;
  }
};

export default modalReducer;
