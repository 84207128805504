import http from "src/utilis/http";
import API from "src/constants/apiConstants";

export const fetchVarietyList = () => http.get(API.FETCH_VARIETY_LIST);
export const addVariety = (data: any) => http.post(API.ADD_VARIETY, data);
export const editVariety = (data: any) => http.put(API.ADD_VARIETY, data);
export const updateVariety = (data: any) => http.put(API.EDIT_VARIETY, data);
export const fetchVarietyMetaData = (data: any) =>
  http.get(API.FETCH_VARIETY_METADATA, data);
export const deleteVariety = (queryString: any) =>
  http.deleteMethod(API.DELETE_VARIETY, queryString);
