import authApic from "./authEpic";
import cropEpic from "./cropEpic";
import queryEpic from "./queryEpic";
import varietyEpic from "./varietyEpic";
import cropPlanEpic from "./cropPlanEpic";
import { combineEpics } from "redux-observable";

export default combineEpics(
  authApic,
  cropEpic,
  queryEpic,
  varietyEpic,
  cropPlanEpic
);
