import axios from "axios";
import Papa from "papaparse";
import store from "src/store";
import { Buffer } from "buffer";
import _uniqBy from "lodash/uniqBy";
import _isEmpty from "lodash/isEmpty";
import _truncate from "lodash/truncate";
import { of, catchError, from } from "rxjs";
import * as modalActions from "../actions/modalActions";
import { ofType, combineEpics } from "redux-observable";
import { concatMap, mergeMap, map } from "rxjs/operators";
import * as cropPlanActions from "../actions/cropPlanActions";
import {
  LABEL,
  MESSAGE,
  PRESIGNED_CROP_PLAN_TYPE,
} from "src/constants/commonConstants";

export const fetchCropMetaDataEpic = (
  action$: any,
  state$: any,
  { cropPlanService }: any
) => {
  return action$.pipe(
    ofType(cropPlanActions.FETCH_CROP_METADATA),
    mergeMap(() => cropPlanService.fetchMetaData()),
    concatMap((response: any) => {
      console.log("Fetch FETCH_CROP_METADATA list response", response);
      let data: {
        name: string;
        value: string;
      }[] = [];
      if (response?.data?.crops) {
        response.data.crops.map((item: any) => {
          data.push({ name: item.cropName, value: item.cropId });
        });
      }
      let actions = [];
      actions.push(cropPlanActions.storeCropMetaData(data));
      return of(...actions);
    }),
    catchError((err) => {
      console.log("Error details: " + err);
      let actions = [];
      actions.push(
        modalActions.open({
          message: "Something went wrong",
          okButtonText: LABEL.TRY_AGAIN,
        })
      );
      return of(...actions);
    })
  );
};

export const fetchVarietyMetaDataEpic = (
  action$: any,
  state$: any,
  { cropPlanService }: any
) => {
  return action$.pipe(
    ofType(cropPlanActions.FETCH_PLAN_VARIETY_METADATA),
    mergeMap(({ payload }) => cropPlanService.fetchMetaData({ ...payload })),
    concatMap((response: any) => {
      console.log("Fetch FETCH_VARIETY_METADATA list response", response);
      let varietyNameList: {
        name: string;
        value: string;
      }[] = [];
      let companyNameList: {
        name: string;
        value: string;
      }[] = [];
      if (response?.data?.varieties) {
        response.data.varieties.map((item: any) => {
          varietyNameList.push({
            name: item.varietyName,
            value: item.varietyId,
          });
          companyNameList.push({
            name: item.companyName,
            value: item.companyName,
          });
        });
      }
      varietyNameList = _uniqBy(varietyNameList, "name");
      companyNameList = _uniqBy(companyNameList, "name");

      console.log("varietyNameList", varietyNameList, companyNameList);
      let actions = [];
      actions.push(
        cropPlanActions.storeVarietyMetaData({
          varietyNameList,
          companyNameList,
        })
      );
      return of(...actions);
    }),
    catchError((err) => {
      console.log("Error details: " + err);
      let actions = [];
      actions.push(
        modalActions.open({
          message: "Something went wrong",
          okButtonText: LABEL.TRY_AGAIN,
        })
      );
      return of(...actions);
    })
  );
};

export const fetchCropPlanEpic = (
  action$: any,
  state$: any,
  { cropPlanService }: any
) => {
  return action$.pipe(
    ofType(cropPlanActions.FETCH_CROP_PLAN),
    mergeMap(() => cropPlanService.fetchCropPlanData()),
    concatMap((response: any) => {
      console.log(
        "Fetch fetchCropPlanData list response",
        response,
        response.data.cropPlanList
      );
      let actions = [];
      actions.push(cropPlanActions.storeCropPlanData(response.data.cropPlans));
      return of(...actions);
    }),
    catchError((err) => {
      console.log("Error details: " + err);
      let actions = [];
      actions.push(
        modalActions.open({
          message: "Something went wrong",
          okButtonText: LABEL.TRY_AGAIN,
        })
      );
      return of(...actions);
    })
  );
};

export const fetchCropPlanDetailsEpic = (
  action$: any,
  state$: any,
  { cropPlanService }: any
) => {
  return action$.pipe(
    ofType(cropPlanActions.FETCH_CROP_PLAN_DETAILS),
    mergeMap(({ payload }) =>
      cropPlanService.fetchCropPlanData({ ...payload })
    ),
    concatMap((response: any) => {
      console.log(
        "Fetch fetchCropPlanDetails list response",
        response,
        response.data
      );
      let actions = [];
      actions.push(cropPlanActions.storeCropPlanDetails(response.data));
      return of(...actions);
    }),
    catchError((err) => {
      console.log("Error details: " + err);
      let actions = [];
      actions.push(
        modalActions.open({
          message: "Something went wrong",
          okButtonText: LABEL.TRY_AGAIN,
        })
      );
      return of(...actions);
    })
  );
};

export const addCropPlanEpic = (
  action$: any,
  state$: any,
  { cropPlanService }: any
) => {
  return action$.pipe(
    ofType(cropPlanActions.ADD_CROP_PLAN),
    mergeMap(({ payload }) => {
      const {
        cropPlanName,
        varietyId,
        fileUrl,
        growthStages,
        language,
        sowingType,
        isDuplicate,
      } = payload || {};
      return from(
        cropPlanService.addCropPlan({
          uploadedBy: state$.value.auth.fullName,
          cropPlanName,
          varietyId,
          fileUrl,
          growthStages,
          language,
          sowingType,
        })
      ).pipe(
        concatMap((response: any) => {
          let actions = [];
          actions.push(cropPlanActions.fetchCropPlanData());
          actions.push(
            modalActions.open({
              message: MESSAGE.UPLOAD_FILE,
              detailMessage: { type: payload.cropPlanName, name: "" },
              subMessage: MESSAGE.FILE_UPLOADED_SUB_MESSAGE,
              okButtonText: LABEL.CONTINUE,
            })
          );
          return of(...actions);
        }),
        catchError((err) => {
          let actions = [];
          if (err?.response?.status == 400 && err?.response?.data?.message) {
            if (err.response.data.message.includes("validation")) {
              actions.push(
                modalActions.open({
                  message: "Validation Error",
                  detailMessage: {
                    type: "cropPlanError",
                    name: payload?.cropPlanName,
                  },
                  subMessage: `${_truncate(err.response.data.message, {
                    length: 400,
                    separator: " ",
                  })}`,
                  okButtonText: LABEL.TRY_AGAIN,
                  closeIcon: true,
                })
              );
            } else {
              const id = err.response.data.message;
              actions.push(
                modalActions.open({
                  message: LABEL.REPLACE_FILE,
                  detailMessage: {
                    type: "cropPlanError",
                    name: payload?.cropPlanName,
                  },
                  subMessage: MESSAGE.UPLOADED_FILE_EXIST_MESSAGE,
                  okButtonText: LABEL.REPLACE_FILE,
                  cancelButtonText: LABEL.KEEP_EXIST_FILE,
                  closeIcon: true,
                  handleConfirmationOk: () => {
                    if (isDuplicate) {
                      store.dispatch(
                        cropPlanActions.getPreSignedURL({
                          ...payload,
                          isDuplicate,
                          id,
                        })
                      );
                    } else {
                      store.dispatch(
                        cropPlanActions.updateCropPlan({
                          id,
                          ...payload,
                        })
                      );
                    }
                  },
                })
              );
            }
          } else {
            actions.push(
              modalActions.open({
                message: "Something went wrong",
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
          }
          return of(...actions);
        })
      );
    })
  );
};

export const updateCropPlanEpic = (
  action$: any,
  state$: any,
  { cropPlanService }: any
) => {
  return action$.pipe(
    ofType(cropPlanActions.UPDATE_CROP_PLAN),
    mergeMap(({ payload }) => {
      return from(
        cropPlanService.updateCropPlan({
          uploadedBy: state$.value.auth.fullName,
          ...payload,
        })
      ).pipe(
        concatMap((response: any) => {
          let actions = [];
          actions.push(cropPlanActions.fetchCropPlanData());
          actions.push(
            modalActions.open({
              message: MESSAGE.UPLOAD_FILE,
              detailMessage: { type: payload.cropPlanName, name: "" },
              subMessage: MESSAGE.FILE_UPLOADED_SUB_MESSAGE,
              okButtonText: LABEL.CONTINUE,
            })
          );
          return of(...actions);
        }),
        catchError((err) => {
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const getPreSignedURLForDownloadEpic = (
  action$: any,
  state$: any,
  { cropPlanService }: any
) => {
  return action$.pipe(
    ofType(cropPlanActions.GET_PRESIGNED_URL_DOWNLOAD),
    mergeMap(({ payload }) => {
      console.log("download", payload);
      return from(
        cropPlanService.getPreSignedURLForDownload({
          mediaUrl: payload?.mediaUrl,
        })
      ).pipe(
        concatMap((response: any) => {
          console.log("presigned url download reposnse ", response);
          let actions = [];
          actions.push(
            cropPlanActions.storeSignedMediaUrl(response.data.signedMediaUrl)
          );
          return of(...actions);
        }),
        catchError((err) => {
          let actions = [];
          actions.push(
            modalActions.open({
              message: MESSAGE.FAILED_TO_DOWNLOAD,
              subMessage: err?.response?.data?.message || "",
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const requestPreSignedEpic = (
  action$: any,
  state$: any,
  { cropPlanService }: any
) => {
  return action$.pipe(
    ofType(cropPlanActions.REQUEST_PRESIGNED_URL),
    mergeMap(({ payload }) => {
      var config = {
        method: "put",
        url: payload?.preSignedUrl,
        headers: {
          "Content-Type": "text/csv",
        },
        data: Buffer.from(Papa.unparse(payload?.data || {}), "utf-8"),
      };
      return from(axios(config)).pipe(
        concatMap((response: any) => {
          console.log("finally", response);
          let actions = [];
          if (payload?.isDuplicate) {
            actions.push(cropPlanActions.updateCropPlan({ ...payload }));
          } else {
            actions.push(cropPlanActions.addCropPlan({ ...payload }));
          }
          return of(...actions);
        }),
        catchError((err) => {
          let actions = [];
          actions.push(
            modalActions.open({
              message: "Something went wrong",
              subMessage: err?.response?.data?.message,
              okButtonText: LABEL.TRY_AGAIN,
            })
          );
          return of(...actions);
        })
      );
    })
  );
};

export const getPreSignedURLEpic = (
  action$: any,
  state$: any,
  { cropPlanService }: any
) => {
  return action$.pipe(
    ofType(cropPlanActions.GET_PRESIGNED_URL),
    mergeMap(({ payload }) => {
      const { cropName, varietyName, companyName, cropPlanName, isDuplicate } =
        payload || {};
      const preSignedBody = {
        cropName,
        varietyName,
        companyName,
        fileNameWithExtension: cropPlanName,
        type: isDuplicate
          ? PRESIGNED_CROP_PLAN_TYPE.UPDATE
          : PRESIGNED_CROP_PLAN_TYPE.CREATE,
      };
      return from(
        cropPlanService.getPreSignedURL({
          ...preSignedBody,
        })
      ).pipe(
        concatMap((response: any) => {
          console.log("presigned url ", response);
          const s3Url = response.data.s3Url;
          let actions = [];
          actions.push(
            cropPlanActions.requestPreSignedURL({
              ...payload,
              fileUrl: s3Url,
              preSignedUrl: response.data.preSignedUrl,
              isDuplicate,
            })
          );
          return of(...actions);
        }),
        catchError((err) => {
          if (
            err?.response?.data?.message &&
            err?.response?.status == 400 &&
            err.response.data.message.includes("Crop Plan Already Exists")
          ) {
            let actions = [];
            actions.push(
              cropPlanActions.addCropPlan({ ...payload, isDuplicate: true })
            );
            return of(...actions);
          } else {
            let actions = [];
            actions.push(
              modalActions.open({
                message: "Something went wrong",
                subMessage: err?.response?.data?.message,
                okButtonText: LABEL.TRY_AGAIN,
              })
            );
            return of(...actions);
          }
        })
      );
    })
  );
};

export default combineEpics(
  addCropPlanEpic,
  fetchCropPlanEpic,
  updateCropPlanEpic,
  getPreSignedURLEpic,
  requestPreSignedEpic,
  fetchCropMetaDataEpic,
  fetchVarietyMetaDataEpic,
  fetchCropPlanDetailsEpic,
  getPreSignedURLForDownloadEpic
);
