import {
  KatIcon,
  KatTable,
  KatButton,
  KatTableRow,
  KatTableBody,
  KatTableCell,
  KatTableHead,
  KatDropdown,
  KatRow,
  KatColumn,
  KatTableFooter,
  KatPagination,
} from "@amzn/katal-react";
import _map from "lodash/map";
import _slice from "lodash/slice";
import { useNavigate } from "react-router-dom";
import React, { Children, useEffect, useState } from "react";
import Pagination from "src/components/common/pagination";
import { ROUTECONSTANTS } from "src/constants/routeConstants";
import {
  LANGUAGES_CODE,
  STATUS,
  MESSAGE,
  LABEL,
  PAGE_SIZE,
} from "src/constants/commonConstants";
import slice from "lodash/slice";
import map from "lodash/map";

interface VarietyDataTableProps {
  varietyList: any;
  showModal: (data: any) => void;
  editVariety: (data: any) => void;
  updateVariety: (data: any) => void;
  deleteVariety: (id: string) => void;
}

export const VarietyDataTable = ({
  showModal,
  varietyList,
  editVariety,
  deleteVariety,
  updateVariety,
}: VarietyDataTableProps) => {
  const navigate = useNavigate();
  const [varietyId, setVarietyId] = React.useState("");
  const [varietyStatus, setVarietyStatus] = useState("");
  const [page, setPage] = useState(1);

  const handleUpdateAction = (data: any, previousStatus: string) => {
    showModal({
      isOpen: true,
      message: MESSAGE.CHANGE_STATUS("variety"),
      subMessage: MESSAGE.SUB_CHANGE_STATUS,
      okButtonText: "Continue",
      cancelButtonText: "Cancel",
      handleConfirmationOk: () => updateVariety(data),
      handleConfirmationCancel: () => setVarietyStatus(previousStatus),
    });
  };

  const handleEditAction = (data: any) => {
    editVariety(data);
    navigate(ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY_ADD);
  };

  return (
    <KatRow className="align-items-end">
      <KatColumn sm={12}>
        <KatTable>
          <KatTableHead>
            <KatTableRow>
              <KatTableCell>Crop Name</KatTableCell>
              <KatTableCell>Varieties</KatTableCell>
              <KatTableCell>Language</KatTableCell>
              <KatTableCell>Company</KatTableCell>
              <KatTableCell className="text-center">Action</KatTableCell>
              <KatTableCell className="text-center">Status</KatTableCell>
            </KatTableRow>
          </KatTableHead>
          <KatTableBody>
            {Children.toArray(
              map(
                slice(
                  varietyList,
                  page * PAGE_SIZE - PAGE_SIZE,
                  page * PAGE_SIZE
                ),
                (item: any, index) => {
                  return (
                    <KatTableRow key={index}>
                      <KatTableCell>{item.cropName}</KatTableCell>
                      <KatTableCell>{item.cropVarietyName}</KatTableCell>
                      <KatTableCell>
                        {
                          LANGUAGES_CODE[
                            item.languageCode as keyof typeof LANGUAGES_CODE
                          ]
                        }
                      </KatTableCell>
                      <KatTableCell>{item.companyName}</KatTableCell>
                      <KatTableCell className="p-0 text-center">
                        <KatButton
                          onClick={() => handleEditAction(item)}
                          variant="link"
                          className="p-0 mr-2 table-action table-action-edit"
                        >
                          <KatIcon
                            size="small"
                            slot="icon"
                            name="edit"
                          ></KatIcon>
                          <div>{LABEL.EDIT}</div>
                        </KatButton>
                      </KatTableCell>
                      <KatTableCell className="py-0">
                        <KatDropdown
                          value={
                            varietyId === item.id ? varietyStatus : item.status
                          }
                          placeholder="Select Status"
                          options={STATUS}
                          onChange={(event) => {
                            setVarietyId(item.id);
                            setVarietyStatus(event.detail.value);
                            handleUpdateAction(
                              {
                                cropId: item.cropId,
                                id: item.id,
                                status: event.detail.value,
                                version: item.version,
                              },
                              item.status
                            );
                          }}
                          className={`form-select ${
                            varietyId === item.id
                              ? `status-${varietyStatus}`
                              : `status-${item.status}`
                          }`}
                        />
                      </KatTableCell>
                    </KatTableRow>
                  );
                }
              )
            )}
          </KatTableBody>
        </KatTable>
        <KatTableFooter className="p-2 h-100">
          <section className="d-flex justify-content-end align-items-center">
            <div className="pe-3">{varietyList.length} Results</div>
            <KatPagination
              className="vsp-enrollments-pagination"
              itemsPerPage={PAGE_SIZE}
              onPageChanged={(e: any) => {
                setPage(e.detail.page);
              }}
              page={page}
              totalItems={varietyList?.length}
            />
          </section>
        </KatTableFooter>
      </KatColumn>{" "}
    </KatRow>
  );
};

export default VarietyDataTable;
