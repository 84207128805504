import React from "react";
import { connect } from "react-redux";
import logo from "src/images/logo.png";
import { KatIcon } from "@amzn/katal-react";
import HamburgerIcon from "src/images/humburger.png";
import { getUserAvatar, getUserName } from "src/helper";
import { ROUTECONSTANTS } from "../../../constants/routeConstants";

export interface AppbarProps {
  toggleMenu: any;
  userId: string;
  fullName: string;
}

const Appbar = ({ toggleMenu, userId, fullName }: AppbarProps) => {
  return (
    <div className="app-bar grid-item pe-3">
      <div className="logo-content">
        <div>
          <img
            alt="HamburgerIcon"
            className="hamburger-icon"
            src={HamburgerIcon}
            onClick={toggleMenu}
          />
        </div>
        <div>
          <a href={ROUTECONSTANTS.ROOT}>
            <img alt="Logo" src={logo} />
          </a>
        </div>
      </div>

      <section className="align-items-center d-flex">
        <aside>
          <KatIcon name="help_outline" className="help-icon" />
        </aside>
        <main className="text-right px-3">
          <p className="p-0 m-0">Welcome!</p>
          <h5 className="p-0 m-0 nowrap">
            {getUserName({ fullName, userId })}
          </h5>
        </main>
        <article>
          <img
            className="profile-img"
            alt="image"
            src={getUserAvatar(userId)}
          />
        </article>
      </section>
    </div>
  );
};

interface stateType {
  auth: {
    userId: string;
    fullName: string;
  };
}

const mapState = (state: stateType) => ({
  userId: state.auth.userId,
  fullName: state.auth.fullName,
});

const connector = connect(mapState);

export default connector(Appbar);
