import { AnyAction } from "redux";
import * as cropPlanActions from "../actions/cropPlanActions";

const initialState = {
  cropPlan: [],
  cropMetadata: [],
  signedMediaUrl: "",
  varietyMetadata: [],
  cropPlanDetails: {},
};

const cropPlanReducer = (state = initialState, action: AnyAction) => {
  const type = action.type;
  const payload = action.payload;

  switch (type) {
    case cropPlanActions.STORE_VARIETY_METADATA:
      return {
        ...state,
        varietyMetadata: payload,
      };
    case cropPlanActions.STORE_CROP_METADATA:
      return {
        ...state,
        cropMetadata: payload,
      };
    case cropPlanActions.STORE_CROP_PLAN:
      return {
        ...state,
        cropPlan: payload,
      };
    case cropPlanActions.STORE_CROP_PLAN_DETAILS:
      return {
        ...state,
        cropPlanDetails: payload,
      };
    case cropPlanActions.STORE_SIGNED_MEDIA_URL:
      return {
        ...state,
        signedMediaUrl: payload,
      };
    default:
      return state;
  }
};

export default cropPlanReducer;
