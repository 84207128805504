import { getAWSCredentials } from "./AuthHandler";
import { getCognitoConfigDetails } from "src/config/CognitoConfig";
import {
  default as apigClientFactory,
  ApiGatewayClient,
} from "aws-api-gateway-client";

type ApiGatewayConfig = {
  invokeUrl: string;
  accessKey?: string;
  secretKey?: string;
  sessionToken?: string;
  sessionExpiration?: Date;
  region?: string;
};

let apiGatewayClient: ApiGatewayClient | undefined;

export const getApiClient = async (): Promise<any> => {
  if (apiGatewayClient === undefined) {
    const getAWSCredentialsResponse = await getAWSCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } =
      getAWSCredentialsResponse.credentials || {};
    const { region, invokeUrlURL } = getCognitoConfigDetails;
    const config: ApiGatewayConfig = {
      invokeUrl: invokeUrlURL,
      accessKey: accessKeyId,
      secretKey: secretAccessKey,
      sessionToken: sessionToken,
      region: region,
    };
    apiGatewayClient = apigClientFactory.newClient(config);
  }
  return apiGatewayClient;
};
