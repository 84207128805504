export const STORE_CHATS = "STORE_CHATS";
export const ASSIGN_QUERY = "ASSIGN_QUERY";
export const STORE_S3_MEDIA = "STORE_S3_MEDIA";
export const END_QUERY_CHAT = "END_QUERY_CHAT";
export const READ_QUERY_CHAT = "READ_QUERY_CHAT";
export const GET_QUERY_BY_ID = "GET_QUERY_BY_ID";
export const SENT_QUERY_CHAT = "SENT_QUERY_CHAT";
export const RETRIEVE_S3_MEDIA = "RETRIEVE_S3_MEDIA";
export const STORE_S3_MEDIA_LIST = "STORE_S3_MEDIA_LIST";
export const SELECT_ACTIVE_QUERY = "SELECT_ACTIVE_QUERY";
export const FETCH_ACTIVE_QUERIES = "FETCH_ACTIVE_QUERIES";
export const STORE_ACTIVE_QUERIES = "STORE_ACTIVE_QUERIES";
export const FETCH_QUERY_CROP_PLAN = "FETCH_QUERY_CROP_PLAN";
export const STORE_QUERY_CROP_PLAN = "STORE_QUERY_CROP_PLAN";
export const RETRIEVE_S3_MEDIA_LIST = "RETRIEVE_S3_MEDIA_LIST";
export const FETCH_INACTIVE_QUERIES = "FETCH_INACTIVE_QUERIES";
export const STORE_INACTIVE_QUERIES = "STORE_INACTIVE_QUERIES";
export const FETCH_GROWTH_ACTIVITIES = "FETCH_GROWTH_ACTIVITIES";
export const STORE_GROWTH_ACTIVITIES = "STORE_GROWTH_ACTIVITIES";

export const fetchInactiveQueries = () => ({
  type: FETCH_INACTIVE_QUERIES,
});

export const storeInactiveQueries = (data: any) => ({
  type: STORE_INACTIVE_QUERIES,
  payload: data,
});

export const fetchActiveQueries = () => ({
  type: FETCH_ACTIVE_QUERIES,
});

export const storeActiveQueries = (data: any) => ({
  type: STORE_ACTIVE_QUERIES,
  payload: data,
});

export const fetchQueryCropPlan = (data: any) => ({
  type: FETCH_QUERY_CROP_PLAN,
  payload: data,
});

export const storeQueryCropPlan = (data: any) => ({
  type: STORE_QUERY_CROP_PLAN,
  payload: data,
});

export const assignQuery = (data: any) => ({
  type: ASSIGN_QUERY,
  payload: data,
});

export const sentQueryChat = (data: any) => ({
  type: SENT_QUERY_CHAT,
  payload: data,
});
export const getQueryById = (queryId: any) => ({
  type: GET_QUERY_BY_ID,
  payload: queryId,
});

export const storeChats = (data: any) => ({
  type: STORE_CHATS,
  payload: data,
});

export const endQueryChat = (data: any) => ({
  type: END_QUERY_CHAT,
  payload: data,
});

export const selectActiveQuery = (data: any) => ({
  type: SELECT_ACTIVE_QUERY,
  payload: data,
});

export const readQueryChat = (data: any) => ({
  type: READ_QUERY_CHAT,
  payload: data,
});

export const retrieves3Media = (data: any) => ({
  type: RETRIEVE_S3_MEDIA,
  payload: data,
});

export const store3Media = (data: any) => ({
  type: STORE_S3_MEDIA,
  payload: data,
});

export const retrieves3MediaList = (data: any) => ({
  type: RETRIEVE_S3_MEDIA_LIST,
  payload: data,
});

export const store3MediaList = (data: any) => ({
  type: STORE_S3_MEDIA_LIST,
  payload: data,
});

export const fetchGrowthActivities = (data: any) => ({
  type: FETCH_GROWTH_ACTIVITIES,
  payload: data,
});

export const storeFetchGrowthActivities = (data: any) => ({
  type: STORE_GROWTH_ACTIVITIES,
  payload: data,
});
