import { getApiClient } from "./ApiGatewayClient";
import { logger } from "../logger";

interface ApiDetails {
  pathParams?: Record<string, unknown>;
  pathTemplate: string;
  method: "GET" | "POST" | "DELETE" | "PUT";
  additionalParams?: {
    headers?: { [key: string]: unknown };
    queryParams?: { [key: string]: string | number | boolean };
    timeout?: number;
  };
  body?: string;
}

export const api = async (data: ApiDetails) => {
  return await new Promise(async (resolve, reject) => {
    let { pathParams, pathTemplate, additionalParams, body, method } = data;
    pathParams = pathParams || {};
    additionalParams = additionalParams || {};
    body = body || "";
    const client = await getApiClient();

    if (!client) {
      reject(client);
    }

    client
      .invokeApi(pathParams, pathTemplate, method, additionalParams, body)
      .then((response: any) => {
        resolve(response);
      })
      .catch((response: any) => {
        console.log(
          `Request for HTTP ${method} ${pathTemplate} failed. Data : ${JSON.stringify(
            data
          )} Response : ${JSON.stringify(response)}`
        );
        if (response?.message == "Network Error") {
          window.location.href = "/";
        } else {
          logger.info(
            `Request for HTTP ${method} ${pathTemplate} failed. Data : ${JSON.stringify(
              data
            )} Response : ${JSON.stringify(response)}`
          );
        }
        reject(response);
      });
  });
};

const get = (pathTemplate: string, queryString: any = {}) => {
  return api({
    pathTemplate,
    method: "GET",
    additionalParams: { queryParams: queryString },
  });
};

const post = (pathTemplate: string, body: any) => {
  return api({
    pathTemplate,
    method: "POST",
    body,
  });
};

const put = (pathTemplate: string, body: any) => {
  return api({
    pathTemplate,
    method: "PUT",
    body,
  });
};

const deleteMethod = (pathTemplate: string, queryString: any) => {
  return api({
    pathTemplate,
    additionalParams: { queryParams: queryString },
    method: "DELETE",
  });
};

export default {
  get,
  put,
  post,
  deleteMethod,
};
