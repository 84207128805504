import { AnyAction } from "redux";
import * as varietyActions from "../actions/varietyActions";

const initialState = {
  editVariety: {},
  varietyList: [],
  varietyMetaData: {},
};

const authReducer = (state = initialState, action: AnyAction) => {
  const type = action.type;
  const payload = action.payload;

  switch (type) {
    case varietyActions.STORE_VARIETY_LIST:
      return {
        ...state,
        varietyList: payload,
      };
    case varietyActions.STORE_VARIETY_METADATA:
      return {
        ...state,
        varietyMetaData: payload,
      };
    case varietyActions.EDIT_VARIETY:
      return {
        ...state,
        editVariety: payload,
      };
    default:
      return state;
  }
};

export default authReducer;
