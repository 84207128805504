import { KatBox } from "@amzn/katal-react";
import React from "react";

export const ShadowBox = ({ children }: any) => {
  return (
    <KatBox variant="white-shadow" className="shadow-box">
      {children}
    </KatBox>
  );
};
