import moment from "moment";
import Footer from "./Footer";
import Header from "./Header";
import { connect } from "react-redux";
import { KatIcon } from "@amzn/katal-react";
import * as queryActions from "src/actions/queryActions";
import { capitalize, isEmpty, isEqual, map } from "lodash";
import React, { Children, useEffect, useState } from "react";
import { LABEL, REGEX } from "../../../../constants/commonConstants";
import { getSignedMediaURL } from "src/helper";

export const ChatArea = ({
  sendQueryChat,
  queryDetails,
  fetchQuery,
  chats,
  retrieves3Media,
  s3Media,
  store3Media,
  chats3MediaList,
  clickedFromSideNav,
  setClickedFromSideNav,
  readQueryChat,
}: any) => {
  const messagesEndRef = React.createRef();
  const [isChats, setIsChats] = useState(false);
  const [prevChatIds, setPrevChatIds] = useState() as any;

  useEffect(() => {
    if (queryDetails.id && !isEqual(map(chats, "id"), prevChatIds)) {
      setPrevChatIds(map(chats, "id"));
      scrollToBottom(messagesEndRef);
    }
  }, [chats, queryDetails.id]);

  useEffect(() => {
    setIsChats(false);
    if (queryDetails?.id) {
      fetchQuery(queryDetails.id);
      const interval = setInterval(() => {
        fetchQuery(queryDetails.id);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [queryDetails.id]);

  useEffect(() => {
    if (!isChats && !isEmpty(chats)) {
      scrollToBottom(messagesEndRef);
      setIsChats(true);
    }

    if (!isEmpty(queryDetails) && queryDetails?.unSeenByAgronomist != 0) {
      readQueryChat({
        farmerId: queryDetails.farmerId,
        farmerCropId: queryDetails.farmerCropId,
      });
    }
  }, [chats]);

  const scrollToBottom = (messagesEndRef: any) => {
    messagesEndRef?.current?.scrollIntoView();
  };

  if (isEmpty(queryDetails))
    return (
      <main className="active-queries-select-chats">
        <span>Select a profile to continue with chatting</span>
      </main>
    );

  return (
    <main className="active-queries-chats">
      <Header {...{ queryDetails }} />

      <main className="active-queries-chats-main">
        {Children.toArray(
          map(
            chats,
            ({
              id,
              createdTime,
              message,
              farmerId,
              hasMedia,
              mediaUrl,
              readStatus,
              senderId,
            }: any) => {
              return (
                <div
                  className={`${
                    farmerId === senderId
                      ? "active-queries-chats-main-received"
                      : "active-queries-chats-main-sent"
                  }`}
                >
                  <header>
                    <span>
                      {farmerId === senderId
                        ? LABEL.AMAZON_FARMER
                        : LABEL.AGRONOMIST}
                    </span>{" "}
                    {moment(createdTime).fromNow()}
                  </header>
                  {hasMedia.toString() === "true" && (
                    <div
                      className={
                        !REGEX.MEDIA_LINK_MP3.test(mediaUrl)
                          ? "active-queries-chats-main-sent-media-container"
                          : ""
                      }
                    >
                      {getSignedMediaURL(
                        id,
                        {
                          [REGEX.MEDIA_LINK_MP4.test(mediaUrl).toString()]:
                            "video",
                          [REGEX.MEDIA_LINK_MP3.test(mediaUrl).toString()]:
                            "audio",
                          [REGEX.MEDIA_LINK.test(mediaUrl).toString()]: "image",
                        }["true"],
                        chats3MediaList
                      )}
                    </div>
                  )}
                  <p>{message}</p>
                  <footer>
                    <aside></aside>
                    <span>
                      <KatIcon name="done_all" size="tiny" />{" "}
                      {capitalize(readStatus)}
                    </span>
                  </footer>
                </div>
              );
            }
          )
        )}
        <div
          id="active-query-chats-end-point"
          ref={messagesEndRef as React.RefObject<HTMLDivElement>}
        />
      </main>

      <Footer
        {...{
          sendQueryChat,
          queryDetails,
          scrollToBottom,
          retrieves3Media,
          s3Media,
          store3Media,
        }}
      />
    </main>
  );
};

export const mapStateToProps = (state: any) => ({
  queryList: state.query.queryList,
  chats: state.query.chats,
  s3Media: state.query.s3Media,
  chats3MediaList: state.query.chats3MediaList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchQuery: (queryId: any) => {
    dispatch(queryActions.getQueryById(queryId));
  },
  retrieves3Media: (data: any) => {
    dispatch(queryActions.retrieves3Media(data));
  },
  store3Media: (data: any) => {
    dispatch(queryActions.store3Media(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ChatArea);
