import _isEmpty from "lodash/isEmpty";
import { Popup } from "src/components/common/Popup";
import { getTranslate } from "src/utilis/TranslateHandler";
import React, { Fragment, useEffect, useState } from "react";
import FormInput from "src/components/FormComponents/FormInput";
import {
  LABEL,
  LANGUAGES,
  MESSAGE,
  REGEX,
} from "src/constants/commonConstants";
import { KatDropdown, KatIcon, KatInputGroup } from "@amzn/katal-react";
import { isEmpty, startsWith } from "lodash";
import AttachmentPopup from "./AttachmentPopup";

export const Footer = ({
  queryDetails,
  sendQueryChat,
  retrieves3Media,
  s3Media,
  store3Media,
}: any) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [translatedMessage, setTranslatedMessage] = useState("");
  const [farmerLanguage, setFarmerLanguage] = useState(LANGUAGES[1].value);
  const [mediaError, setMediaError] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [attachmentPopup, setAttachmentPopup] = useState(false);
  const [attachement, setAttachement] = useState("");

  const userLanguage = "en";

  const translationHandler = async () => {
    console.log(message, farmerLanguage, userLanguage);
    const translatedMessage = await getTranslate(
      userLanguage,
      farmerLanguage,
      message
    );
    setTranslatedMessage(translatedMessage);
  };

  const handlerMedia = (link: any) => {
    setAttachement(link);
    if (REGEX.MEDIA_LINK.test(link)) {
      store3Media({});
      retrieves3Media({ mediaUrl: link });
    }
  };

  useEffect(() => {
    setLoading(false);
    if (
      !isEmpty(s3Media) &&
      s3Media.includes(MESSAGE.FILE_DOES_NOT_EXISTS_IN_S3)
    ) {
      setMediaError(MESSAGE.FILE_DOES_NOT_EXISTS_IN_S3);
    }
  }, [s3Media]);

  useEffect(() => {
    if (!attachmentPopup) {
      setAttachement("");
      store3Media({});
      setMediaError("");
    }
  }, [attachmentPopup]);

  return (
    <Fragment>
      <AttachmentPopup
        attachmentPopup={attachmentPopup}
        setAttachmentPopup={setAttachmentPopup}
        attachement={attachement}
        mediaError={mediaError}
        sendQueryChat={sendQueryChat}
        queryDetails={queryDetails}
        setMediaError={setMediaError}
        setLoading={setLoading}
        handlerMedia={handlerMedia}
        setAttachement={setAttachement}
        s3Media={s3Media}
        loading={loading}
      />
      <footer className="active-queries-chats-footer">
        <section className="active-queries-chats-footer-input">
          <section className="w-100">
            <p className="mb-2">English</p>
            <FormInput
              placeholder={`${LABEL.TYPE_HERE}...`}
              className="typeHere w-100 input-group"
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
            />
          </section>
          <button id="translate-button" onClick={() => translationHandler()}>
            <KatIcon name="arrow-right" size="small" />
          </button>
          <section className="w-100">
            <KatDropdown
              options={LANGUAGES}
              className="lang-selection"
              value={farmerLanguage}
              onChange={(e: any) => setFarmerLanguage(e.detail.value)}
              {...{ expanded }}
            ></KatDropdown>

            <KatInputGroup className="input-group">
              <FormInput
                placeholder={`${LABEL.TYPE_HERE}...`}
                className="typeHere w-100"
                value={translatedMessage}
                onChange={(e: any) => setMessage(e.target.value)}
              />
              <svg
                width={30}
                height={30}
                viewBox="0 0 10 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setExpanded(!expanded)}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.70711 5.70711C9.31658 6.09763 8.68342 6.09763 8.29289 5.70711L5 2.41421L1.70711 5.70711C1.31658 6.09763 0.683418 6.09763 0.292893 5.70711C-0.0976311 5.31658 -0.0976311 4.68342 0.292893 4.29289L4.29289 0.292893C4.68342 -0.0976311 5.31658 -0.0976311 5.70711 0.292893L9.70711 4.29289C10.0976 4.68342 10.0976 5.31658 9.70711 5.70711ZM1.70711 12.2929L5 15.5858L8.29289 12.2929C8.68342 11.9024 9.31658 11.9024 9.70711 12.2929C10.0976 12.6834 10.0976 13.3166 9.70711 13.7071L5.70711 17.7071C5.31658 18.0976 4.68342 18.0976 4.29289 17.7071L0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929C0.683418 11.9024 1.31658 11.9024 1.70711 12.2929Z"
                  fill="#232F3E"
                />
              </svg>
            </KatInputGroup>
          </section>
        </section>
        <section className="d-flex align-items-center">
          <button className="attachment-button">
            <KatIcon
              name="attachment"
              className="attachment-button-icon"
              onClick={() => {
                setLoading(true);
                setAttachmentPopup(true);
              }}
            />
          </button>
          <button
            id="send-button"
            disabled={!translatedMessage && !message}
            onClick={() => {
              sendQueryChat({
                message: translatedMessage || message,
                hasMedia: "false",
                queryId: queryDetails.id,
                farmerId: queryDetails.farmerId,
                farmerCropId: queryDetails.farmerCropId,
              });
              setMessage("");
              setTranslatedMessage("");
            }}
          >
            <KatIcon className="sendIcon" name="send" size="small" />
          </button>
        </section>
      </footer>
    </Fragment>
  );
};

export default Footer;
