import { CognitoAuth, CognitoAuthSession } from "amazon-cognito-auth-js";
import { getCognitoConfigDetails } from "src/config/CognitoConfig";

export class AuthorizationService {
  auth?: CognitoAuth = undefined;

  /**
   * Ensures the user is authenticated via Cognito, and returns the CognitoAuth object
   * once authenticated.
   * Note that this can potentially redirect the user to the Cognito identity provider
   * (e.g. Midway) for login.
   */
  public async ensureAuthenticated(): Promise<CognitoAuth> {
    const auth = this.getCognitoAuth();

    const href = window.location.href;
    const session = auth.getSignInUserSession();
    if (session.isValid()) {
      return auth;
    } else {
      //Example URL: http://localhost:4321/?code=c1fb1035-16b7-491b-8707-39a090c969b2
      const params = new URLSearchParams(window.location.search);
      console.log("window.location.search", window.location.href);
      const cognitoSignIn = params.has("code")
        ? () => auth.parseCognitoWebResponse(window.location.href)
        : () => this.storeCurrentLocationInState(auth);

      await this.waitForCognitoSignIn(auth, cognitoSignIn);
      return auth;
    }
    return auth;
  }

  private async waitForCognitoSignIn(
    auth: CognitoAuth,
    cognitoSignIn: () => void
  ): Promise<CognitoAuth> {
    return new Promise((resolve, reject) => {
      auth.userhandler = {
        onFailure: (err: any) => {
          auth.clearCachedTokensScopes();
          this.restorePreviousLocation();
          console.log(err);
          this.removeQueryFromLocation();
          reject(err);
        },
        onSuccess: (session: CognitoAuthSession) => {
          this.restorePreviousLocation(session);
          console.log("success");
          this.removeQueryFromLocation();
          resolve(auth);
        },
      };
      cognitoSignIn();
    });
  }

  /**
   * Add redirect URI to the session state so that, after successful login and redirect,
   * we can return the user to the same location/view.  Uses the strategy outlined in
   * https://tiny.amazon.com/1itnzg7p5
   */
  private storeCurrentLocationInState(auth: CognitoAuth) {
    const nonce = auth.generateRandomString(
      auth.getCognitoConstants().STATELENGTH,
      auth.getCognitoConstants().STATEORIGINSTRING
    );
    const state = {
      location: location.pathname + location.hash,
      nonce,
    };
    auth.setState(window.btoa(JSON.stringify(state)));
    // Triggers a redirect to the Cognito identity provider, if applicable
    auth.getSession();
  }

  /**
   * Retrieves the previous path stored in the Cognito session state, if applicable
   * @param session A valid Cognito auth session
   */
  private getPreviousLocationFromState(session?: CognitoAuthSession) {
    let previousPath = "";
    if (session?.getState()) {
      const statePath = decodeURIComponent(session.getState());
      const state = JSON.parse(window.atob(statePath));
      previousPath = state.location;
    }
    return previousPath;
  }

  private getCognitoAuth(): CognitoAuth {
    if (this.auth === undefined) {
      this.auth = new CognitoAuth(getCognitoConfigDetails);
      this.auth.useCodeGrantFlow();
    }
    return this.auth;
  }

  /**
   * Removes the query string/parameters from the URL (i.e. after a redirect from Cognito),
   * as Cognito will store the session "code" in the URL params.
   * Additionally, if the previous location was stored in the Cognito "state" parameter,
   * redirect to that location
   * @param session A (valid) Cognito auth session
   */
  private restorePreviousLocation(session?: CognitoAuthSession) {
    if (window.history.length > 0) {
      const previousPath = this.getPreviousLocationFromState(session);
      this.auth?.setState(previousPath);
    }
  }

  // This code removes the "?code=..." from the URL. It is because the grant code is not reusable. Sometimes
  // the SDK will report weird message because of using old grant code.
  private removeQueryFromLocation() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string
    // And the grant code is not reusable
    if (window.history.length > 0) {
      const newHref = window.location.href.split("?")[0];
      window.history.replaceState(undefined, "Agronomist Website", newHref);
    }
  }
}

export default new AuthorizationService();
