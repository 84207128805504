import _filter from "lodash/filter";
import { connect } from "react-redux";
import _includes from "lodash/includes";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { varietyFilter } from "src/helper";
import VarietyFilter from "./VarietyFilter";
import VarietyDataTable from "./VarietyTable";
import AppContainer from "src/components/AppContainer";
import * as modalActions from "src/actions/modalActions";
import * as varietyActions from "src/actions/varietyActions";
import { LABEL, PAGETITLE } from "src/constants/commonConstants";
import { KatButton, KatColumn, KatRow } from "@amzn/katal-react";
import { ROUTECONSTANTS, ROUTENAME } from "src/constants/routeConstants";

interface VarietyDataTableProps {
  varietyList: any;
  fetchVarieties: () => void;
  showModal: (data: any) => void;
  editVariety: (data: any) => void;
  updateVariety: (data: any) => void;
  deleteVariety: (id: string) => void;
}

export const VarietyPage = ({
  showModal,
  varietyList,
  editVariety,
  deleteVariety,
  updateVariety,
  fetchVarieties,
}: VarietyDataTableProps) => {
  const [filterData, setFilterData] = React.useState(varietyList);
  const [filterCriteria, setFilterCriteria] = React.useState({
    status: "",
    cropName: "",
    varietyName: "",
    companyName: "",
    languageCode: "",
  });

  useEffect(() => {
    fetchVarieties();
    editVariety({});
  }, []);

  useEffect(() => {
    handleFilter();
  }, [varietyList]);

  const handleFilter = () => {
    const filteredVariety = varietyFilter({ filterCriteria, varietyList });
    setFilterData(filteredVariety);
  };

  return (
    <AppContainer
      parentKey={ROUTENAME.CROP_MANAGEMENT}
      childKey={ROUTENAME.CROP_MANAGEMENT_VARIETY}
    >
      <section>
        <KatRow className="align-items-end justify-content-between mb-4">
          <KatColumn sm={6}>
            <h1>{PAGETITLE.VARIETY}</h1>
          </KatColumn>

          <KatColumn sm={2}>
            <Link to={ROUTECONSTANTS.CROP_MANAGEMENT_VARIETY_ADD}>
              <KatButton
                variant="primary"
                label={"+" + LABEL.ADD_NEW_VARIETY}
                className="w-100"
              />
            </Link>
          </KatColumn>
        </KatRow>
        <VarietyFilter
          {...{ filterCriteria, setFilterCriteria, handleFilter }}
          varietyList={varietyList}
        />
        <VarietyDataTable
          varietyList={filterData}
          showModal={showModal}
          editVariety={editVariety}
          deleteVariety={deleteVariety}
          updateVariety={updateVariety}
        />
      </section>
    </AppContainer>
  );
};

interface stateType {
  variety: {
    varietyList: [
      {
        id: string;
        version: string;
        status: string;
        cropName: string;
        companyName: string;
        languageCode: string;
        cropVarietyName: string;
      }
    ];
  };
}

export const mapStateToProps = (state: stateType) => ({
  varietyList: state.variety.varietyList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchVarieties: () => {
    dispatch(varietyActions.fetchVarieties());
  },
  deleteVariety: (id: string) => {
    dispatch(varietyActions.deleteVariety(id));
  },
  updateVariety: (data: any) => {
    dispatch(varietyActions.updateVariety(data));
  },
  editVariety: (data: any) => {
    dispatch(varietyActions.editVariety(data));
  },
  showModal: (data: any) => {
    dispatch(modalActions.open(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(VarietyPage);
