export const ENSURE_AUTHENTICATED = "AUTH";
export const STORE_USER_DETAILS = "STORE_USER_DETAILS";

export const ensureAuthenticated = () => ({
  type: ENSURE_AUTHENTICATED,
});

export const storeUserDetails = (data: any) => ({
  type: STORE_USER_DETAILS,
  payload: data,
});
