import React from "react";
import { Link } from "react-router-dom";
import { KatButton } from "@amzn/katal-react";
import ErrorImage from "../../../images/error-lock.png";
import { ROUTECONSTANTS } from "src/constants/routeConstants";

const Error = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100vh">
      <section className="text-center">
        <img
          alt="error lock image"
          src={ErrorImage}
          className="h-50 w-50 p-2"
        />

        <h3 className="mt-4">
          Sorry, You do not have access to this application
        </h3>

        <p className="w-75 mx-auto text-secondary mb-5">
          Only agronomist and admin authorized to use any functionality of the
          application.
        </p>
        <Link to={ROUTECONSTANTS.ROOT}>
          <KatButton variant="primary" label="Go Back" className="w-100 mt-5" />
        </Link>
      </section>
    </div>
  );
};

export default Error;
