import SideNav from "./SideNav";
import ChatArea from "./ChatArea";
import _isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import AppContainer from "src/components/AppContainer";
import { ROUTENAME } from "src/constants/routeConstants";
import * as queryActions from "src/actions/queryActions";

export const ActiveQueries = ({
  sendQueryChat,
  readQueryChat,
  activeQueryList,
  fetchActiveQueries,
}: any) => {
  const [selectedQuery, setSelectedQuery] = React.useState({} as any);
  const [timeInterval, setTimeInterval] = React.useState(false);
  const [clickedFromSideNav, setClickedFromSideNav] = React.useState(false);

  useEffect(() => {
    if (activeQueryList && activeQueryList[0]) {
      const queryItem = activeQueryList[0];
      if (_isEmpty(selectedQuery)) {
        setSelectedQuery(queryItem);
        if (queryItem?.unSeenByAgronomist != 0) {
          readQueryChat({
            farmerId: queryItem.farmerId,
            farmerCropId: queryItem.farmerCropId,
          });
        }
      } else {
        const queryExist = activeQueryList.find(
          (item: any) => item.id == selectedQuery?.id
        );
        if (queryExist) {
          setSelectedQuery(queryExist);
          if (queryExist?.unSeenByAgronomist != 0) {
            readQueryChat({
              farmerId: queryExist.farmerId,
              farmerCropId: queryExist.farmerCropId,
            });
          }
        } else {
          setSelectedQuery({});
        }
      }
      setClickedFromSideNav(true);
    } else {
      setSelectedQuery({});
    }
  }, [activeQueryList]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchActiveQueries();
    }, 5000);
    return () => clearInterval(interval);
  }, [timeInterval]);

  useEffect(() => {
    fetchActiveQueries();
    setTimeInterval(true);
  }, []);

  return (
    <AppContainer
      parentKey={ROUTENAME.QUERY_MANAGEMENT}
      childKey={ROUTENAME.QUERY_MANAGEMENT_ACTIVE_QUERIES}
      noPadding
    >
      <section className="active-queries">
        <SideNav
          readQueryChat={readQueryChat}
          selectedQuery={selectedQuery}
          activeQueryList={activeQueryList}
          setSelectedQuery={setSelectedQuery}
          setClickedFromSideNav={setClickedFromSideNav}
        />
        <ChatArea
          readQueryChat={readQueryChat}
          queryDetails={selectedQuery}
          sendQueryChat={sendQueryChat}
          clickedFromSideNav={clickedFromSideNav}
          setClickedFromSideNav={setClickedFromSideNav}
        />
      </section>
    </AppContainer>
  );
};

export const mapStateToProps = (state: any) => ({
  activeQueryList: state.query.activeQueryList,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchActiveQueries: () => {
    dispatch(queryActions.fetchActiveQueries());
  },
  sendQueryChat: (data: any) => {
    dispatch(queryActions.sentQueryChat(data));
  },
  readQueryChat: (data: any) => {
    dispatch(queryActions.readQueryChat(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(ActiveQueries);
