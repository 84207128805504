import authReducer from "./authReducer";
import cropReducer from "./cropReducer";
import modalReducer from "./modalReducer";
import queryReducer from "./queryReducer";
import varietyReducer from "./varietyReducer";
import cropPlanReducer from "./cropPlanReducer";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  auth: authReducer,
  crop: cropReducer,
  modal: modalReducer,
  query: queryReducer,
  variety: varietyReducer,
  cropPlan: cropPlanReducer,
});

export default rootReducer;
