import http from "src/utilis/http";
import API from "src/constants/apiConstants";

export const endQueryChat = (body: any) => http.put(API.END_CHAT, body);
export const assignQuery = (body: any) => http.put(API.ASSIGN_QUERY, body);
export const fetchInActiveQueryList = () => http.get(API.FETCH_INACTIVE_QUERY);
export const readQueryChat = (body: any) => http.put(API.READ_QUERY_CHAT, body);
export const sentQueryChat = (body: any) =>
  http.post(API.SENT_QUERY_CHAT, body);
export const retrieves3Media = (body: any) =>
  http.post(API.RETRIEVE_S3_MEDIA, body);
export const retrieves3MediaList = (body: any) =>
  http.post(API.RETRIEVE_S3_MEDIA_LIST, body);
export const getQueryById = (queryString: any) =>
  http.get(API.GET_QUERY_BY_ID, queryString);
export const fetchQueryCropPlan = (body: any) =>
  http.post(API.FETCH_QUERY_CROP_PLAN_GROWTH, body);
export const fetchActiveQueryList = (queryString: any) =>
  http.get(API.FETCH_ACTIVE_QUERY, queryString);
export const fetchGrowthActivities = (body: any) =>
  http.post(API.FETCH_QUERY_CROP_PLAN_GROWTH_ACTIVITIES, body);
