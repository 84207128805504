import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface Props {
  children: JSX.Element;
}
interface stateType {
  auth: {
    userId: string;
  };
}

const prodUrl = "prod.kisan-engagement.kisan.amazon.dev";
const moeAppID = "2349UMPPMA4VH8M474MW99T2";

function MoengageProvider(props: Props) {
  const user: stateType = useSelector((state: stateType) => state);

  const [isAuthSuccess, setIsAuthSuccess] = useState(false);

  useEffect(() => {
    if (!isAuthSuccess && user && user.auth && user.auth.userId) {
      // @ts-ignore
      window.Moengage = window.moe({
        app_id: moeAppID,
        debug_logs: window.location.hostname === prodUrl ? 0 : 1,
      });

      // @ts-ignore
      window.Moengage.add_unique_user_id(user.auth.userId);
      // @ts-ignore
      window.Moengage.add_user_attribute("ATTRIBUTE_USER_TYPE", "Agronomist");
      setIsAuthSuccess(true);
    }
  }, [user]);

  return props.children;
}

export default MoengageProvider;
