import AWS from "aws-sdk";
import { CognitoAuth } from "amazon-cognito-auth-js";
import { getCognitoConfigDetails } from "src/config/CognitoConfig";
import moment from "moment";

const ROLE_KEY = "custom:role";
const ANT_ROLE_KEY = "custom:ant_role";

export const extractRoles = (auth: CognitoAuth): string[] => {
  const idToken = auth
    .getSignInUserSession()
    .getIdToken()
    .decodePayload() as any;
  const getRoles = (attributeName: string) => {
    return attributeName in idToken ? idToken[attributeName].split(",") : [];
  };
  const roles = [ROLE_KEY, ANT_ROLE_KEY].map(getRoles).flat();
  return roles;
};

export const extractAlias = (auth: CognitoAuth): string => {
  const idToken = auth.getSignInUserSession().getIdToken().decodePayload();
  return (idToken as any)["identities"][0].userId
    ? (idToken as any)["identities"][0].userId
    : "";
};

export const extractUserName = (auth: CognitoAuth): string => {
  const idToken = auth
    .getSignInUserSession()
    .getIdToken()
    .decodePayload() as any;
  const givenName = idToken["given_name"] || "";
  const familyName = idToken["family_name"] || "";
  return `${givenName} ${familyName}`;
};

export const getAWSCredentials = (): Promise<any> => {
  const auth = new CognitoAuth(getCognitoConfigDetails);
  const session = auth.getSignInUserSession();
  const decodeDetails = auth
    .getSignInUserSession()
    .getIdToken()
    .decodePayload() as any;
  const expirationUnixTime = decodeDetails["exp"] || "";
  const currentUnixTime = moment().unix();
  console.log(currentUnixTime, expirationUnixTime);
  let idToken = "";

  if (currentUnixTime >= expirationUnixTime || !session.isValid()) {
    console.log(
      "Cognito Session is invalid. Generating new session for the user."
    );
    // Cognito SDK will handle session refresh / authentication.
    auth.getSession();
  } else {
    idToken = auth.getSignInUserSession().getIdToken().getJwtToken();
  }
  console.log("Cognito Session is valid.");

  return new Promise((resolve) => {
    const { userPoolId, providerName, identityPoolId, region } =
      getCognitoConfigDetails;
    const Logins = {
      [`${providerName}${userPoolId}`]: idToken,
    };
    AWS.config.region = region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId,
      Logins,
    });
    AWS.config.getCredentials((err: any) => {
      if (err) {
        return resolve({ status: "Failure" });
      }

      if (
        !AWS.config.credentials?.accessKeyId ||
        !AWS.config.credentials?.secretAccessKey ||
        !AWS.config.credentials?.sessionToken
      ) {
        return resolve({ status: "Failure. No Credentails" });
      }

      const credentials = {
        accessKeyId: AWS.config.credentials?.accessKeyId,
        secretAccessKey: AWS.config.credentials?.secretAccessKey,
        sessionToken: AWS.config.credentials?.sessionToken,
      };

      return resolve({
        status: "Success",
        credentials: credentials,
      });
    });
  });
};
