import moment from "moment";
import React from "react";
import Papa from "papaparse";
import { uniqueId } from "lodash";
import _slice from "lodash/slice";
import _values from "lodash/values";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import { cropInput } from "src/types";
import _includes from "lodash/includes";
import { getTranslate } from "src/utilis/TranslateHandler";
import {
  LABEL,
  REGEX,
  MESSAGE,
  CSV_HEADER,
  TEXT_LENGTH,
  DATE_FORMATS,
  SOWING_TYPE_ENUM,
  CROP_PLAN_CSV_SAMPLE,
  SOURCE_LANGUAGE_CODE,
  REQUIRE_CSV_HEADER,
} from "src/constants/commonConstants";
import isEmpty from "lodash/isEmpty";
import { filter, identity, keys, map, pickBy, values } from "lodash";

export const getUserName = ({ fullName, userId }: any) => {
  return fullName || userId;
};

export const getUserAvatar = (userId: any) => {
  return userId
    ? `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userId}`
    : `https://avatars.dicebear.com/api/identicon/${uniqueId()}.svg`;
};

export const validateURL = (url: string) => {
  const pattern = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return REGEX.MEDIA_LINK.test(url);
};

export const cropPlanFilter = ({ selectedMetaData, cropPlan }: any) => {
  let name = "";
  ["cropName", "varietyName", "companyName", "sowingType"].map((item) => {
    const value = selectedMetaData[item as keyof typeof selectedMetaData];
    if (value) {
      name += `_${value}`;
    }
  });
  name = `${selectedMetaData.language}${name}`;
  const filterCropPlanList = cropPlan.filter((item: any) => {
    return _includes(item.cropPlanName, name);
  });

  return filterCropPlanList;
};

export const newQueriesFilter = ({ queryList, filter }: any) => {
  const { languageCode, cropName, varietyName } = filter || {};

  if (isEmpty(pickBy([languageCode, cropName, varietyName], identity)))
    return queryList;

  let data = _filter(queryList, (item) => {
    let conditions = [];
    conditions.push(item.language == languageCode);
    if (cropName) {
      conditions.push(item.cropName == cropName);
    }
    if (varietyName) {
      conditions.push(item.cropVarietyName == varietyName);
    }
    return conditions.every((item) => item === true);
  });
  return data;
};

export const cropFilter = ({ filterCriteria, cropList }: any) => {
  const { languageCode, status } = filterCriteria;
  const filterCropList = _filter(cropList, (item) => {
    let conditions: any = "";
    if (languageCode) {
      conditions = item.languageCode == languageCode;
    }
    if (status) {
      conditions = item.cropStatus == status;
    }
    if (languageCode && status) {
      conditions =
        item.languageCode == languageCode && item.cropStatus == status;
    }
    return conditions;
  });
  return languageCode || status ? filterCropList : cropList;
};

export const varietyFilter = ({ filterCriteria, varietyList }: any) => {
  const { languageCode, status, cropName, varietyName, companyName } =
    filterCriteria;
  let data = _filter(varietyList, (item) => {
    let conditions = [];
    conditions.push(item.languageCode == languageCode);
    if (status) {
      conditions.push(item.status == status);
    }
    if (cropName) {
      conditions.push(item.cropName == cropName);
    }
    if (varietyName) {
      conditions.push(item.cropVarietyName == varietyName);
    }
    if (companyName) {
      conditions.push(item.companyName == companyName);
    }
    return conditions.every((item) => item === true);
  });

  return languageCode || status || cropName || varietyName || companyName
    ? data
    : varietyList;
};

export const generateErrorMessageForCrop = async ({
  cropName,
  cropImgUrl,
  languageCode,
  translatedCropName,
}: cropInput) => {
  const inputDetails = {
    cropName: LABEL.CROP_NAME,
    cropImgUrl: LABEL.MEDIA_LINK,
    languageCode: LABEL.LANGUAGE,
    translatedCropName: LABEL.CROP_NAME,
  };
  let message: string[] = [];
  let translation: string[] = [];
  if (!languageCode) {
    message.push(inputDetails["languageCode"]);
  }

  if (isEmpty(cropName)) {
    message.push(inputDetails["cropName"]);
  }

  if (!cropImgUrl) {
    message.push(inputDetails["cropImgUrl"]);
  }

  if (!translatedCropName) {
    console.log("translatedCropName", translatedCropName);
    translation.push(inputDetails["translatedCropName"]);
  }

  let result = {
    isValid: false,
    message: MESSAGE.REQUIRE_MESSAGE("crop"),
    subMessage: "",
  };

  if (!_isEmpty(message)) {
    (result.isValid = true),
      (result.subMessage += `Required Field: ${message.join(", ")}`);
  } else if (!_isEmpty(translation)) {
    (result.isValid = true),
      (result.subMessage += `Required Translation Field: ${translation.join(
        ", "
      )}`);
  }
  if (!_isEmpty(cropName) && !_isEmpty(translatedCropName)) {
    translatedCropName = cropName;  // No Translation
    if (translatedCropName.length > TEXT_LENGTH.CROP_NAME) {
      (result.isValid = true),
        (result.subMessage +=
          (!result.subMessage.trim() ? "" : "\n") +
          MESSAGE.TEXT_LENGTH("Crop name", TEXT_LENGTH.CROP_NAME));
    }
  }
  if (!_isEmpty(cropImgUrl) && !validateURL(cropImgUrl)) {
    (result.isValid = true),
      (result.subMessage +=
        (!result.subMessage.trim() ? "" : "\n") +
        MESSAGE.SUB_MESSAGE_INVALID_LINK);
  }

  return result;
};

export const prepareCSVData = (
  data: any,
  selectedMetaData: any,
  fileName: any
) => {
  const GROWTH_STAGE_NAME = CSV_HEADER[0];
  const GROWTH_STAGE_ACTIVE_ICON = CSV_HEADER[1];
  const GROWTH_STAGE_INACTIVE_ICON = CSV_HEADER[2];
  const GROWTH_STAGE_COMPLETED_ICON = CSV_HEADER[3];
  const START_DATE = CSV_HEADER[4];
  const ACTIVITY_NAME = CSV_HEADER[5];
  const ACTIVITY_START_DATE = CSV_HEADER[6];
  const DESCRIPTION = CSV_HEADER[7];

  const growthStagesList = groupBy(data, GROWTH_STAGE_NAME);
  let growthStages: any = [];
  _values(growthStagesList).map((item: any) => {
    let activities = _values(item).map((item1: any) => {
      var imageKeys = filter(keys(item1), (key) => /Image [0-9]+/i.test(key));
      var videoKeys = filter(keys(item1), (key) => /^Video [0-9]+$/i.test(key));
      var thumbnailKeys = filter(keys(item1), (key) =>
        /Thumbnail [0-9]+/i.test(key)
      );
      var vidDescKeys = filter(keys(item1), (key) =>
        /Video [0-9]+ Desc/i.test(key)
      );
      return {
        activityName: item1[ACTIVITY_NAME],
        startDate: parseInt(item1[ACTIVITY_START_DATE]),
        description: item1[DESCRIPTION],
        imagesList: filter(
          values(
            pickBy(
              map(imageKeys, (imgKey) => item1[imgKey]),
              identity
            )
          ),
          (img) => REGEX.MEDIA_LINK.test(img)
        ),
        videoList: filter(
          map(videoKeys, (vidKey, index) => ({
            videoUrl: item1[vidKey],
            ...(item1[vidDescKeys[index]] && {
              videoDescription: item1[vidDescKeys[index]],
            }),
            thumbnail: item1[thumbnailKeys[index]],
          })),
          (j) => j.videoUrl
        ),
      };
    });
    //Growth Stage
    if (!_isEmpty(item[0][GROWTH_STAGE_NAME])) {
      growthStages.push({
        growthStageName: item[0][GROWTH_STAGE_NAME],
        growthStageActiveIcon: item[0][GROWTH_STAGE_ACTIVE_ICON],
        growthStagePendingIcon: item[0][GROWTH_STAGE_INACTIVE_ICON],
        growthStageCompletedIcon: item[0][GROWTH_STAGE_COMPLETED_ICON],
        startDate: parseInt(item[0][START_DATE]),
        activities,
      });
    }
  });

  let finalResult: any = {
    cropPlanName: fileName,
    cropName: selectedMetaData.cropName,
    varietyName: selectedMetaData.varietyName,
    companyName: selectedMetaData.companyName,
    varietyId: selectedMetaData.varietyId,
    fileUrl: CROP_PLAN_CSV_SAMPLE,
    growthStages,
    language: selectedMetaData.languageCode,
    sowingType:
      SOWING_TYPE_ENUM[
        selectedMetaData.sowingType as keyof typeof SOWING_TYPE_ENUM
      ],
    data: data,
  };

  return finalResult;
};

export const groupBy = (objectArray: any, property: any) => {
  return objectArray.reduce((acc: any, obj: any) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
};

export const getFomattedDate = (time: any) => {
  return moment().format(DATE_FORMATS.v3) ===
    moment(time).format(DATE_FORMATS.v3)
    ? `Today, ${moment(time).format(DATE_FORMATS.v4)}`
    : moment(time).format(DATE_FORMATS.v2);
};

export const getFomattedDateForCropPlanPreview = (time: any) => {
  return moment(time).format(DATE_FORMATS.v1);
};

export const getSignedMediaURL = (
  id: string,
  type: string,
  chats3MediaList: any
) => {
  const getObjectURL =
    chats3MediaList && chats3MediaList.find((item: any) => item.chatId == id);

  if (isEmpty(getObjectURL)) return "";

  return {
    image: (
      <img
        src={getObjectURL.signedMediaUrl}
        className="active-queries-chats-main-sent-img"
      />
    ),
    video: (
      <video controls className="active-queries-chats-main-sent-video">
        <source src={getObjectURL.signedMediaUrl} type="video/mp4" />
        Your browser does not support HTML video.
      </video>
    ),
    audio: (
      <audio controls className="active-queries-chats-main-sent-audio">
        <source src={getObjectURL.signedMediaUrl} type="audio/mpeg" />
        Your browser does not support HTML audio.
      </audio>
    ),
  }[type];
};

export const fileUploadHandler = (
  file: any,
  showModal: any,
  setUploadFilePopup: any,
  setExtractData: any,
  selectedMetaData: any,
  fileName: any
) => {
  if (file.size < 200) {
    showModal({
      isOpen: true,
      message: MESSAGE.FILE_SIZE_0KB,
      okButtonText: LABEL.TRY_AGAIN,
    });
    return;
  }

  Papa.parse(file, {
    complete: async function (results: any) {
      console.log(results.data);
      if (_isEmpty(results.data)) {
        showModal({
          isOpen: true,
          message: MESSAGE.REQUIRE_CSV_FILE_DATA,
          subMessage: MESSAGE.REQUIRE_CSV_FILE_DATA_SUB_MESSAGE,
          okButtonText: LABEL.TRY_AGAIN,
        });
        return true;
      }
      let headers = results.data[0];

      if (!REQUIRE_CSV_HEADER.every((v) => headers.includes(v))) {
        setUploadFilePopup(false);
        showModal({
          isOpen: true,
          message: MESSAGE.REQUIRE_CSV_HEADER,
          detailMessage: { type: "cropPlan" },
          okButtonText: LABEL.TRY_AGAIN,
        });
      } else {
        let data: any[] = [];

        for (var i = 1; i < results.data.length; i++) {
          var obj: any = {};
          var currentline = results.data[i];

          for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
          }

          data.push(obj);
        }

        const finalResult = await prepareCSVData(
          data,
          selectedMetaData,
          fileName
        );
        console.log(finalResult);
        setExtractData(finalResult);
      }
    },
  });
};
