import _uniqBy from "lodash/uniqBy";
import _isEmpty from "lodash/isEmpty";
import React, { useEffect } from "react";
import { KatRow, KatColumn } from "@amzn/katal-react";
import FormSelect from "src/components/FormComponents/FormSelect";
import {
  LABEL,
  LANGUAGES,
  LANGUAGES_CODE,
} from "src/constants/commonConstants";
import OutlinedButton from "src/components/common/OutlinedButton";
import { mapValues } from "lodash";

export const NewQueriesFilter = ({
  cropList,
  fetchCrops,
  handleFilter,
  varietyMetadata,
  fetchPlanVarietyMetaData,
  setPage,
}: any) => {
  const [selectedMetaData, setSelectedMetaData] = React.useState({
    cropId: "",
    varietyId: "",
    cropName: "",
    language: "",
    varietyName: "",
    languageCode: "",
  });

  const [metaData, setMetaData] = React.useState({
    cropNameList: [],
  }) as any;

  const storeChangeData = (data: any) => {
    if (data.cropId) {
      const cropNameDetails = cropList
        ? cropList.find((item: any) => item.id == data.cropId)
        : {};
      data = cropNameDetails
        ? {
            ...data,
            cropName: cropNameDetails.cropName,
          }
        : data;
    }

    if (data.varietyId) {
      const varietyNameDetails = varietyMetadata.varietyNameList
        ? varietyMetadata.varietyNameList.find(
            (item: any) => item.name == data.varietyId
          )
        : {};

      data = varietyNameDetails
        ? {
            ...data,
            varietyName: varietyNameDetails.name,
          }
        : data;
    }

    if (data.languageCode)
      setSelectedMetaData((prevState: any) => ({
        ...mapValues(prevState, () => ""),
        ...data,
      }));
    else if (data.cropId)
      setSelectedMetaData((prevState: any) => ({
        ...prevState,
        ...data,
        varietyId: "",
        varietyName: "",
      }));
    else
      setSelectedMetaData((prevState: any) => ({
        ...prevState,
        ...data,
      }));
  };

  const updateCropNameList = (languageCode: string) => {
    let cropNameList: any[] = [];
    cropList.map((item: any) => {
      if (item.languageCode == languageCode) {
        cropNameList.push({ name: item.cropName, value: item.id });
      }
    });

    return _uniqBy(cropNameList, "name");
  };

  return (
    <KatRow className="align-items-end mb-4">
      <KatColumn sm={3}>
        <FormSelect
          placeholder={LABEL.LANGUAGE}
          options={LANGUAGES}
          onChange={(val) => {
            fetchCrops();
            storeChangeData({ languageCode: val });
            storeChangeData({
              language: LANGUAGES_CODE[val as keyof typeof LANGUAGES_CODE],
            });
          }}
          value={selectedMetaData.languageCode}
        />
      </KatColumn>

      <KatColumn sm={3}>
        <FormSelect
          placeholder={LABEL.CROP_NAME}
          options={updateCropNameList(selectedMetaData.languageCode)}
          disabled={_isEmpty(selectedMetaData.language)}
          onChange={(val) => {
            storeChangeData({ cropId: val });
            fetchPlanVarietyMetaData({
              cropId: val,
              languageCode: selectedMetaData.languageCode,
            });
          }}
          value={selectedMetaData.cropId}
        />
      </KatColumn>

      <KatColumn sm={3}>
        <FormSelect
          placeholder={LABEL.VARIETY_NAME}
          options={
            varietyMetadata?.varietyNameList &&
            varietyMetadata.varietyNameList.map((item: any) => {
              return { name: item.name, value: item.name };
            })
          }
          disabled={
            _isEmpty(selectedMetaData.language) ||
            _isEmpty(selectedMetaData.cropId)
          }
          onChange={(val) => storeChangeData({ varietyId: val })}
          value={selectedMetaData.varietyId}
        />
      </KatColumn>

      <KatColumn sm={3}>
        <OutlinedButton
          className="handleFilter"
          label={LABEL.FILTER}
          onClick={() => {
            handleFilter(selectedMetaData);
            setPage(1);
          }}
        />
      </KatColumn>
    </KatRow>
  );
};

export default NewQueriesFilter;
