import { KatButton, KatColumn, KatIcon, KatRow } from "@amzn/katal-react";
import Preview from "./Preview";
import _filter from "lodash/filter";
import _isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Filter from "./NewQueriesFilter/index";
import { useNavigate } from "react-router-dom";
import NewQueriesTable from "./NewQueriesTable";
import * as cropActions from "src/actions/cropActions";
import AppContainer from "src/components/AppContainer";
import * as queryActions from "src/actions/queryActions";
import * as cropPlanActions from "src/actions/cropPlanActions";
import { LABEL, PAGETITLE } from "src/constants/commonConstants";
import { ROUTECONSTANTS, ROUTENAME } from "src/constants/routeConstants";
import BlueButton from "src/components/common/BlueButton";
import { getFomattedDate, newQueriesFilter } from "src/helper";
import moment from "moment";

interface QueryTableProps {
  cropList: any;
  queryList: any;
  varietyMetadata: any;
  fetchCrops: () => void;
  fetchQueries: () => void;
  assignQuery: (data: any) => void;
  fetchPlanVarietyMetaData: (data: any) => void;
}

export const NewQueries = ({
  cropList,
  queryList,
  assignQuery,
  fetchCrops,
  fetchQueries,
  varietyMetadata,
  fetchPlanVarietyMetaData,
}: QueryTableProps) => {
  let navigate = useNavigate();
  const [query, setQuery] = React.useState({
    id: "",
    farmerId: "",
    farmerCropId: "",
    updatedTime: "",
  });
  const [timeInterval, setTimeInterval] = React.useState(false);
  const [showModal, setModal] = React.useState(false);
  const [filterData, setFilterData] = React.useState(queryList);
  const [filters, setFilters] = React.useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    handleFilter(filters);
  }, [queryList]);

  const handleFilter = (filter: any) => {
    setFilters(filter);
    let data = newQueriesFilter({ queryList, filter });
    setFilterData(data);
  };

  const handleRespond = () => {
    if (query.id) {
      const respondParams = Object.assign(query, {
        resolutionTime: moment().diff(moment(query.updatedTime), "minutes"),
        createdTime: getFomattedDate(query.updatedTime),
      });
      // @ts-ignore
      window.Moengage.track_event("RespondToQuery", respondParams);
      assignQuery({
        farmerId: query.farmerId,
        farmerCropId: query.farmerCropId,
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchQueries();
    }, 5000);
    return () => clearInterval(interval);
  }, [timeInterval]);

  useEffect(() => {
    fetchQueries();
    setTimeInterval(true);
  }, []);

  return (
    <AppContainer
      parentKey={ROUTENAME.QUERY_MANAGEMENT}
      childKey={ROUTENAME.QUERY_MANAGEMENT_NEW_QUERIES}
    >
      <section>
        <KatRow className="align-items-end justify-content-between mb-4">
          <KatColumn sm={6}>
            <h1>{PAGETITLE.NEW_QUERIES}</h1>
          </KatColumn>
        </KatRow>
        <Filter
          fetchCrops={fetchCrops}
          cropList={cropList}
          queryList={queryList}
          fetchPlanVarietyMetaData={fetchPlanVarietyMetaData}
          varietyMetadata={varietyMetadata}
          handleFilter={handleFilter}
          {...{ setPage }}
        />
        <KatRow className="align-items-center previewOption">
          <KatButton
            onClick={() => setModal(true)}
            className="table-action me-5 table-action-edit"
            disabled={!query.id}
          >
            <KatIcon
              size="small"
              slot="icon"
              name="remove_red_eye"
              className="table-action-icon"
            ></KatIcon>
            <div>Preview File</div>
          </KatButton>
          <div>
            <BlueButton
              className={"respondButton"}
              disabled={_isEmpty(query.id)}
              label={LABEL.RESPOND}
              onClick={handleRespond}
            />
          </div>
        </KatRow>
        <NewQueriesTable
          queryList={filterData}
          {...{ queryId: query.id, setQuery, page, setPage }}
        />
      </section>
      <Preview {...{ querId: query.id, setModal, showModal, handleRespond }} />
    </AppContainer>
  );
};

export const mapStateToProps = (state: any) => ({
  cropList: state.crop.cropList,
  queryList: state.query.queryList,
  varietyMetadata: state.cropPlan.varietyMetadata,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchQueries: () => {
    dispatch(queryActions.fetchInactiveQueries());
  },
  assignQuery: (data: any) => {
    dispatch(queryActions.assignQuery(data));
  },
  fetchCrops: () => {
    dispatch(cropActions.fetchCrops());
  },
  fetchPlanVarietyMetaData: (data: any) => {
    dispatch(cropPlanActions.fetchPlanVarietyMetaData(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NewQueries);
