import { KatButton } from "@amzn/katal-react";
import React from "react";

interface TitleProps {
  label: string;
  className?: string;
  onClick?: (e: any) => void;
}

const OutlinedButton = ({ label, className, onClick }: TitleProps) => {
  return (
    <KatButton
      variant="tertiary"
      {...{ label, onClick }}
      className={`outlined-button w-100 ${className}`}
    />
  );
};

export default OutlinedButton;
