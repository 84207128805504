import React from "react";
import _slice from "lodash/slice";
import { PAGE_SIZE } from "src/constants/commonConstants";
import { KatPagination, KatTableFooter } from "@amzn/katal-react";

interface PaginationTypes {
  list: any;
  setDataList: (data: any) => void;
}

export const Pagination = ({ list, setDataList }: PaginationTypes) => {
  const handlePageChange = (e: any) => {
    const pageNumber = parseInt(e.detail.page);
    const next = pageNumber * PAGE_SIZE;
    const data = _slice(list, next - PAGE_SIZE, next);
    setDataList(data);
  };
  return (
    <>
      {list.length < PAGE_SIZE ? null : (
        <KatTableFooter className="p-2 h-100">
          <section className="d-flex justify-content-end align-items-center">
            <div className="pe-3">{list.length} Results</div>
            <KatPagination
              className="vsp-enrollments-pagination"
              itemsPerPage={PAGE_SIZE}
              onPageChanged={(e) => handlePageChange(e)}
              page={1}
              totalItems={list.length}
            />
          </section>
        </KatTableFooter>
      )}
    </>
  );
};

export default Pagination;
