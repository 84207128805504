import {
  KatRow,
  KatIcon,
  KatTable,
  KatButton,
  KatColumn,
  KatTableRow,
  KatTableCell,
  KatTableBody,
  KatTableHead,
} from "@amzn/katal-react";
import _slice from "lodash/slice";
import _uniqBy from "lodash/uniqBy";
import _isEmpty from "lodash/isEmpty";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { ShadowBox } from "src/components/common/ShadowBox";
import * as varietyActions from "src/actions/varietyActions";
import * as cropPlanActions from "src/actions/cropPlanActions";
import OutlinedButton from "src/components/common/OutlinedButton";
import FormSelect from "src/components/FormComponents/FormSelect";
import {
  LABEL,
  LANGUAGES,
  LANGUAGES_CODE,
  PAGE_SIZE,
  SOWING_TYPE,
} from "src/constants/commonConstants";
import { useNavigate } from "react-router-dom";
import { ROUTECONSTANTS } from "src/constants/routeConstants";
import Pagination from "src/components/common/pagination";
import { mapValues } from "lodash";

export interface CropPlanPreviewProps {
  cropList: any;
  cropPlan?: any;
  signedMediaUrl?: string;
  varietyList: any;
  cropMetadata: any;
  varietyMetadata: any;
  selectedMetaData: any;
  fetchCropPlanData?: any;
  fetchVarieties: () => void;
  handleFilter: () => void;
  setSelectedMetaData: (data: any) => void;
  storeCropPlanDetails: (data: any) => void;
  fetchCropPlanDetails: (data: any) => void;
  getPreSignedURLDownload: (data: any) => void;
  fetchPlanVarietyMetaData: (data: any) => void;
}

export const CropPlanTable = ({
  cropList,
  cropPlan,
  varietyList,
  cropMetadata,
  handleFilter,
  signedMediaUrl,
  varietyMetadata,
  fetchVarieties,
  selectedMetaData,
  fetchCropPlanData,
  setSelectedMetaData,
  storeCropPlanDetails,
  fetchCropPlanDetails,
  getPreSignedURLDownload,
  fetchPlanVarietyMetaData,
}: CropPlanPreviewProps) => {
  const [downloadClick, setDownloadClick] = React.useState(false);
  const [dataList, setDataList] = React.useState(cropPlan);
  const [metaData, setMetaData] = React.useState({
    cropNameList: [],
    varietyNameList: [],
  }) as any;

  const storeChangeData = (data: any) => {
    if (data.cropId) {
      const cropNameDetails = cropList
        ? cropList.find((item: any) => item.id == data.cropId)
        : {};
      data = cropNameDetails
        ? {
            ...data,
            cropName: cropNameDetails.cropName,
          }
        : data;
    }
    if (data.languageCode)
      setSelectedMetaData((prevState: any) => ({
        ...mapValues(prevState, () => ""),
        ...data,
      }));
    else if (data.cropId)
      setSelectedMetaData((prevState: any) => ({
        ...prevState,
        ...data,
        varietyId: "",
        varietyName: "",
        companyName: "",
      }));
    else
      setSelectedMetaData((prevState: any) => ({
        ...prevState,
        ...data,
      }));
  };

  useEffect(() => {
    const data = _slice(cropPlan, 0, PAGE_SIZE);
    setDataList(data);
  }, [cropPlan]);

  useEffect(() => {
    fetchVarieties();
    const data = _slice(cropPlan, 0, PAGE_SIZE);
    setDataList(data);
  }, []);

  const updateCropNameList = (languageCode: string) => {
    let cropNameList: any[] = [];
    cropList.map((item: any) => {
      if (item.languageCode == languageCode) {
        cropNameList.push({ name: item.cropName, value: item.id });
      }
    });

    setMetaData((prevState: any) => ({
      ...prevState,
      cropNameList: _uniqBy(cropNameList, "name"),
    }));
  };

  const downloadLink = React.useRef();
  useEffect(() => {
    if (downloadClick && signedMediaUrl) {
      let link = document.createElement("a");
      link.href = signedMediaUrl;
      link.click();
    }
  }, [downloadClick, signedMediaUrl]);

  const navigate = useNavigate();

  return (
    <div>
      <ShadowBox>
        <KatRow className="align-items-center justify-content-between">
          <KatColumn xs={12}>
            <h3>{LABEL.CROP_PLAN_PREVIEW}</h3>
            <br />
          </KatColumn>
          <KatColumn md={2}>
            <FormSelect
              label={LABEL.LANGUAGE}
              value={selectedMetaData.languageCode}
              placeholder={LABEL.SELECT_LANGUAGE}
              options={LANGUAGES}
              onChange={(val) => {
                storeChangeData({ languageCode: val });
                storeChangeData({
                  language: LANGUAGES_CODE[val as keyof typeof LANGUAGES_CODE],
                });
                updateCropNameList(val);
              }}
            />
          </KatColumn>
          <KatColumn md={2}>
            <FormSelect
              label={LABEL.CROP_NAME}
              placeholder={LABEL.SELECT_CROP}
              options={metaData.cropNameList}
              disabled={_isEmpty(selectedMetaData.language)}
              value={selectedMetaData.cropId}
              onChange={(val) => {
                storeChangeData({ cropId: val });
                fetchPlanVarietyMetaData({
                  cropId: val,
                  languageCode: selectedMetaData.languageCode,
                });
              }}
            />
          </KatColumn>

          <KatColumn md={2}>
            <FormSelect
              label={LABEL.VARIETY_NAME}
              placeholder={LABEL.SELECT_VARIETY}
              value={selectedMetaData.varietyName}
              options={
                varietyMetadata.varietyNameList &&
                varietyMetadata.varietyNameList.map((item: any) => {
                  return { name: item.name, value: item.name };
                })
              }
              onChange={(val) => storeChangeData({ varietyName: val })}
              disabled={
                _isEmpty(selectedMetaData.language) ||
                _isEmpty(selectedMetaData.cropName)
              }
            />
          </KatColumn>

          <KatColumn md={3}>
            <FormSelect
              label={LABEL.COMPANY_NAME}
              placeholder={LABEL.SELECT_COMPANY}
              value={selectedMetaData.companyName}
              options={varietyMetadata.companyNameList}
              disabled={
                _isEmpty(selectedMetaData.language) ||
                _isEmpty(selectedMetaData.cropName)
              }
              onChange={(val) => storeChangeData({ companyName: val })}
            />
          </KatColumn>

          <KatColumn md={3}>
            <FormSelect
              label={LABEL.SOWING_TYPE}
              placeholder={LABEL.SELECT_SOWING_TYPE}
              value={selectedMetaData.sowingType}
              disabled={_isEmpty(selectedMetaData.language)}
              onChange={(val) => storeChangeData({ sowingType: val })}
              options={
                SOWING_TYPE[
                  selectedMetaData.languageCode as keyof typeof SOWING_TYPE
                ]
              }
            />
          </KatColumn>

          <KatColumn xs={8} />
          <KatColumn xs={2}>
            <OutlinedButton
              className="mt-4"
              label={LABEL.FILTER}
              onClick={() => handleFilter()}
            />
          </KatColumn>

          <KatColumn xs={12} className="mt-4">
            <KatTable>
              <KatTableHead>
                <KatTableRow>
                  <KatTableCell>{LABEL.CROP_PLAN_NAME} </KatTableCell>
                  <KatTableCell>{LABEL.ACTION}</KatTableCell>
                </KatTableRow>
              </KatTableHead>
              <KatTableBody>
                {dataList.map((item: any, index: any) => {
                  return (
                    <KatTableRow key={index}>
                      <KatTableCell className="w-75">
                        {item.cropPlanName}
                      </KatTableCell>

                      <KatTableCell className="w-25 nowrap">
                        <KatButton
                          onClick={() => {
                            storeCropPlanDetails({});
                            navigate(
                              `${ROUTECONSTANTS.CROP_PLAN_MANAGEMENT}/preview/${item.cropPlanId}`
                            );
                          }}
                          // variant="link"
                          className="table-action me-4 table-action-edit"
                        >
                          <KatIcon
                            size="small"
                            slot="icon"
                            name="remove_red_eye"
                            className="table-action-icon"
                          ></KatIcon>
                          <div>Preview File</div>
                        </KatButton>
                        <KatButton
                          onClick={() => {
                            getPreSignedURLDownload({ mediaUrl: item.fileUrl });
                            setDownloadClick(true);
                          }}
                          variant="link"
                          className="table-action me-5 table-action-edit"
                        >
                          <KatIcon
                            size="small"
                            slot="icon"
                            name="file_download"
                            className="table-action-icon"
                          ></KatIcon>
                          <div>{LABEL.DOWNLOAD}</div>
                        </KatButton>
                      </KatTableCell>
                    </KatTableRow>
                  );
                })}
              </KatTableBody>
              <Pagination list={cropPlan} setDataList={setDataList} />
            </KatTable>
          </KatColumn>
        </KatRow>
      </ShadowBox>
    </div>
  );
};

export const mapStateToProps = (state: any) => ({
  varietyList: state.variety.varietyList,
  signedMediaUrl: state.cropPlan.signedMediaUrl,
});

export const mapDispatchToProps = (dispatch: any) => ({
  fetchCropPlanDetails: (data: any) => {
    dispatch(cropPlanActions.fetchCropPlanDetails(data));
  },
  storeCropPlanDetails: (data: any) => {
    dispatch(cropPlanActions.storeCropPlanDetails(data));
  },
  fetchVarieties: () => {
    dispatch(varietyActions.fetchVarieties());
  },
  getPreSignedURLDownload: (data: any) => {
    dispatch(cropPlanActions.storeSignedMediaUrl(""));
    dispatch(cropPlanActions.getPreSignedURLDownload(data));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(CropPlanTable);
