import React from "react";
import _uniqBy from "lodash/uniqBy";
import { KatRow, KatColumn, KatLabel } from "@amzn/katal-react";
import FormSelect from "src/components/FormComponents/FormSelect";
import OutlinedButton from "src/components/common/OutlinedButton";
import { LANGUAGES, STATUS } from "src/constants/commonConstants";
import { mapValues } from "lodash";

export const VarietyFilter = ({
  handleFilter,
  setFilterCriteria,
  varietyList,
  filterCriteria,
}: any) => {
  const [languageCode, setLanguageCode] = React.useState("");
  const [filterOptions, setFilterOptions] = React.useState({
    companyList: [],
    cropNameList: [],
    varietyNameList: [],
  });

  const onChangeHandler = (data: any) => {
    setFilterCriteria((prevState: any) => ({
      ...prevState,
      ...data,
    }));
  };

  const onLanguageChange = (languageCode: string) => {
    const filterOptionsData = varietyList.filter((item: any) => {
      return item.languageCode == languageCode;
    });

    let companyList: any = [];
    let cropNameList: any = [];
    let varietyNameList: any = [];
    filterOptionsData.map((item: any) => {
      cropNameList.push({ name: item.cropName, value: item.cropName });
      companyList.push({ name: item.companyName, value: item.companyName });
      varietyNameList.push({
        name: item.cropVarietyName,
        value: item.cropVarietyName,
      });
    });

    setLanguageCode(languageCode);
    setFilterCriteria((prevState: any) => ({
      ...mapValues(filterCriteria, () => ""),
      languageCode,
    }));

    setFilterOptions((prevState: any) => ({
      ...prevState,
      companyList: _uniqBy(companyList, "name"),
      cropNameList: _uniqBy(cropNameList, "name"),
      varietyNameList: _uniqBy(varietyNameList, "name"),
    }));
  };

  return (
    <KatRow className="justify-content-between">
      <KatColumn sm={2}>
        <FormSelect
          className="onLanguageSelection"
          label={"Language"}
          value={languageCode}
          placeholder="Select Language"
          options={LANGUAGES}
          onChange={(val) => onLanguageChange(val)}
        />
      </KatColumn>

      <KatColumn sm={2}>
        <FormSelect
          className="onChangeValue"
          options={filterOptions.cropNameList}
          label={"Crop Name"}
          placeholder="Select Crop"
          value={filterCriteria?.cropName}
          disabled={!languageCode}
          onChange={(val) => onChangeHandler({ cropName: val })}
        />
      </KatColumn>

      <KatColumn sm={2}>
        <FormSelect
          className="onChangeValue"
          options={filterOptions.varietyNameList}
          label={"Variety Name"}
          placeholder="Select Variety"
          value={filterCriteria?.varietyName}
          disabled={!languageCode}
          onChange={(val) => onChangeHandler({ varietyName: val })}
        />
      </KatColumn>

      <KatColumn sm={2}>
        <FormSelect
          className="onChangeValue"
          options={filterOptions.companyList}
          label={"Company Name"}
          placeholder="Select Company"
          value={filterCriteria?.companyName}
          disabled={!languageCode}
          onChange={(val) => onChangeHandler({ companyName: val })}
        />
      </KatColumn>

      <KatColumn sm={2}>
        <FormSelect
          className="onChangeValue"
          label={"Status"}
          placeholder="Select Status"
          options={STATUS}
          onChange={(val) => onChangeHandler({ status: val })}
          value={filterCriteria?.status}
          disabled={!languageCode}
        />
      </KatColumn>

      <KatColumn sm={2} className="mb-4">
        <KatLabel className="mb-3 text-transparent">label</KatLabel>

        <OutlinedButton
          label="Filter"
          className="mt-1 handleFilter"
          onClick={() => handleFilter()}
        />
      </KatColumn>
    </KatRow>
  );
};

export default VarietyFilter;
