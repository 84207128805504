import {
  KatRow,
  KatIcon,
  KatTable,
  KatButton,
  KatColumn,
  KatTableBody,
  KatTableCell,
  KatTableRow,
  KatDropdown,
  KatTableHead,
  KatTableFooter,
  KatPagination,
} from "@amzn/katal-react";
import _map from "lodash/map";
import _slice from "lodash/slice";
import { useNavigate } from "react-router-dom";
import React, { Children, useEffect, useState } from "react";
import { MESSAGE } from "src/constants/commonConstants";
import Pagination from "src/components/common/pagination";
import { ROUTECONSTANTS } from "src/constants/routeConstants";
import { PAGE_SIZE, STATUS } from "src/constants/commonConstants";
import { LANGUAGES_CODE, LABEL } from "src/constants/commonConstants";
import slice from "lodash/slice";
import map from "lodash/map";

interface CropDataTableProps {
  editCrop: (data: any) => void;
  showModal: (data: any) => void;
  updateCrop: (data: any) => void;
  deleteCrop: (cropId: string) => void;
  cropList: {
    id: string;
    version: string;
    cropName: string;
    cropStatus: string;
    languageCode: string;
  }[];
}

export const CropDataTable = ({
  cropList,
  showModal,
  deleteCrop,
  updateCrop,
  editCrop,
}: CropDataTableProps) => {
  const navigate = useNavigate();
  const [cropId, setCropId] = useState("");
  const [cropStatus, setCropStatus] = useState("");
  const [page, setPage] = useState(1);

  const handleUpdateAction = (data: any, previousStatus: string) => {
    showModal({
      isOpen: true,
      message: MESSAGE.CHANGE_STATUS("crop"),
      subMessage: MESSAGE.SUB_CHANGE_STATUS,
      okButtonText: LABEL.CONTINUE,
      cancelButtonText: LABEL.CANCEL,
      handleConfirmationOk: () => updateCrop(data),
      handleConfirmationCancel: () => setCropStatus(previousStatus),
    });
  };

  const handleEditAction = (data: any) => {
    editCrop(data);
    navigate(ROUTECONSTANTS.CROP_MANAGEMENT_CROP_ADD);
  };

  return (
    <KatRow className="align-items-end">
      <KatColumn sm={12}>
        <KatTable>
          <KatTableHead>
            <KatTableRow>
              <KatTableCell>{LABEL.CROP_NAME}</KatTableCell>
              <KatTableCell>{LABEL.LANGUAGE}</KatTableCell>
              <KatTableCell className="text-center">
                {LABEL.ACTION}
              </KatTableCell>
              <KatTableCell className="text-center">
                {LABEL.STATUS}
              </KatTableCell>
            </KatTableRow>
          </KatTableHead>
          <KatTableBody>
            {Children.toArray(
              map(
                slice(cropList, page * PAGE_SIZE - PAGE_SIZE, page * PAGE_SIZE),
                (item, index) => {
                  return (
                    <KatTableRow>
                      <KatTableCell>{item.cropName}</KatTableCell>
                      <KatTableCell>
                        {
                          LANGUAGES_CODE[
                            item.languageCode as keyof typeof LANGUAGES_CODE
                          ]
                        }
                      </KatTableCell>
                      <KatTableCell className="text-center">
                        <KatButton
                          variant="link"
                          className="editCell mr-2 table-action table-action-edit"
                          onClick={() => handleEditAction(item)}
                        >
                          <KatIcon
                            size="small"
                            slot="icon"
                            name="edit"
                          ></KatIcon>
                          <div>{LABEL.EDIT}</div>
                        </KatButton>
                      </KatTableCell>
                      <KatTableCell className="text-center py-0">
                        <KatDropdown
                          value={
                            cropId === item.id ? cropStatus : item.cropStatus
                          }
                          placeholder="Select Status"
                          options={STATUS}
                          onChange={(event) => {
                            setCropId(item.id);
                            setCropStatus(event.detail.value);
                            handleUpdateAction(
                              {
                                id: item.id,
                                cropStatus: event.detail.value,
                                version: item.version,
                              },
                              item.cropStatus
                            );
                          }}
                          className={`form-select ${
                            cropId === item.id
                              ? `status-${cropStatus}`
                              : `status-${item.cropStatus}`
                          }`}
                        />
                      </KatTableCell>
                    </KatTableRow>
                  );
                }
              )
            )}
          </KatTableBody>
        </KatTable>
        <KatTableFooter className="p-2 h-100">
          <section className="d-flex justify-content-end align-items-center">
            <div className="pe-3">{cropList.length} Results</div>
            <KatPagination
              className="vsp-enrollments-pagination"
              itemsPerPage={PAGE_SIZE}
              onPageChanged={(e: any) => {
                setPage(e.detail.page);
              }}
              page={page}
              totalItems={cropList?.length}
            />
          </section>
        </KatTableFooter>
      </KatColumn>
    </KatRow>
  );
};

export default CropDataTable;
