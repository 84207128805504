import { AnyAction } from "redux";
import _merge from "lodash/merge";
import * as queryActions from "../actions/queryActions";

const initialState = {
  chats: [],
  s3Media: "",
  queryList: [],
  queryCropPlan: [],
  activeQueryList: [],
  chats3MediaList: [],
  growthActivities: {},
};

const queryReducer = (state = initialState, action: AnyAction) => {
  const type = action.type;
  const payload = action.payload;

  switch (type) {
    case queryActions.STORE_INACTIVE_QUERIES:
      return {
        ...state,
        queryList: payload,
      };
    case queryActions.STORE_ACTIVE_QUERIES:
      return {
        ...state,
        activeQueryList: payload,
      };
    case queryActions.STORE_QUERY_CROP_PLAN:
      return {
        ...state,
        queryCropPlan: payload,
      };
    case queryActions.STORE_CHATS:
      return {
        ...state,
        chats: payload,
      };
    case queryActions.STORE_S3_MEDIA:
      return {
        ...state,
        s3Media: payload,
      };
    case queryActions.STORE_S3_MEDIA_LIST:
      return {
        ...state,
        chats3MediaList: payload,
      };
    case queryActions.STORE_GROWTH_ACTIVITIES:
      return {
        ...state,
        growthActivities: payload,
      };
    default:
      return state;
  }
};

export default queryReducer;
