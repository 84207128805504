import { isEmpty, find } from "lodash";
import { connect } from "react-redux";
import { KatIcon } from "@amzn/katal-react";
import React, { Children, useEffect } from "react";
import AppContainer from "src/components/AppContainer";
import { useNavigate, useParams } from "react-router-dom";
import { getFomattedDateForCropPlanPreview } from "src/helper";
import * as cropPlanActions from "src/actions/cropPlanActions";
import { LABEL, LANGUAGES } from "src/constants/commonConstants";
import CropPlanStepper from "src/components/common/CropPlanStepper";
import { ROUTECONSTANTS, ROUTENAME } from "src/constants/routeConstants";

const CropPlanPreview = ({ fetchCropPlanDetails, cropPlanDetails }: any) => {
  const navigate = useNavigate();
  const { planId } = useParams();

  useEffect(() => {
    fetchCropPlanDetails({
      cropPlanId: planId,
    });
  }, [planId]);

  console.log("cropPlanDetails: ", cropPlanDetails);

  return (
    <AppContainer parentKey={ROUTENAME.CROP_PLAN_MANAGEMENT_PREVIEW} noPadding>
      <main id="cpp">
        <h2 className="cp-drawer-main-title" id="cpp-title">
          <KatIcon
            className="cp-drawer-main-title-icon"
            name="arrow-left"
            onClick={() => navigate(ROUTECONSTANTS.CROP_PLAN_MANAGEMENT)}
          ></KatIcon>{" "}
          {LABEL.CROP_PLAN}
        </h2>
        <div id="cpp-aside">
          <article className="cp-drawer-main-blue-strip mb-4">
            <div className="cp-drawer-main-blue-strip-item">
              <div>
                <span className="text-secondary">{LABEL.CROP}:</span>
                <div className="cp-drawer-main-blue-strip-black">
                  {cropPlanDetails.cropName}
                </div>
              </div>
            </div>
            <div className="cp-drawer-main-blue-strip-item">
              <div>
                <span className="text-secondary">{LABEL.VARIETY}:</span>
                <div className="cp-drawer-main-blue-strip-black">
                  {cropPlanDetails.varietyName}
                </div>
              </div>
            </div>
            <div className="cp-drawer-main-blue-strip-item">
              <div>
                <span className="text-secondary">{LABEL.LANGUAGE}:</span>
                <div className="cp-drawer-main-blue-strip-black">
                  {
                    find(LANGUAGES, {
                      value: cropPlanDetails?.cropPlan?.language,
                    })?.name
                  }
                </div>
              </div>
            </div>
            <div className="cp-drawer-main-blue-strip-item">
              <div>
                <span className="text-secondary">{LABEL.DURATION}:</span>
                <div className="cp-drawer-main-blue-strip-black">
                  {!isEmpty(cropPlanDetails) &&
                    `${cropPlanDetails?.cropPlan?.duration} ${LABEL.DAYS}`}
                </div>
              </div>
            </div>
          </article>

          <main id="cpp-stepper">
            {!isEmpty(cropPlanDetails?.cropPlan) &&
              Children.toArray(
                cropPlanDetails?.cropPlan?.growthStages.map((item: any) => (
                  <div className="px-1">
                    <CropPlanStepper
                      header={{
                        asideWidth: "7rem",
                        title: item?.growthStageName,
                        aside: `${item?.startDate} Days`,
                      }}
                      data={item?.activities?.map((i: any) => ({
                        aside: `${item?.startDate} Days`,
                        title: i?.activityName,
                        description: i?.description,
                        completed: false,
                        mediaList: i?.imagesList,
                        videoList: i?.videoList,
                      }))}
                      asideWidth={"7ch"}
                    />
                  </div>
                ))
              )}
          </main>
        </div>
        <article className="px-5" id="cpp-details">
          <h2 className="mb-4">{LABEL.CROP_PLAN_HISTORY}</h2>

          <span className="text-disabled">{LABEL.DATE_UPLOADED}</span>
          <p>
            {cropPlanDetails?.cropPlan?.updatedTime &&
              getFomattedDateForCropPlanPreview(
                cropPlanDetails?.cropPlan?.updatedTime
              )}
          </p>

          <span className="text-disabled">{LABEL.UPLOADED_BY}</span>
          <p>{cropPlanDetails?.cropPlan?.uploadedBy}</p>
        </article>
      </main>
    </AppContainer>
  );
};

const mapState = (state: any) => ({
  cropPlanDetails: state.cropPlan.cropPlanDetails,
});

const connector = connect(mapState, (dispatch) => ({
  fetchCropPlanDetails: (data: any) => {
    dispatch(cropPlanActions.fetchCropPlanDetails(data));
  },
}));

export default connector(CropPlanPreview);
